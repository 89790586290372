.btn-arrow-text {
  @extend %flex;
  align-items: center;

  &.-prev {
    & .btn-arrow-text__icon {
      transform: rotate(180deg);
    }
    & .btn-arrow-text__text {
      margin-left: 44px;

      @include media('below-small') {
        margin-left: 8px;
      }
    }
  }
  &.-next {
    & .btn-arrow-text__text {
      margin-right: 44px;

      @include media('below-small') {
        margin-right: 8px;
      }
    }
  }

  @include media('above-desktop') {
    &:hover {
      transform: translateX(0);
      & .btn-arrow-text__icon,
      & .btn-arrow-text__text {
        transition: transform .3s;
      }
      &.-prev {
        & .btn-arrow-text__text {
          transform: translateX(-8px);
        }
        & .btn-arrow-text__icon {
          transform: rotate(180deg) translateX(4px);
        }
      }
      &.-next {
        & .btn-arrow-text__text {
          transform: translateX(8px);
        }
        & .btn-arrow-text__icon {
          transform: translateX(4px);
        }
      }
    }
  }
}

.btn-arrow-text__text {
  display: block;
  font-size: 13px;
  letter-spacing: 1.95px;
  transition: transform .3s;
}

.btn-arrow-text__icon {
  display: block;
  font-size: 20px;
  transition: transform .3s;
}