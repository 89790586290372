.title-set {
  color: $c-main-light;
  padding-top: 16px;
  margin-bottom: 72px;

  @include media('below-small') {
    margin-bottom: 40px;
  }

  &.-black {
    color: $c-main-dark;
  }
  &.-rwd-dark {
    @include media('below-small') {
      color: $c-main-dark;
    }
  }

  &.-inline {
    @extend %flex;
    align-items: center;

    & .title-set__en {
      margin-right: 20px;
    }
  }

  & .title-set__en {
    font-family: $font-en-serif;
    font-size: 36px;
    letter-spacing: 1.6px;
    line-height: 46px;

    @include media('below-small') {
      font-size: 24px;
      letter-spacing: 1px;
      line-height: 30px;
    }
  }
  & .title-set__zh {
    font-family: $font-zh-serif;
    font-weight: $font-zh-serif-medium;
    font-size: 26px;
    letter-spacing: 3.6px;
    line-height: 46px;

    @include media('below-small') {
      font-size: 16px;
      letter-spacing: 2.4px;
      line-height: 23px;
    }
  }
}