// $menu-container-width: calc(( ( 100vw - #{$inner-wrap} ) / 2) + ( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 3 + #{$gutter} * 2);
$menu-container-width: 30%;
$menu-transition: all .3s ease-out;

.menu {
  position: absolute;
  right: 0;
  overflow-x: hidden;
  z-index: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;

  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($c-main-dark, 0.7);

    opacity: 0;
    will-change: transform;

    @include media('below-desktop') {
      display: none;
    }
  }

  &.-is-active {
    z-index: $z-menu;
    pointer-events: visible;

    &:after {
      opacity: 1;
      transition: all .1s;
      will-change: transform;
    }

    & .menu__container {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: all .4s ease-out;
      will-change: transform;
    }
  }
}

.menu__container {
  @extend %flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: auto;
  padding: 60px 5vw 32px 5vw;
  height: 100%;
  width: $menu-container-width;
  background-color: $c-main-dark;

  opacity: 0;
  transform: translate3d($menu-container-width, 0, 0);
  transition: all .4s;
  will-change: transform;

  @include media('below-desktop') {
    padding: 40px 10vw;

    width: 100%;
    transform: translate3d(50%, 0, 0);
    transition: all .4s ease-out;
  }
}

.menu__wrapper {
  max-height: 100%;
}

.menu__top {
  @extend %flex-between-center;
  margin-bottom: 200px;

  @include media('below-desktop') {
    margin-bottom: 15vh;
  }

  & .menu__top-btn {
    font-size: 20px;
    color: $gray-3;

    @include media('above-desktop') {
      &:hover {
        color: $c-main-light;
      }
    }
  }

  & .menu__top-link {
    font-size: 14px;
    letter-spacing: 0.7px;
    color: $gray-3;
    margin-right: $col-width;

    @include media('above-desktop') {
      &:hover {
        color: $c-main-light;
      }
    }
    @include media('below-desktop') {
      font-size: 13px;
      letter-spacing: 0.65px;
    }
  }
  & .menu__top-language {
    font-family: $base-font-medium;
    font-size: 14px;
    letter-spacing: 0.7px;
    color: $c-font-gray-light;

    @include media('above-desktop') {
      &:hover {
        color: $c-main-light;
      }
    }
    @include media('below-desktop') {
      font-size: 13px;
      letter-spacing: 0.65px;
    }
  }
}

.menu__links {

  & .menu__links-link {
    @extend %last-no-margin;
    margin-bottom: 24px;

    @include media('below-desktop') {
      margin-bottom: 40px;
    }

    & a {
      font-size: 24px;
      letter-spacing: 4px;
      color: $gray-3;

      @include media('above-desktop') {
        &:hover {
          color: $c-main-light;
        }
      }
      @include media('below-desktop') {
        font-size: 20px;
        letter-spacing: 3.7px;
      }
    }
  }
}

.menu__socials {
  @extend %flex;

  & .menu__socials-link {
    @extend %last-no-margin;
    margin-right: 32px;
  }

  & a .icon {
    font-size: 20px;
    color: $gray-3;

    @include media('above-desktop') {
      &:hover {
        color: $c-main-light;
      }
    }
  }
}