.container {
	@extend %position-relative;
	@extend %lost-clear;
	@extend %full-width;
	max-width: $inner-wrap;
	margin: 0 auto;

	@include media('below-medium') {
		max-width: $wrapper-rwd-m;
	}
	@include media('below-small') {
		padding: 0 $rwd-padding;
	}
}

.container.-l {
	max-width: $outer-wrap;

	@include media('below-medium') {
		max-width: $wrapper-rwd-m;
	}
	@include media('below-small') {
		padding: 0 $rwd-padding;
	}
}

.wrapper-s,
%wrapper-s {
	@extend %position-relative;
	padding: 0 calc(( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) + #{$gutter});
}