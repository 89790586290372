.btn-dash {
  @extend %flex-between-center;
  position: relative;

  &.-reverse {
    & .btn-dash__text {
      padding: 0 0 0 16px;
    }
  }

  &:hover {
    @include media('above-desktop') {
      & .btn-dash__text {
        transform: translateX(30%);
        transition: all .5s;
      }
      & .btn-dash__dash {
        margin-left: 10%;
        transition: all .5s;
      }

      &.-reverse {
        & .btn-dash__text {
          transform: translateX(-30%);
          transition: all .5s;
        }
        & .btn-dash__dash {
          margin: 0 10% 0 0;
        }
      }

      // & .btn-dash__dash:before {
      //   width: 100%;
      //   transition: width .3s;
      // }
    }
  }
}

.btn-dash__text {
  display: inline-block;
  font-size: 13px;
  letter-spacing: 2.6px;
  padding-right: 16px;
  transition: all .5s;

  @include media('below-small') {
    font-size: 11px;
    letter-spacing: 2.2px;
  }

  .-bright & {
    color: $c-main-light;
  }
}

.btn-dash__dash {
  position: relative;
  display: block;
  flex-grow: 2;
  height: 1px;
  background-color: $c-main-dark;
  transition: all .5s;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    background-color: $c-matcha;
    transition: width .3s;

    .-bright & {
      background-color: $c-main-light;
    }
  }

  .-bright & {
    background-color: $c-font-gray;
  }
}