.news {
  &:before {
    content: '';
    position: absolute;
    z-index: 0;
    left: -32px;
    top: 0;
    height: calc(#{$header-m} + 180px);
    width: calc(70% + 18px);
    background-color: $c-beige;
    display: none;

    @include media('below-small') {
      display: block;
    }
  }
}

.news-header,
.news-posts__card, {
  position: relative;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    z-index: -2;
    left: calc(( ( 100vw - #{$inner-wrap} ) / 2) * -1);
    top: calc(#{$header} * -1);
    height: calc(100% + #{$header});
    width: calc(( ( 100vw - #{$inner-wrap} ) / 2) + ( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 8 + #{$gutter} * 8);
    background-color: $c-beige;

    @include media('below-medium') {
      left: calc(( ( 100vw - #{$wrapper-rwd-m} ) / 2) * -1);
      width: calc(( ( 100vw - #{$wrapper-rwd-m} ) / 2) + ( ( #{$wrapper-rwd-m} - #{$gutter} * 11 ) / 12) * 8 + #{$gutter} * 8);
    }
    @include media('below-small') {
      left: -32px;
      top: calc(#{$header-m} * -1);
      height: calc(100% + #{$header-m});
      width: calc(70% + 32px);
    }
  }
}

.news-select.list-menu.-select {
  position: relative;
  z-index: 1;
  margin-bottom: 40px;
  width: calc(70% + 32px);

  &:before {
    content: '';
    position: absolute;
    z-index: -2;
    left: -32px;
    top: 0;
    height: calc(100% + 40px);
    width: 100%;
    background-color: $c-beige;
  }

  & .news-select__wrapper {
    width: 75%;
  }
}

.news-header {
  padding-bottom: 40px;
  & .title-set {
    margin-bottom: 0;
  }
}

.news-menu {
  position: relative;
  z-index: 2;
  padding-top: 56px;
}

.news-carousel {
  position: relative;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    left: calc(( ( 100vw - #{$inner-wrap} ) / 2) * -1 - ( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 2 - #{$gutter});
    top: 0;
    height: 100%;
    width: calc(( ( 100vw - #{$inner-wrap} ) / 2) + ( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 8 + #{$gutter} * 8);
    background-color: $c-beige;

    @include media('below-medium') {
      left: calc(( ( 100vw - #{$wrapper-rwd-m} ) / 2) * -1 - ( ( #{$wrapper-rwd-m} - #{$gutter} * 11 ) / 12) * 2 - #{$gutter} * 2);
      width: calc(( ( 100vw - #{$wrapper-rwd-m} ) / 2) + ( ( #{$wrapper-rwd-m} - #{$gutter} * 11 ) / 12) * 8 + #{$gutter} * 8);
    }
    @include media('below-small') {
      left: -32px;
      width: calc(70% + 32px);
    }
  }
  &:after {
    content: '';
    position: absolute;
    z-index: -2;
    left: calc(( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 4 + #{$gutter} * 4);
    bottom: 90px;
    height: calc((100% - 90px) / 2);
    width: calc(( ( 100vw - #{$inner-wrap} ) / 2) + ( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 10 + #{$gutter} * 10);
    background-color: $gray-0;

    @include media('below-small') {
      display: none;
    }
  }

  &.carousel.-default-style .news-carousel__slides {
    height: 480px;
  }

  & .news-carousel__dots.carousel__dots {
    margin-bottom: 0;
    padding-bottom: 60px;

    @include media('below-small') {
      padding-bottom: 40px;
    }
  }
}

.news-posts {
  & .news-posts__card {
    &:before {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      left: calc(( ( 100vw - #{$inner-wrap} ) / 2) * -1 - ( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 3 - #{$gutter} * 3);
      height: 100%;
      width: calc(( ( 100vw - #{$inner-wrap} ) / 2) + ( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 9 + #{$gutter} * 10);
      background-color: $c-beige;

      @include media('below-medium') {
        width: calc(( ( 100vw - #{$wrapper-rwd-m} ) / 2) + ( ( #{$wrapper-rwd-m} - #{$gutter} * 11 ) / 12) * 9 + #{$gutter} * 9);
        left: calc(( ( 100vw - #{$wrapper-rwd-m} ) / 2) * -1 - ( ( #{$wrapper-rwd-m} - #{$gutter} * 11 ) / 12) * 3 - #{$gutter} * 3);
      }
      @include media('below-small') {
        width: calc(70% + 32px);
        left: -32px;
      }
    }
    &:after {
      content: '';
      position: absolute;
      z-index: -2;
      left: calc(( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 4 + #{$gutter} * 4);
      top: 0;
      height: 100%;
      width: calc(( ( 100vw - #{$inner-wrap} ) / 2) + ( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 10 + #{$gutter} * 10);
      background-color: $gray-0;

      @include media('below-medium') {
        left: calc(( ( #{$wrapper-rwd-m} - #{$gutter} * 11 ) / 12) * 6 + #{$gutter} * 6);
        width: calc(( ( 100vw - #{$wrapper-rwd-m} ) / 2) + ( ( #{$wrapper-rwd-m} - #{$gutter} * 11 ) / 12) * 10 + #{$gutter} * 10);
      }
      @include media('below-small') {
        left: auto;
        right: -32px;
        height: 100%;
        width: calc(30% + 32px);
      }
    }
    &:first-child {
      &:after {
        @include media('below-small') {
          top: auto;
          bottom: 0;
          height: calc(100% - 150px);
        }
      }
    }
    &:last-child {
      &:before {
        height: 50%;

        @include media('below-small') {
          height: 65%;
        }
      }
    }
    &.card-img {
      margin-bottom: 0;
      padding-bottom: 64px;

      @include media('below-small') {
        padding-bottom: 40px;
        &:last-child {
          padding-bottom: 0;
        }
      }
    }
    & .card-img__info-side {
      width: calc(( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 4 + #{$gutter} * 3);

      @include media('below-medium') {
        width: calc(( ( #{$wrapper-rwd-m} - #{$gutter} * 11 ) / 12) * 4 + #{$gutter} * 3);
      }
      @include media('below-small') {
        width: 100%;
      }
    }
  }
}

.news {
  & .pagination {
    &:after {
      background-color: $gray-0;
    }
  }
}