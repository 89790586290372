$village-hero-h: 480px;
$village-hero-h-m: 175px;

.village {
  position: relative;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    z-index: -2;
    left: 0;
    top: calc(#{$header} * -1);
    height: calc(100% + #{$header} + #{$footer});
    width: calc(( ( 100vw - #{$inner-wrap} ) / 2) + ( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 6 + #{$gutter} * 5);
    background-color: $c-beige;

    @include media('below-small') {
      top: calc(#{$header-m} * -1);
      height: calc(100% + #{$header-m} + #{$footer-m});
    }
  }
}

.village-right {
  position: relative;
  width: calc(( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 9 + #{$gutter} * 9);
  margin-left: auto;

  @include media('below-medium') {
    width: calc(( ( #{$wrapper-rwd-m} - #{$gutter} * 11 ) / 12) * 9 + #{$gutter} * 9);
  }
  @include media('below-small') {
    width: 100%;
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    right: calc(( ( 100vw - #{$inner-wrap} ) / 2) * -1);
    bottom: 0;
    height: calc(100% - #{$village-hero-h}/2);
    width: calc(100% - ( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) + ( ( 100vw - #{$inner-wrap} ) / 2));
    background-color: $c-military;

    @include media('below-medium') {
      // height: 85%;
      right: calc(( ( 100vw - #{$wrapper-rwd-m} ) / 2) * -1);
      width: calc(100% - ( ( #{$wrapper-rwd-m} - #{$gutter} * 11 ) / 12) + ( ( 100vw - #{$wrapper-rwd-m} ) / 2));
    }
    @include media('below-small') {
      right: -32px;
      width: calc(100% + 32px);
    }
    @include media('below-tablet') {
      top: calc(#{$village-hero-h-m}/2);
      bottom: auto;
      height: calc(100% - #{$village-hero-h-m}/2);
    }
  }

  &.-bg-brick:after {
    background-color: $c-brick;
  }
}

.village-column {
  @include media('below-small') {
    flex-direction: column;

    &.column.-cl-3-9 .column__item:first-child {
      width: 100%;
    }
  }
}

.village-select {
  width: 100%;
  margin-bottom: 32px;
}

.village-carousel {
  &.carousel.-default-style {
    & .carousel__slides {
      height: $village-hero-h;
      width: 100%;
    }
    & .carousel__arrows {
      top: calc(50% - 48px + 12px);
      width: 100%;
    }
    & .carousel__dots {
      margin-bottom: 16px;
    }
  }
}

.village__hero {
  position: relative;
  margin-bottom: 72px;

  @include media('below-small') {
    margin-bottom: 24px;
  }

  & .village__hero-img {
    @extend %background-cover;
    height: $village-hero-h;
    min-height: $village-hero-h;
    width: 100%;
    margin-left: auto;

    @include media('below-tablet') {
      height: $village-hero-h-m;
      min-height: $village-hero-h-m;
    }
  }
}

.village-content {
  @extend %flex-between;
  width: calc(100% - ( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ));
  margin-left: auto;
  color: $c-main-light;

  @include media('below-small') {
    flex-direction: column-reverse;
    width: 90%;
  }

  & .village-content__main {
    padding-left: calc(( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) + #{$gutter} * 2);
    flex-grow: 2;

    @include media('below-small') {
      padding-left: 0;
      padding-bottom: 40px;
    }
  }
  & .village-content__side {
    margin-left: calc(( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) / 2 + #{$gutter});

    @include media('below-small') {
      margin-left: auto;
    }
  }
  & .village-content__main-mid {
    padding-bottom: 54px;
    margin-bottom: 48px;
    border-bottom: 1px solid $c-main-light;
  }
  & .village-content__main-btm {
    padding-bottom: 126px;

    @include media('below-small') {
      padding-bottom: 0;
      & .village-content__list {
        margin-bottom: 40px;
      }
    }
  }
  & .village-content__main-btm-share {
    width: 150px;
    display: none;
    @include media('below-small') {
      display: block;
    }
  }
  & .village-content__paragraph {
    @extend %last-no-margin-btm;
    font-size: 15px;
    letter-spacing: 1px;
    line-height: 30px;
    margin-bottom: 26px;

    @include media('below-small') {
      font-size: 13px;
      letter-spacing: 1.2px;
      line-height: 27px;
    }
  }
  & .village-content__icons {

    @include media('below-small') {
      display: flex;
      margin-bottom: 32px;
    }

    & .village-content__icon {
      @extend %last-no-margin;
      margin-bottom: 24px;
      & .icon {
        font-size: 26px;
        color: $c-main-gray;
      }
      @include media('above-desktop') {
        &:hover {
          & .icon {
            color: $c-main-light;
          }
        }
      }
      @include media('below-small') {
        margin-bottom: 0;
        margin-right: 16px;

        &:last-child {
          margin-right: 0;
        }
        & .icon {
          font-size: 18px;
        }
      }
    }
  }
  & .village-content__list {
    font-size: 15px;
    letter-spacing: 1.2px;
    line-height: 30px;
    color: $gray-1;

    @include media('below-small') {
      font-size: 13px;
      line-height: 27px;
    }

    & > * > a {
      color: $gray-1;
      border-bottom: 1px solid $gray-1;
      @include media('above-desktop') {
        &:hover {
          color: $c-main-light;
          border-bottom-color: $c-main-light;
        }
      }
    }
  }
  & .village-content__main-top {
    & .info-btn-bar__right {
      @include media('below-small') {
        display: none;
      }
    }
  }
}