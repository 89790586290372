// font-family - font-weight - font-size
@mixin font($family, $weight, $size, $spacing: 0, $height: normal) {
	font-family: $family;
	font-weight: $weight;
	font-size: $size;
	letter-spacing: $spacing;
	line-height: $height;
}

.-f-base-m {
	font-family: $base-font-medium;
}