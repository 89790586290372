.btn-basic {
  display: block;
  padding: 10px 32px;
  font-size: 13px;
  letter-spacing: 3.2px;
  color: $c-main-light;
  border: 1px solid transparent;
  background-color: $c-main-dark;
  transition: all .3s;

  @include media('above-desktop') {
    &:hover {
      cursor: pointer;
      color: $c-main-dark;
      border-color: $c-main-dark;
      background-color: transparent;
      transition: all .3s;
    }
  }
}