$fadein-transition: all .5s ease-out;

.fade-in {
  &:not(.-pseudo) {
    opacity: 0;
    transform: translateY(80px);
    transition: $fadein-transition;
  }

  &.-pseudo {
    &.-after {
      &:after {
        opacity: 0;
        transform: translateY(80px);
        transition: $fadein-transition;
      }
    }
    &.-before {
      &:before {
        opacity: 0;
        transform: translateY(80px);
        transition: $fadein-transition;
      }
    }
  }

  &.-is-shown {
    opacity: 1;
    transform: translateY(0);
    transition: $fadein-transition;

    &.-pseudo {
      &.-after {
        &:after {
          opacity: 1;
          transform: translateY(0);
          transition: $fadein-transition;
        }
      }
      &.-before {
        &:before {
          opacity: 1;
          transform: translateY(0);
          transition: $fadein-transition;
        }
      }
    }
  }
}