.contact {
  position: relative;
  z-index: 1;
  min-height: calc(100vh - #{$footer} - #{$header});

  @include media('below-small') {
    min-height: calc(100vh - #{$footer-m} - #{$header-m});
  }

  &:before {
    content: '';
    position: absolute;
    z-index: 0;
    left: 0;
    top: calc(#{$header} * -1);
    height: calc(100% + #{$header} + #{$footer});
    width: calc(( ( 100vw - #{$inner-wrap} ) / 2) + ( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 5 + #{$gutter} * 4);
    background-color: $c-main-light;

    @include media('below-medium') {
      width: calc(( ( 100vw - #{$wrapper-rwd-m} ) / 2) + ( ( #{$wrapper-rwd-m} - #{$gutter} * 11 ) / 12) * 5 + #{$gutter} * 4);
    }
    @include media('below-small') {
      display: none;
    }
  }
  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    right: 0;
    top: calc(#{$header} * -1);
    height: calc(100% + #{$header} + #{$footer});
    width: 100vw;
    background-color: $c-matcha;

    @include media('below-small') {
      display: none;
    }
  }
}

.contact__column {
  padding-top: 80px;

  @include media('below-small') {
    padding-top: 64px;
    flex-direction: column;
  }

  & .column__item {
    position: relative;
  }

  &.-cl-5-7 .column__item:first-child {
    @include media('below-desktop') {
      width: 100%;
    }
  }

  & .column__item:first-child {
    @include media('below-small') {
      padding-bottom: 80px;
    }
    &:before {
      content: '';
      position: absolute;
      z-index: -1;
      left: -32px;
      top: -64px;
      height: calc(100% + 64px);
      width: 100vw;
      background-color: $c-main-light;

      @include media('below-small') {
        display: block;
      }
    }
  }
}

.contact-header {
  position: relative;
  padding-top: 100px;

  @include media('below-small') {
    padding-top: 48px;

    &:before {
      content: '';
      position: absolute;
      z-index: -1;
      left: -32px;
      top: calc(#{$header-m} * -1);
      height: calc(100% + #{$header-m});
      width: 100vw;
      background-color: $c-main-light;
    }
  }
  & .contact-header__title {
    font-size: 26px;
    letter-spacing: 4px;

    @include media('below-small') {
      font-size: 16px;
      letter-spacing: 2.4px;
    }
  }
}

.contact-block {
  @extend %last-no-margin-btm;
  margin-bottom: 40px;

  & .contact-block__title {
    font-family: $base-font-medium;
  }

  & .contact-block__title,
  & .contact-block__item {
    text-align: left;
    font-size: 15px;
    letter-spacing: 1.8px;
    line-height: 30px;

    @include media('below-small') {
      font-size: 13px;
      letter-spacing: 1.6px;
      line-height: 27px;
    }
  }
}

.contact-form {
  position: relative;
  padding-left: calc(( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) + #{$gutter} * 2);

  @include media('below-desktop') {
    padding-left: 0;
  }

  @include media('below-small') {
    padding: 58px 0 72px 0;

    &:before {
      content: '';
      position: absolute;
      z-index: -1;
      left: -32px;
      top: 0;
      height: 100%;
      width: 100vw;
      background-color: $c-matcha;
    }
  }
}

.contact-btm {
  & .contact-btm__text {
    font-size: 11px;
    letter-spacing: 1.57px;
    line-height: 26px;
  }
}