.card-img {
  @extend %flex;
  @extend %last-no-margin-btm;

  margin-bottom: 64px;

  @include media('below-small') {
    flex-direction: column;
    margin-bottom: 40px;
  }

  &.-skin {
    & .card-img__info-side {
      background-color: $c-skin;
    }
  }
  &.-green {
    & .card-img__info-side {
      background-color: $c-matcha;
    }
  }
  &.-gray {
    & .card-img__info-side {
      background-color: $c-block-gray;
    }
  }
}

.card-img__img-side {
  flex-grow: 2;
}

.card-img__img {
  @extend %background-cover;
  min-height: 360px;
  width: 100%;

  @include media('below-small') {
    min-height: 240px;
  }
  @include media('below-tablet') {
    min-height: 175px;
  }
}

.card-img__info-side {
  width: 40%;
  padding: 30px 32px 24px 32px;
  background-color: $c-main-light;
  min-height: 360px;

  @include media('below-small') {
    width: 100%;
    min-height: 240px;
  }
  @include media('below-tablet') {
    min-height: 150px;
  }

  & .card-img__info {
    @extend %flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  &:hover {
    @include media('above-desktop') {
      color: $c-main-dark;

      & .btn-dash__text {
        transform: translateX(30%);
        transition: $transition;
      }
      & .btn-dash__dash {
        margin-left: 10%;
        transition: $transition;
      }
    }
  }
}

.card-img__info-top {
  @extend %flex-between-center;
  padding-bottom: 18px;
  margin-bottom: 22px;
  border-bottom: 1px solid $c-main-dark;

  & .card-img__info-top-title {
    font-size: 15px;
    letter-spacing: 2.5px;
  }
  & .card-img__info-top-date {
    font-size: 13px;
    letter-spacing: 1.4px;
  }

  @include media('below-small') {
    & .card-img__info-top-title {
      font-size: 11px;
      letter-spacing: 1.8px;
    }
    & .card-img__info-top-date {
      font-size: 11px;
      letter-spacing: 1.2px;
    }
  }
}

.card-img__info-mid {
  padding: 0 2px;
}
.card-img__info-subtitle {
  font-size: 15px;
  letter-spacing: 0.8px;
  margin-bottom: 18px;

  @include media('below-small') {
    font-size: 11px;
    letter-spacing: 0.6px;
  }
}
.card-img__info-title {
  font-size: 22px;
  letter-spacing: 1.6px;
  line-height: 32px;

  @include media('below-small') {
    font-size: 16px;
    letter-spacing: 1.2px;
    line-height: 26px;
    margin-bottom: 72px;
  }
}

.card-img__info-btm {
  padding-bottom: 4px;
  border-bottom: 1px solid $c-main-dark;

  & .card-img__info-btn {
    padding-bottom: 16px;
    border-bottom: 1px solid $c-main-dark;
  }
}

.card-img.-large {
  & .card-img__info-side {
    width: calc(( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 4 + #{$gutter} * 4);
    padding: 45px 48px;

    @include media('below-medium') {
      width: calc(( ( #{$wrapper-rwd-m} - #{$gutter} * 11 ) / 12) * 4 + #{$gutter} * 4);
    }
    @include media('below-small') {
      width: 100%;
      padding: 32px 34px;
    }
  }
  & .card-img__img {
    min-height: 600px;

    @include media('below-small') {
      min-height: 315px;
    }
  }
  & .card-img__info-top {
    display: block;
    padding-bottom: 85px;
    margin-bottom: 16px;

    @include media('below-small') {
      padding-bottom: 16px;
      margin-bottom: 16px;
    }

    & .card-img__info-top-title {
      font-family: $font-en-serif;
      font-size: 20px;
      letter-spacing: 1.5px;
      margin-bottom: 10px;

      @include media('below-small') {
        margin-bottom: 8px;
      }
    }
    & .card-img__info-top-subtitle {
      font-family: $font-zh-serif;
      font-weight: $font-zh-serif-medium;
      font-size: 16px;
      letter-spacing: 1.2px;
    }
  }
  & .card-img__info-bar {
    @extend %flex;
    margin-bottom: 40px;

    @include media('below-small') {
      justify-content: space-between;
      width: 100%;
      margin-bottom: 16px;
    }
  }
  & .card-img__info-date {
    font-size: 15px;
    letter-spacing: 1.4px;
    margin-right: 24px;

    @include media('below-small') {
      font-size: 11px;
      letter-spacing: 1.18px;
    }
  }
  & .card-img__info-type {
    font-size: 15px;
    letter-spacing: 2.5px;

    @include media('below-small') {
      font-size: 11px;
      letter-spacing: 1.83px;
    }
  }
}