header {
  position: fixed;
  width: 100vw;
  z-index: $z-header;
  transition: all .3s;
  will-change: transform;

  &.-is-inflate {
    @include media('above-small') {
      height: 140px;
    }

    & .header-wrapper {
      transform: translateY(30px);
      transition: transform .5s;

      @include media('below-small') {
        transform: translateY(0);
      }
    }
  }
  &.-with-bg {
    transition: all .5s;
    background-color: $c-main-gray;

    & .header-logo.-bright {
      color: $c-main-dark;
    }
  }
  &.-is-hidden {
    transform: translateY(- #{$header});
    transition: transform .3s;
  }

  @include media('below-small') {
    position: fixed;

    &.-with-bg {
      transition: all .3s;
      background-color: $gray-1;
    }
  }
}

.header-wrapper {
  @extend %flex-center-horizontal;
  width: 100%;
  height: $header-shrink;
  padding: 0 5vw;

	@include media('below-small') {
		height: $header-m;
	}
}

.header-logo {
  & .icon {
    font-size: 40px;
    transition: all .3s;
    will-change: font-size;
    .-is-inflate & {
      font-size: 60px;
      transition: all .3s;
      will-change: font-size;

      @include media('below-small') {
        font-size: 36px;
      }
    }
  }
  &.-index {
    .-is-inflate & {
      & .icon {
        font-size: 70px;
        transition: all .3s;
        will-change: font-size;

        @include media('below-small') {
          font-size: 36px;
        }
      }
    }
  }
  &.-bright {
    color: $c-main-light;
  }

  @include media('below-small') {
    & .icon {
      font-size: 36px;
    }
  }
}

.header-right {
  @extend %flex-center-horizontal;
}

.header-links {
  @extend %flex;

  & .header-links__item {
    @extend %flex;
    align-items: center;
    margin-right: 40px;

    &:last-child {
      margin-right: 80px;
    }

    @include media('below-small') {
      margin-right: 48px;
      &:first-child {
        margin-right: 20px;
      }
      &.-rwd-hide {
        display: none;
      }
    }
  }
  & .header-links__link {
    font-size: 14px;
    letter-spacing: 2.4px;

    @include media('below-small') {
      font-size: 12px;
      letter-spacing: 2px;
    }
  }
}

.header-ham {
  display: block;
  position: fixed;
  top: 24px;
  right: 5vw;
  z-index: 90;
  height: 16px;
  width: 26px;
  padding: 16px;
  transition: $transition;
  transition: transform .5s;
  will-change: transform;

  &.-is-hidden {
    transform: translateY(- #{$header});
    transition: all .5s;
  }
  &.-is-inflate {
    transform: translateY(30px);
    transition: transform .5s;

    @include media('below-small') {
      transform: translateY(0);
    }
  }

  &.-menu-opened {
    margin-right: 8px;
    & .header__ham-line {
      background-color: $gray-3;

      &:first-child {
        top: 15px;
        transform: rotate(45deg);
      }
      &:last-child {
        bottom: 15px;
        transform: rotate(-45deg);
      }
    }
    &:hover {
      & .header__ham-line {
        background-color: $c-main-light;
      }
    }
  }

  &:hover {
    & .header__ham-line {
      transition: $transition;
      background-color: $c-font-hover;
    }
  }
  @include media('below-small') {
    top: 31px;
    height: 14px;
    width: 24px;
  }

  & .header__ham-line {
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: $c-main-dark;
    transition: $transition;
  }
  & .header__ham-line:first-child {
    top: 8px;
    @include media('below-small') {
      top: 10px;
    }
  }
  & .header__ham-line:last-child {
    bottom: 8px;
    @include media('below-small') {
      bottom: 10px;
    }
  }
}