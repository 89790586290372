.breadcrumb {
  text-align: right;
  margin-bottom: 40px;

  @include media('below-small') {
    text-align: left;
    margin-bottom: 24px;
  }

  & .breadcrumb__bar {
    font-size: 13px;
    letter-spacing: 1.6px;
    margin-left: auto;
    color: $c-font-gray-light;

    @include media('below-small') {
      line-height: 22px;
    }
  }
  & .breadcrumb__link {
    color: $c-font-gray-light;
  }
  & .breadcrumb__current {
    color: rgba(black, 0.7);
  }
}