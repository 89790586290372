.post-main,
.news-post-main {
  &.-skin {
    &:before {
      background-color: $c-skin;
    }
  }
  &.-green {
    &:before {
      background-color: $c-matcha;
    }
  }
  &.-gray {
    &:before {
      background-color: $c-block-gray;
    }
  }
  &.-military {
    &:before {
      background-color: $c-military;
    }
  }
}

.village-right {
  &.-skin {
    &:after {
      background-color: $c-skin;
    }
  }
  &.-green {
    &:after {
      background-color: $c-matcha;
    }
  }
  &.-gray {
    &:after {
      background-color: $c-block-gray;
    }
  }
  &.-military {
    &:after {
      background-color: $c-military;
    }
  }
}