
.pagination {
  position: relative;
  z-index: 2;
  margin-left: calc(( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) + #{$gutter});
  padding: 48px 0 112px 0;

  @include media('below-small') {
    display: flex;
    align-items: center;
    // justify-content: flex;
    margin-left: 0;
    padding-bottom: 64px;
  }

  &:after {
    content: '';
    position: absolute;
    z-index: -2;
    left: calc(( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 2 + #{$gutter} * 2);
    top: -64px;
    height: calc(100% + 64px);
    width: calc(( ( 100vw - #{$inner-wrap} ) / 2) + ( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 9 + #{$gutter} * 9);
    background-color: $c-brick;

    @include media('below-small') {
      top: 0;
      left: auto;
      right: -32px;
      height: 100%;
      width: calc(32px + 80%);
    }
  }

  & .pagination__counts {
    @extend %flex;
    position: relative;
    z-index: 2;

    & .pagination__num {
      @extend %last-no-margin;
      font-family: $font-en;
      font-size: 14px;
      padding: 8px;
      margin-right: 24px;

      @include media('below-small') {
        margin-right: 12px;
        &:last-child { margin-right: 12px; }
      }

      &:last-child {
        position: relative;

        &:after {
          content: '';
          position: absolute;
          z-index: 0;
          left: 52px;
          top: 50%;
          height: 1px;
          width: 1000%;
          background-color: $c-main-dark;

          @include media('below-medium') {
            // left: 24px;
            // right: calc(( ( #{$wrapper-rwd-m} - #{$gutter} * 11 ) / 12) * 3 + #{$gutter} * 4);
            // width: calc(( ( #{$wrapper-rwd-m} - #{$gutter} * 11 ) / 12) * 3 + #{$gutter} * 3);
          }
          @include media('below-small') {
            // display: none;
          }
        }
      }
    }
  }

  & .pagination__decor {
    @include media('below-small') {
      display: block;
      height: 1px;
      width: 100%;
      background-color: $c-main-dark;
    }
  }
}