/* ------------------------------------
 *  Width and Height
 * ------------------------------------ */

/*wrap*/
$inner-wrap: 1200px;
$outer-wrap: 1325px;
$wrapper-rwd-m: 920px;
$rwd-padding: 32px;
$gutter: 20px;
$col-width: calc( ( #{$inner-wrap} - #{$gutter} * 11) / 12 );
$col-width-m: calc( ( #{$wrapper-rwd-m} - #{$gutter} * 11 ) / 12);
$col-width-s: calc( ( 100vw - #{$gutter} * 11 ) / 12);
$extra-padding: calc( ( 100vw - #{$inner-wrap} ) / 2);
$extra-padding-m: calc( ( 100vw - #{$wrapper-rwd-m} ) / 2);
$extra-padding-s: calc( 100vw - 100% / 2);

/*components*/
$header: 150px;
$header-shrink: 80px;
$header-m: 96px;
$footer: 470px;
$footer-m: 640px;

/*z-index*/
$z-menu: 80;
$z-header: 60;
$z-floats: 50;
$z-modal: 200;
$z-lightbox: 200;


/* ------------------------------------
 *  Color
 * ------------------------------------ */

$dark-0: #20271E;
$gray-0: #EFEFEF;
$gray-1: #F4F4F4;
$gray-3: #DADADA;
$gray-4: #4A4A4A;
$gray-5: #878787;

$c-beige: #E4DDCF;
$c-skin: #F3D9B3;
$c-brick: #BA8553;
$c-forest: #20271E;
$c-military: #4B5346;
$c-matcha: #C7CBB8;

$c-error: #FF2929;
$c-main-dark: black;
$c-main-light: white;
$c-main-gray: $gray-0;

$c-font-gray: $gray-4;
$c-font-gray-light: $gray-5;
$c-font-hover: $c-font-gray;

$c-block-dark: $dark-0;
$c-block-light: $c-main-gray;
$c-block-gray: $gray-3;
$c-bg-main: $c-main-gray;

$default-color: $c-main-dark;


/* ------------------------------------
 *  Styles
 * ------------------------------------ */

 $transition: all .3s;


/* ------------------------------------
 *  Font
 * ------------------------------------ */

$font-zh: "PingFangTC-Regular", "微軟正黑體", Helvetica, Arial, sans-serif;
$font-zh-light: "PingFangTC-Light", "微軟正黑體", Helvetica, Arial, sans-serif;
$font-zh-medium: "PingFangTC-Medium", "微軟正黑體", Helvetica, Arial, sans-serif;
$font-zh-serif: 'source-han-serif-tc', serif;
$font-en: "Open Sans", Helvetica, "微軟正黑體", Arial, sans-serif;
$font-en-serif: "Aurelia W01 Book", "Baskerville", "Times New Roman", serif;

$font-zh-serif-medium: 500;
$font-en-light: 300;
$font-en-medium: 600;

$base-font: $font-zh;
$base-font-light: $font-zh-light;
$base-font-medium: $font-zh-medium;
