.sandwich__bar {
  @extend %flex;
  justify-content: space-between;
  @extend %last-no-margin-btm;
  width: 100%;
  min-width: 100%;
  padding: 0 2px 18px 2px;
  margin-bottom: 16px;
  border-bottom: 1px solid $c-font-gray-light;

  @include media('below-medium') {
    justify-content: initial;
  }

  & .sandwich__bar-first,
  & .sandwich__bar-mid,
  & .sandwich__bar-last, {
    font-size: 15px;
    letter-spacing: 1.2px;
    line-height: 16px;

    @include media('below-medium') {
      font-size: 11px;
    }
  }

  & .sandwich__bar-first {
    flex-grow: 1;
    min-width: 10%;

    @include media('below-medium') {
      flex-grow: 0;
      min-width: 30%;
      // margin-right: 5%;
    }
  }
  & .sandwich__bar-mid {
    flex-grow: 2;
    min-width: 82%;

    @include media('below-medium') {
      min-width: 0;
      max-width: 75%;
    }
    @include media('below-small') {
      max-width: 70%;
    }
    @include media('below-tablet') {
      max-width: 50%;
    }
  }
  & .sandwich__bar-last {
    @include media('below-medium') {
      flex-grow: 1;
      text-align: right;
    }
  }
}