.form-basic__wrapper {
  margin-bottom: 40px;

  @include media('below-small') {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.form-basic__bar {
  @extend %flex-between-center;;
  @extend %position-relative;
  @extend %last-no-margin;
  margin-bottom: 26px;
  width: 100%;

  @include media('below-small') {
    margin-bottom: 24px;
  }

  & .icon {
    @extend %position-absolute;
    bottom: 10px;
    right: 16px;

    @include media('below-small') {
      bottom: 8px;
    }

    &.icon-chevron {
      transform: rotate(90deg);
    }
  }
  &.-is-error {
    .form-basic__bar-title {
      color: $c-error;
    }
  }
  &.-textarea {
    align-items: flex-start;

    & .form-bbasic__bar-title {
      margin-top: 4px;
    }
  }
}

.form-basic__bar-title {
  @extend %flex-between;
  width: 15%;
  min-width: 15%;

  @include media('below-small') {
    margin-bottom: 0;
  }

  .form-basic__bar-label {
    font-size: 15px;
    letter-spacing: 3.75px;
    line-height: 30px;

    @include media('below-small') {
      font-size: 13px;
      letter-spacing: 2.4px;
    }
  }
  .form-basic__bar-msg {
    font-size: 13px;
    letter-spacing: 1.3px;
    line-height: 30px;

    @include media('below-small') {
      font-size: 12px;
    }
  }
}

.form-basic__bar-input,
.form-basic__bar-select,
.form-basic__bar-textarea {
  font-family: $base-font-light;
  font-size: 15px;
  letter-spacing: 1.69px;
  width: 100%;
  padding: 10px 16px;
  border: 1px solid $c-main-dark;
  border-radius: 0 !important;
  box-shadow: none !important;
  -webkit-appearance: none;

  &::placeholder {
    color: $c-font-gray;
  }

  @include media('below-small') {
    font-size: 13px;
    letter-spacing: 1.8px;
    padding: 9px 12px;
  }
}

.form-basic__bar-select {
  border-radius: 0;
  border: 1px solid $c-main-dark;
  color: $c-font-gray;
}

.form-basic__wrapper {
  width: 100%;
}
.form-basic__btn {
  margin-left: 15%;
}