.btn-border {
  display: block;
  text-align: center;
  padding: 10px 32px;
  font-size: 13px;
  letter-spacing: 3.2px;
  border: 1px solid $c-main-dark;
  transition: all .3s;

  @include media('above-desktop') {
    &:hover {
      transform: translateX(0);
      cursor: pointer;
      color: $c-main-light;
      border-color: $c-main-dark;
      background-color: $c-main-dark;
      transition: all .3s;
    }
  }

  &.-bright {
    color: $gray-1;
    border-color: $gray-1;

    @include media('above-desktop') {
      &:hover {
        color: $c-main-light;
        border-color: $c-main-dark;
        background-color: $c-main-dark;
        transition: all .3s;
      }
    }
  }
}