$col-width-s-index: calc( (100% - #{$gutter} * 11) / 12 );
$extra-padding-index: calc( ( 100% - #{$inner-wrap} ) / 2 );
$extra-padding-index-m: calc( ( 100% - #{$wrapper-rwd-m} ) / 2 );
$extra-padding-index-s: calc( 100% - 100% / 2 );

.index {
  position: relative;
}

.index-hero {
  position: relative;
  z-index: 1;
  padding-bottom: 32px;

  &:before {
    content: '';
    position: absolute;
    z-index: -2;
    left: 0;
    top: calc(#{$header} * -1);
    height: calc(100% + #{$header});
    width: calc(( ( 100% - #{$inner-wrap} ) / 2 ) + ( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 8 + #{$gutter} * 7);
    background-color: $c-beige;

    @include media('below-small') {
      width: 65%;
    }
  }
  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    right: 0;
    bottom: 0;
    height: calc(50%);
    width: calc(( ( 100% - #{$inner-wrap} ) / 2 ) + ( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 9 + #{$gutter} * 9);
    background-color: $c-forest;

    @include media('below-small') {
      height: 80%;
      width: 80%;
    }
  }
}

.index-hero__main {
  position: relative;
  margin-left: auto;
  max-width: calc(100vw - ( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) - #{$gutter});

  @include media('below-desktop') {
    margin: 0 auto;
    max-width: calc(100vw - ( (100% - #{$gutter} * 11) / 12) - #{$gutter});
  }
  @include media('below-small') {
    max-width: 100vw;
  }

  & .index-hero__carousel {
    margin-bottom: 32px;

    @include media('below-small') {
      margin-bottom: 16px;
    }
  }
  & .index-hero__img {
    @extend %background-cover;
    min-height: 685px;
    max-height: 685px;
    width: 100%;

    @include media('below-small') {
      min-height: 400px;
      max-height: 400px;
    }
    @include media('below-tablet') {
      min-height: 175px;
      max-height: 175px;
    }
  }

  & .index-hero__captions {
    text-align: right;
    color: $c-main-light;
  }
  & .index-hero__caption {
    font-size: 15px;
    letter-spacing: 2.4px;
    line-height: 24px;
    text-align: right;

    @include media('below-small') {
      font-size: 10px;
      letter-spacing: 1.6px;
      line-height: 14px;
    }
  }
}

.index-hero__slides {
  min-height: 685px;

  @include media('below-small') {
    min-height: 400px;
  }
  @include media('below-tablet') {
    min-height: 175px;
  }
}

.index-hero__counter {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: calc(50% - 100px);
  right: -72px;

  @media (max-width: 1100px) {
    display: none;
  }

  & .index-hero__counter-num {
    display: block;
    font-size: 14px;
    letter-spacing: 2.6px;
    margin-bottom: 20px;

    &:last-child {
      color: $c-main-light;
      margin-bottom: 0;
    }
  }
  & .index-hero__counter-bar {
    position: relative;
    width: 1px;
    height: 200px;
    background-color: $gray-3;
    margin-bottom: 20px;
  }
  & .index-hero__counter-steppers {
    height: 100%;
  }
  & .index-hero__counter-stepper {
    display: block;
    width: 1px;
    padding: 0 4px;
  }
  & .index-hero__counter-indicator {
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    background-color: $c-forest;
    transition: all .5s;
  }
}

.index-block {
  position: relative;
  z-index: 1;

  & .index-block__wrapper {
    @extend %flex-between;
    width: 100%;

    @include media('below-small') {
      flex-direction: column;
    }
  }
  & .index-block__text {
    padding-top: 56px;
  }
  & .index-block__text-side {
    color: $c-main-light;
    max-width: calc( ( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 6 + #{$gutter} * 5 );

    @include media('below-desktop') {
      max-width: calc( ( (100% - #{$gutter} * 11) / 12) * 9 + #{$gutter} * 8 );
    }
  }
  & .index-block__text-indent {
    margin-left: calc(( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) + #{$gutter});

    @include media('below-desktop') {
      margin-left: calc(( (100% - #{$gutter} * 11) / 12) + #{$gutter});
    }
    @include media('below-small') {
      margin-left: 0;
    }
  }
  & .index-block__img {
    @extend %background-cover;
  }
  & .index-block__title {
    font-size: 36px;
    letter-spacing: 4.2px;
    line-height: 46px;
    font-family: $font-zh-serif;
    font-weight: $font-zh-serif-medium;
    margin-bottom: 56px;

    @include media('below-small') {
      font-size: 22px;
      letter-spacing: 2.86px;
      margin-bottom: 30px;
    }
  }
  & .index-block__subtitle {
    font-size: 23px;
    letter-spacing: 3.5px;
    line-height: 38px;
    font-family: $font-zh-serif;
    font-weight: $font-zh-serif-medium;
    margin-bottom: 24px;

    @include media('below-small') {
      font-size: 14px;
      letter-spacing: 1.4px;
      line-height: 24px;
    }
  }
  & .index-block__paragraph {
    font-size: 15px;
    letter-spacing: 2px;
    line-height: 30px;
    font-family: $base-font-light;

    @include media('below-small') {
      font-size: 11px;
      letter-spacing: 1px;
      line-height: 23px;
    }
  }
}
.index-block.-first {
  background-color: $c-beige;
  padding-top: 80px;

  @include media('below-small') {
    padding-top: 16px;
  }

  & .index-block__img-side {
    @include media('below-desktop') {
      width: calc(( (100% - #{$gutter} * 11) / 12) * 5 + #{$gutter} * 4);
      margin-right: 40px;
    }
    @include media('below-small') {
      width: 100%;
      margin-right: 0;
    }
  }

  & .index-block__img {
    min-height: 460px;
    width: calc(( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 5 + #{$gutter} * 4);

    @include media('below-desktop') {
      width: 100%;
    }
    @include media('below-small') {
      min-height: 280px;
      width: 80%;
    }
    @include media('below-tablet') {
      min-height: 193px;
    }
  }

  &:before {
    content: '';
    position: absolute;
    z-index: -2;
    right: 0;
    bottom: 0;
    height: 50%;
    width: 100%;
    background-color: $c-military;

    @include media('below-small') {
      height: 40%;
    }
  }
  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    right: 0;
    bottom: 0;
    height: 100%;
    width: calc(( ( 100% - #{$inner-wrap} ) / 2 ) + ( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 9 + #{$gutter} * 9);
    background-color: $c-forest;

    @include media('below-small') {
      width: 80%;
    }
  }

  & .index-block__text-side {
    @include media('below-small') {
      max-width: 100%;
      margin-left: 25%;
    }
  }
}
.index-block.-second {
  background-color: $c-beige;
  padding-top: 40px;

  & .index-block__img-side {
    @include media('below-desktop') {
      width: calc(( (100% - #{$gutter} * 11) / 12) * 4 + #{$gutter} * 3);
      margin-right: 40px;
    }
    @include media('below-small') {
      width: 100%;
    }
  }

  & .index-block__img {
    min-height: 580px;
    width: calc(( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 4 + #{$gutter} * 4);

    @include media('below-desktop') {
      width: 100%;
    }
    @include media('below-small') {
      min-height: 300px;
      width: 60vw;
      margin: 0 auto;
    }
    @include media('below-tablet') {
      background-position-x: left;
    }
  }
  & .index-block__text {
    padding-top: 246px;

    @include media('below-small') {
      padding-top: 64px;
      max-width: 100%;
    }
  }

  &:before {
    content: '';
    position: absolute;
    z-index: -2;
    left: 0;
    bottom: 0;
    height: 100%;
    width: calc(( ( 100% - #{$inner-wrap} ) / 2 ) + ( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 8 + #{$gutter} * 7);
    // width: calc(( ( 100% - #{$inner-wrap} ) / 2 ) + ( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 4 + #{$gutter} * 3);
    background-color: $c-military;

    @include media('below-small') {
      width: 80%;
    }
  }
  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    right: 0;
    top: 0;
    height: 130px;
    width: calc(( ( 100% - #{$inner-wrap} ) / 2 ) + ( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 9 + #{$gutter} * 9);
    background-color: $c-forest;

    @include media('below-small') {
      height: 25%;
      width: 80%;
    }
  }

  & .index-block__wrapper {
    @include media('below-small') {
      flex-direction: column-reverse;
      padding-top: 24px;
    }
  }
}
.index-block.-third {
  background-color: $c-beige;
  padding-top: 72px;

  & .index-block__text-wrapper {
    @include media('below-small') {
      color: $c-main-light;
    }
  }
  & .index-block__text-indent {
    margin-left: 0;
  }
  & .index-block__link-wrapper {
    max-width: 60%;
    margin-left: auto;

    @include media('below-small') {
      max-width: 100%;
      margin-right: auto;
      margin-left: initial;

      & .index-block__link {
        color: $c-main-light;
        & .btn-dash__dash {
          background-color: $c-main-light;
        }
      }
    }
  }
  & .index-block__text-side {
    width: 100%;
    max-width: calc(( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 5 + #{$gutter} * 5);

    @include media('below-desktop') {
      max-width: calc(( (100% - #{$gutter} * 11) / 12) * 5 + #{$gutter} * 5);
    }
    @include media('below-desktop') {
      max-width: 75%;
    }
  }
  & .index-block__text {
    @extend %flex;
    align-items: center;
    padding-top: 0;
    height: 100%;
    color: $c-main-dark;
  }
  & .index-block__text-wrapper {
    width: 100%;
  }
  & .index-block__img-side {
    width: calc(( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 7 + #{$gutter} * 7);

    @include media('below-desktop') {
      margin-right: 40px;
      width: calc(( (100% - #{$gutter} * 11) / 12) * 5 + #{$gutter} * 5 + ( ( #{$inner-wrap} - #{$gutter} * 11) / 12 )/2);
    }
    @include media('below-small') {
      min-height: 150px;
      width: 90%;
      margin: 0 0 64px auto;
    }
    @include media('below-tablet') {
      min-height: 183px;
    }
  }
  & .index-block__img {
    min-height: 360px;
    width: calc(( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 5 + #{$gutter} * 5 + ( ( #{$inner-wrap} - #{$gutter} * 11) / 12 )/2);

    @include media('below-desktop') {
      width: 100%;
    }
    @include media('below-small') {
      min-height: 300px;
      width: 100%;
    }
    @include media('below-tablet') {
      min-height: 150px;
    }
  }
  & .index-block__paragraph {
    margin-bottom: 40px;
  }

  &:before {
    content: '';
    position: absolute;
    z-index: -2;
    left: 0;
    bottom: 0;
    height: 100%;
    width: calc(( ( 100% - #{$inner-wrap} ) / 2 ) + ( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 8 + #{$gutter} * 7);
    background-color: $c-military;

    @include media('below-small') {
      width: 80%;
    }
  }
}
.index-block.-fourth {
  background-color: $c-beige;
  padding-top: 56px;

  @include media('below-small') {
    padding-bottom: 116px;
  }

  & .index-block__img-side {
    margin-left: calc(( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 7 + #{$gutter} * 6);
    max-width: calc(( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 5 + #{$gutter} * 5);

    @include media('below-desktop') {
      margin-left: calc(( (100% - #{$gutter} * 11) / 12) * 7 + #{$gutter} * 6);
      max-width: calc(( (100% - #{$gutter} * 11) / 12) * 5 + #{$gutter} * 5);
      width: calc(( (100% - #{$gutter} * 11) / 12) * 4 + #{$gutter} * 4);
    }
    @include media('below-small') {
      margin-left: 10%;
      max-width: 90%;
      width: 100%;
    }
  }
  & .index-block__img {
    min-height: 300px;
    width: calc(( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 4 + #{$gutter} * 4);

    @include media('below-desktop') {
      width: 100%;
    }
    @include media('below-small') {
      min-height: 240px;
      width: 100%;
    }
    @include media('below-tablet') {
      min-height: 207px;
    }
  }

  &:before {
    content: '';
    position: absolute;
    z-index: -2;
    left: 0;
    bottom: 0;
    height: 100%;
    width: calc(( ( 100% - #{$inner-wrap} ) / 2 ) + ( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 8 + #{$gutter} * 7);
    background-color: $c-military;

    @include media('below-small') {
      width: 80%;
      height: 45%;
      bottom: auto;
      top: 0;
    }
  }
}
.index-block.-last {
  padding-bottom: 132px;

  @include media('below-small') {
    padding-bottom: 72px;
  }

  & .index-block__img-side {
    width: calc(( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 5 + #{$gutter} * 4);
    @include media('below-desktop') {
      width: calc(( (100% - #{$gutter} * 11) / 12) * 5 + #{$gutter} * 4);
    }
    @include media('below-small') {
      width: 90%;
      transform: translateX(32px);
    }
  }
  & .index-block__img {
    height: 730px;
    width: 100%;

    @include media('below-small') {
      height: 390px;
      margin-left: auto;
    }
  }
  & .index-block__text-side {
    padding-top: 168px;

    @include media('below-small') {
      padding-top: 150px;
      margin-bottom: 64px;
      max-width: 75%;
    }
  }
  & .index-block__paragraph {
    font-family: $base-font-light;
    font-size: 20px;
    letter-spacing: 3px;
    line-height: 40px;
    margin-bottom: 88px;

    @include media('below-small') {
      font-size: 13px;
      letter-spacing: 1.5px;
      line-height: 26px;
    }
  }
  & .index-block__link {
    @include media('below-small') {
      &.btn-dash {
        & .btn-dash__dash {
          background-color: $c-main-light;
        }
      }
    }
  }
  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    bottom: 0;
    height: calc(100% - 110px);
    width: calc(( ( 100% - #{$inner-wrap} ) / 2 ) + ( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 12 + #{$gutter} * 11);
    background-color: $c-forest;

    @include media('below-small') {
      bottom: auto;
      top: 100px;
      height: calc(85% - 100px);
      width: 80%;
    }
  }
  &:after {
    content: '';
    position: absolute;
    z-index: -2;
    right: 0;
    top: 0;
    height: 110px;
    width: calc(( ( 100% - #{$inner-wrap} ) / 2 ) + ( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 10 + #{$gutter} * 10);
    background-color: $c-beige;

    @include media('below-small') {
      height: 85%;
      width: 100%;
    }
  }
}

.index-cards {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    height: calc(100% - 360px * 0.7);
    width: calc(( ( 100% - #{$inner-wrap} ) / 2 ) + ( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 8 + #{$gutter} * 7);
    background-color: $c-military;

    @include media('below-small') {
      display: none;
    }
  }
  &:after {
    content: '';
    position: absolute;
    z-index: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: calc(( ( 100% - #{$inner-wrap} ) / 2 ) + ( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 10 + #{$gutter} * 10);
    background-color: $c-beige;
  }

  & .container {
    z-index: 2;
  }

  & .index-cards__wrapper {
    margin-left: auto;
    max-width: calc(( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 10 + #{$gutter} * 10);
    margin-bottom: 48px;

    @include media('below-desktop') {
      max-width: 100%;
    }
  }
}

.index-cards__btm {
  max-width: calc(( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 5 + #{$gutter} * 5);
  padding: 0 calc(( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) + #{$gutter}) 124px calc(( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) + #{$gutter});

  @include media('below-desktop') {
    max-width: calc(( (100% - #{$gutter} * 11) / 12) * 5 + #{$gutter} * 5);
    padding: 0 calc(( (100% - #{$gutter} * 11) / 12) + #{$gutter}) 124px calc(( (100% - #{$gutter} * 11) / 12) + #{$gutter});
  }
  @include media('below-small') {
    max-width: 100%;
    padding: 0;
  }
}

.index__go-top {
  position: absolute;
  z-index: 2;
  bottom: 2px;
  right: calc((100vw - #{$outer-wrap})/2);
  transition: all .3s;

  & .icon {
    font-size: 40px;
  }

  &:hover {
    @include media('above-desktop') {
      transform: translateY(-4px);
      transition: all .3s;

      & .icon {
        color: $c-font-hover;
        transition: all .3s;
      }
    }
  }
}