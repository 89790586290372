.blog {
  &:before {
    content: '';
    position: absolute;
    z-index: 0;
    left: -32px;
    top: 0;
    height: calc(#{$header-m} + 180px);
    width: calc(70% + 18px);
    background-color: $c-main-light;
    display: none;

    @include media('below-small') {
      display: block;
    }
  }
}

.blog-header,
.blog-posts__card {
  position: relative;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    z-index: -2;
    left: calc(( ( 100vw - #{$inner-wrap} ) / 2) * -1);
    top: calc(#{$header} * -1);
    height: calc(100% + #{$header});
    width: calc(( ( 100vw - #{$inner-wrap} ) / 2) + ( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 7 + #{$gutter} * 7);
    background-color: $c-main-light;

    @include media('below-medium') {
      left: calc(( ( 100vw - #{$wrapper-rwd-m} ) / 2) * -1);
      width: calc(( ( 100vw - #{$wrapper-rwd-m} ) / 2) + ( ( #{$wrapper-rwd-m} - #{$gutter} * 11 ) / 12) * 7 + #{$gutter} * 7);
    }
    @include media('below-small') {
      left: -32px;
      top: calc(#{$header-m} * -1);
      height: calc(100% + #{$header-m});
      width: calc(70% + 32px);
    }
  }
}

.blog-select {
  position: relative;
  z-index: 1;
  margin-bottom: 40px;

  &:before {
    content: '';
    position: absolute;
    z-index: -2;
    left: -32px;
    top: 0;
    height: calc(100% + 40px);
    width: calc(70% + 32px);
    background-color: $c-main-light;
  }
}

.blog-header {
  padding-bottom: 40px;
  & .title-set {
    margin-bottom: 0;
  }
}

.blog-menu {
  position: relative;
  z-index: 2;
}

.blog-posts {
  & .blog-posts__card {
    &:before {
      height: 100%;
      top: 0;
      left: calc(( ( 100vw - #{$inner-wrap} ) / 2) * -1 - ( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 3 - #{$gutter} * 3);

      @include media('below-medium') {
        left: calc(( ( 100vw - #{$wrapper-rwd-m} ) / 2) * -1 - ( ( #{$wrapper-rwd-m} - #{$gutter} * 11 ) / 12) * 3 - #{$gutter} * 3);
      }
      @include media('below-small') {
        left: -32px;
      }
    }
    &:after {
      content: '';
      position: absolute;
      z-index: -2;
      left: calc(( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 4 + #{$gutter} * 4);
      top: 0;
      height: 100%;
      width: calc(( ( 100vw - #{$inner-wrap} ) / 2) + ( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 5 + #{$gutter} * 4);
      background-color: $c-brick;

      @include media('below-medium') {
        left: calc(( ( #{$wrapper-rwd-m} - #{$gutter} * 11 ) / 12) * 4 + #{$gutter} * 4);
        width: calc(( ( 100vw - #{$wrapper-rwd-m} ) / 2) + ( ( #{$wrapper-rwd-m} - #{$gutter} * 11 ) / 12) * 5 + #{$gutter} * 4);
      }
      @include media('below-small') {
        left: auto;
        right: -32px;
        height: 100%;
        width: calc(30% + 32px);
      }
    }
    &:first-child {
      &:after {
        height: calc(50% + 32px);
        top: auto;
        bottom: 0;

        @include media('below-small') {
          height: 65%;
        }
      }
    }
    &:last-child {
      &:before {
        height: 50%;
      }
    }
    &.card-img {
      margin-bottom: 0;
      padding-bottom: 64px;

      @include media('below-small') {
        padding-bottom: 40px;
        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }
}
