.floater {
  @extend %flex;
  align-items: center;
  overflow: hidden;
}

.floater__img {
  transition: transform 5s cubic-bezier(0,0,.04,1.45);
  // transition-delay: .1s;
  will-change: transform;
}