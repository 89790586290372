.about {
  position: relative;
  background-color: $c-matcha;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: calc(#{$header} * -1);
    height: $header;
    width: 100vw;
    background-color: $c-matcha;
  }
  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: calc(#{$footer} * -1);
    height: $footer;
    width: 100vw;
    background-color: $c-matcha;

    @include media('below-small') {
      bottom: calc(#{$footer-m} * -1);
      height: $footer-m;
    }
  }
}

.about-top {
  position: relative;
  z-index: 1;

  @include media('below-small') {
    margin-bottom: 48px;
  }

  &:before {
    content: '';
    position: absolute;
    z-index: 0;
    right: calc(( ( 100vw - #{$inner-wrap} ) / 2) * -1);
    top: 0;
    height: 100%;
    width: calc(100vw - ( ( 100vw - #{$inner-wrap} ) / 2) - ( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 1.5 - #{$gutter});
    background-color: $c-beige;

    @include media('below-medium') {
      display: none;
    }
  }

  & .title-set {
    position: relative;
    z-index: 1;
    margin-bottom: 0;
    padding-top: 64px;
    padding-bottom: 4px;
  }
}

.about-main {
  @extend %flex-between;
  position: relative;
  padding: 0 calc(( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) + #{$gutter});
  width: 100%;

  @include media('below-medium') {
    flex-direction: column;
    padding: 0;
  }

  &:before {
    content: '';
    position: absolute;
    z-index: 1;
    right: calc(( ( 100vw - #{$inner-wrap} ) / 2) * -1);
    top: 0;
    height: 100%;
    width: calc(100vw - ( ( 100vw - #{$inner-wrap} ) / 2) - ( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 1.5 - #{$gutter});
    background-color: $c-beige;

    @include media('below-medium') {
      top: 25%;
      right: calc(( ( 100vw - #{$wrapper-rwd-m} ) / 2) * -1);
      height: 75%;
      width: calc(100% + ( ( 100vw - #{$wrapper-rwd-m} ) / 2));
    }
    @include media('below-small') {
      top: 10%;
      right: -32px;
      height: 90%;
      width: calc(100% + 32px);
    }
  }
  &:after {
    content: '';
    position: absolute;
    z-index: 0;
    left: calc(( ( 100vw - #{$inner-wrap} ) / 2) * -1);
    bottom: 0;
    height: 72px;
    width: calc(( ( 100vw - #{$inner-wrap} ) / 2) + #{$inner-wrap});
    background-color: $c-main-light;

    @include media('below-small') {
      left: -32px;
      width: calc(100% + 32px);
      height: 35%;
    }
  }

  & .about-main__right {
    z-index: 2;
    padding-left: calc(( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) + #{$gutter} * 2);

    @include media('below-medium') {
      padding-left: 10%;
    }
  }
  & .about-main__left {
    @include media('below-medium') {
      margin-bottom: 145px;
    }
    @include media('below-small') {
      margin-bottom: 48px;
    }
  }
  & .about-main__title {
    font-family: $font-zh-serif;
    font-weight: $font-zh-serif-medium;
    font-size: 21px;
    letter-spacing: 2.8px;
    line-height: 35px;
    margin-bottom: 48px;

    @include media('below-small') {
      font-size: 14px;
      letter-spacing: 1.4px;
      line-height: 24px;
    }
  }
  & .about-main__paragraph {
    @extend %last-no-margin;
    font-size: 15px;
    letter-spacing: 1px;
    line-height: 30px;
    margin-bottom: 32px;

    @include media('below-small') {
      font-size: 13px;
      letter-spacing: 1.2px;
      line-height: 27px;
    }
  }
  & .about-main__img-floater {
    position: relative;
    z-index: 2;
    transform: translateY(104px);
    width: calc(( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 4 + #{$gutter} * 3);

    @include media('below-medium') {
      width: 60%;
    }
    @include media('below-small') {
      width: 80%;
      transform: none;
    }
  }
  & .about-main__img {
    @extend %background-cover;
    height: 580px;
    min-height: 580px;
    width: 100%;

    @include media('below-medium') {
      height: 300px;
      min-height: 300px;
    }
  }
}

.about-stripe {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    z-index: 1;
    right: calc(( ( 100vw - #{$inner-wrap} ) / 2) * -1);
    top: 0;
    height: 50%;
    width: calc(100vw - ( ( 100vw - #{$inner-wrap} ) / 2) - ( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 1.5 - #{$gutter});
    background-color: $c-beige;

    @include media('below-medium') {
      right: calc(( ( 100vw - #{$wrapper-rwd-m} ) / 2) * -1);
      width: calc(100% + ( ( 100vw - #{$wrapper-rwd-m} ) / 2));
    }
    @include media('below-small') {
      right: -32px;
      width: calc(100% + 32px);
    }
  }
  &:after {
    content: '';
    position: absolute;
    z-index: 0;
    left: calc(( ( 100vw - #{$inner-wrap} ) / 2) * -1);
    bottom: 0;
    height: 100%;
    width: calc(( ( 100vw - #{$inner-wrap} ) / 2) + #{$inner-wrap});
    background-color: $c-main-light;

    @include media('below-medium') {
      left: calc(( ( 100vw - #{$wrapper-rwd-m} ) / 2) * -1);
      width: calc(( ( 100vw - #{$wrapper-rwd-m} ) / 2) + #{$wrapper-rwd-m});
    }
    @include media('below-small') {
      left: -32px;
      width: 100%;
    }
    @include media('below-tablet') {
      left: -32px;
      width: calc(100% + 32px);
    }
  }

  & .about-stripe__img-floater {
    position: relative;
    z-index: 2;
    margin-left: auto;
    transform: translateX(calc(( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 0.3));
    width: calc(( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 4.3 + #{$gutter} * 3);

    @include media('below-small') {
      width: 90%;
      transform: none;
    }
  }
  & .about-stripe__img {
    @extend %background-cover;
    height: 270px;
    min-height: 270px;
    width: 100%;

    @include media('below-small') {
      height: 380px;
      min-height: 380px;
    }
    @include media('below-tablet') {
      height: 140px;
      min-height: 140px;
    }
  }
}

.about-bars {
  position: relative;

  @include media('below-small') {
    padding-top: 88px;
  }

  &:before {
    content: '';
    position: absolute;
    z-index: 0;
    left: calc(( ( 100vw - #{$inner-wrap} ) / 2) * -1);
    top: 0;
    height: 100%;
    width: calc(( ( 100vw - #{$inner-wrap} ) / 2) + #{$inner-wrap});
    background-color: $c-main-light;

    @include media('below-medium') {
      left: calc(( ( 100vw - #{$wrapper-rwd-m} ) / 2) * -1);
      width: calc( #{$wrapper-rwd-m});
    }
    @include media('below-small') {
      left: -32px;
      width: 100%;
    }
    @include media('below-tablet') {
      left: -32px;
      width: calc(100% + 32px);
    }
  }
  & .about-bars__top {
    position: relative;
    z-index: 1;
  }
  & .about-bars__main {
    position: relative;
    z-index: 1;
    padding: 0 calc(( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) + #{$gutter});
    padding-bottom: 104px;

    @include media('below-small') {
      padding: 0 10% 104px 0;
    }
  }
  & .about-bars__main-hidden {
    pointer-events: none;
    opacity: 0;
    height: 0;
    transition: all .3s;
    will-change: height;

    &.-is-active {
      margin-bottom: 16px;
      pointer-events: visible;
      opacity: 1;
      height: auto;
      transition: all .5s;
      will-change: height;
    }
  }
  & .about-bars__more {
    display: block;
    margin: 0 auto;
    transform: rotate(90deg);
    transition: all .3s;

    &.-is-hidden {
      transform: rotate(-90deg);
    }

    & .icon {
      font-size: 20px;
    }

    @include media('above-desktop') {
      &:hover {
        transform: rotate(90deg) translateX(6px);
        transition: all .3s;
      }
      &.-is-hidden:hover {
        transform: rotate(-90deg) translateX(6px);
      }
    }
  }
}

.about-last {
  position: relative;

  @include media('below-small') {
    padding-bottom: 72px;
  }

  &:before {
    content: '';
    position: absolute;
    z-index: 0;
    left: calc(( ( 100vw - #{$inner-wrap} ) / 2) * -1);
    top: 0;
    height: 100%;
    width: calc(( ( 100vw - #{$inner-wrap} ) / 2) + #{$inner-wrap});
    background-color: $c-main-light;

    @include media('below-medium') {
      left: calc(( ( 100vw - #{$wrapper-rwd-m} ) / 2) * -1);
      width: calc( #{$wrapper-rwd-m});
    }
    @include media('below-small') {
      left: -32px;
      width: 100%;
    }
    @include media('below-tablet') {
      left: -32px;
      width: calc(100% + 32px);
    }
  }
  & .about-last__top  {
    position: relative;
    z-index: 1;
  }
  & .about-last__main {
    @extend %flex;
    position: relative;
    z-index: 1;
    padding: 0 calc(( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) + #{$gutter});
    padding-bottom: 104px;

    @include media('below-small') {
      padding: 0;
    }
  }
  & .about-last__left {
    min-width: calc(( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 5 + #{$gutter} * 5);

    @include media('below-medium') {
      min-width: calc(( ( #{$wrapper-rwd-m} - #{$gutter} * 11 ) / 12) * 5 + #{$gutter} * 5);
    }
    @include media('below-small') {
      min-width: 0;
      max-width: 90%;
    }
  }
  & .about-last__right {
    @include media('below-medium') {
      padding-left: 32px;
    }
    @include media('below-small') {
      display: none;
    }
  }
  & .about-last__titles {
    @extend %last-no-margin-btm;
    color: $c-font-gray-light;
    margin-bottom: 32px;
    cursor: pointer;
    transition: all .3s;

    &.-is-active {
      color: $c-main-dark;
      transition: all .3s;
    }

    @include media('above-desktop') {
      &:hover {
        color: $c-main-dark;
        transition: all .3s;
      }
    }
    @include media('below-small') {
      color: $c-main-dark;
      pointer-events: none;
    }
  }
  & .about-last__titles-title {
    font-size: 19px;
    letter-spacing: 2px;
    margin-bottom: 22px;

    @include media('below-small') {
      font-size: 16px;
      letter-spacing: 1.6px;
    }
  }
  & .about-last__titles-desc {
    font-family: $base-font-light;
    font-size: 15px;
    letter-spacing: 1px;
    line-height: 26px;

    @include media('below-small') {
      font-size: 13px;
      letter-spacing: 1.2px;
      line-height: 24px;
      margin-bottom: 32px;
    }
  }
  & .about-last__titles-img-floater {
    display: none;
    transform: translateX(32px);
    height: 240px;
    width: calc(100% + 32px);

    @include media('below-small') {
      display: flex;
      align-items: center;
    }
    // @include media('below-tablet') {
    //   height: 195px;
    // }

    & .about-last__titles-img {
      @extend %background-cover;
      height: 240px;
      min-height: 240px;
      width: 100%;
      transition: background .3s;

      @include media('below-small') {
        height: 400px;
        min-height: 400px;
      }
      @include media('below-tablet') {
        height: 195px;
        min-height: 195px;
      }
    }
  }
  & .about-last__img-floater {
    transform: translateX(100px);
    height: 410px;
    width: calc(120px + ( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 5 + #{$gutter} * 4);

    @include media('below-medium') {
      transform: translateX(0);
    }
  }
  & .about-last__img {
    @extend %background-cover;
    height: 410px;
    min-height: 410px;
    width: 100%;
    transition: background .3s;
  }
}