.floats {
  position: absolute;
  top: calc(140px + 685px - 22px * 3 - 48px);
  left: 5%;
  z-index: $z-floats;

  @media (max-width: 1460px) {
    left: 2%;
   }
  @include media('below-medium') {
    // left: calc((100vw - #{$wrapper-rwd-m} - 20%)/2 + 8px);
    left: 3.5%;
  }
  @media (max-width: 1100px) {
    display: none;
  }
}

.floats-socials__item {
  @extend %last-no-margin;
  margin-bottom: 32px;

  & .floats-socials__link {
    height: 22px;
    width: 22px;
  }
  & .icon {
    display: block;
    font-size: 20px;
    transform: translateX(1px);
  }
}