@font-face {
  font-family: "icon";
  src: url( "fonts/icon.eot" );
  src: url( "fonts/icon.eot?#iefix" ) format( "eot" ),
       url( "fonts/icon.ttf" ) format( "truetype" ),
       url( "fonts/icon.woff" ) format( "woff" ),
       url( "fonts/icon.svg#icon" ) format( "svg" );
  font-weight: normal;
  font-style: normal;
}

.icon-base,
[class^="icon-"], [class*=" icon-"] {
  font-family: "icon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  speak: none;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-long:before { content: "\EA01" }
.icon-arrow-short:before { content: "\EA02" }
.icon-book:before { content: "\EA03" }
.icon-calendar:before { content: "\EA04" }
.icon-chevron:before { content: "\EA05" }
.icon-class:before { content: "\EA06" }
.icon-cross:before { content: "\EA07" }
.icon-cup:before { content: "\EA08" }
.icon-gift:before { content: "\EA09" }
.icon-house:before { content: "\EA0A" }
.icon-logo-text-en:before { content: "\EA0B" }
.icon-logo-text-zh:before { content: "\EA0C" }
.icon-logo:before { content: "\EA0D" }
.icon-social-fb:before { content: "\EA0E" }
.icon-social-ig:before { content: "\EA0F" }
.icon-social-youtube:before { content: "\EA10" }
.icon-utensil:before { content: "\EA11" }
