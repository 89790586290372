.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $z-lightbox;
  width: 100vw;
  height: 100vh;
  background-color: black;
  padding: 48px 0;

  pointer-events: none;
  opacity: 0;

  &.-is-active {
    transition: all .3s;
    pointer-events: visible;
    opacity: 1;
    background-color: rgba(black, .8);
  }

  &.-video {
    @extend %flex-center-all;
  }
}

.lightbox__video-wrapper {
  min-width: 80%;
  height: 90%;

  @include media('below-desktop') {
    height: 50%;
  }
  @include media('below-tablet') {
    height: 40%;
  }
}

.lightbox__iframe {
  margin: 0 auto;
  width: 100%;
  height: 100%;
}
