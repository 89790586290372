.news-post {
  position: relative;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    z-index: -2;
    left: 0;
    top: calc(#{$header} * -1);
    height: calc(100% - #{$header});
    width: 50vw;
    background-color: $c-main-light;

    @include media('below-small') {
      top: calc(#{$header-m} * -1);
      width: 70%;
      height: calc(75% + #{$header-m});
    }
  }
}

.news-post-carousel {
  position: relative;
  z-index: 20;

  // &:before {
  //   content: '';
  //   position: absolute;
  //   z-index: -2;
  //   right: calc(( ( 100vw - #{$inner-wrap} ) / 2) * -1);
  //   bottom: 0;
  //   height: 50%;
  //   width: calc(( ( 100vw - #{$inner-wrap} ) / 2) + ( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 8 + #{$gutter} * 7);
  //   background-color: $c-main-light;

  //   @include media('below-medium') {
  //     right: calc(( ( 100vw - #{$wrapper-rwd-m} ) / 2) * -1);
  //     width: calc(( ( 100vw - #{$wrapper-rwd-m} ) / 2) + ( ( #{$wrapper-rwd-m} - #{$gutter} * 11 ) / 12) * 8 + #{$gutter} * 7);
  //   }
  //   @include media('below-small') {
  //     right: -32px;
  //     width: calc(100% + 32px);
  //   }
  // }

  & .news-post-carousel__dots.carousel__dots {
    margin-bottom: 0;
    padding-bottom: 40px;
  }
}

.news-post-main {
  @extend %flex;
  position: relative;
  z-index: 1;
  padding-bottom: 100px;

  &:before {
    content: '';
    position: absolute;
    z-index: -2;
    right: calc(( ( 100vw - #{$inner-wrap} ) / 2) * -1);
    bottom: 0;
    height: calc(100% + 580px / 2 + 70px);
    width: calc(( ( 100vw - #{$inner-wrap} ) / 2) + ( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 8 + #{$gutter} * 7);
    background-color: $c-beige;

    @include media('below-medium') {
      height: calc(100% + 400px / 2 + 70px);
      right: calc(( ( 100vw - #{$wrapper-rwd-m} ) / 2) * -1);
      width: calc(( ( 100vw - #{$wrapper-rwd-m} ) / 2) + ( ( #{$wrapper-rwd-m} - #{$gutter} * 11 ) / 12) * 8 + #{$gutter} * 7);
    }
    @include media('below-small') {
      height: calc(100% + 150px / 2 + 66px);
      right: -32px;
      width: calc(100% + 32px);
    }
  }

  & .title-set {
    padding-top: 0;
  }
  & .news-post-main__left {
    width: calc(( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 4 + #{$gutter} * 4);
    min-width: calc(( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 4 + #{$gutter} * 4);

    @include media('below-medium') {
      width: calc(( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 3 + #{$gutter} * 3);
      min-width: calc(( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 3 + #{$gutter} * 3);
    }
    @include media('below-small') {
      display: none;
    }

    & .news-post-main__left-top {
      margin-bottom: 600px;
    }
    & .news-post-main__left-btm {
      padding-right: calc(( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) + #{$gutter});
      text-align: right;
    }
  }
  & .news-post-main__right {
    flex-grow: 2;
  }
  & .news-post-main__content {
    padding-left: calc(( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) + #{$gutter});

    @include media('below-small') {
      padding-left: 10%;
    }
  }
  & .news-post-main__content-top {
    margin-bottom: 36px;

    @include media('below-small') {
      & .info-btn-bar__right {
        display: none;
      }
    }
  }
  & .news-post-main__content-mid {
    position: relative;
    padding-bottom: 68px;
    margin-bottom: 32px;
    border-bottom: 1px solid $c-main-dark;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -8px;
      height: 1px;
      width: 100%;
      background-color: $c-main-dark;
    }
  }
  & .news-post-main__content-btm {
    @extend %flex-between-center;
    width: 100%;
    padding: 0 2px;
  }
  & .news-post-main__content-title {
    font-size: 26px;
    letter-spacing: 2.4px;
    margin-bottom: 56px;

    @include media('below-small') {
      font-size: 16px;
      letter-spacing: 1.8px;
      line-height: 27px;
      margin-bottom: 32px;
    }
  }
  & .news-post-main__content-subtitle {
    font-size: 15px;
    letter-spacing: 2.4px;
    margin-bottom: 14px;

    @include media('below-small') {
      font-size: 11px;
      letter-spacing: 1.8px;
    }
  }
  & .news-post-main__content-paragraph {
    @extend %last-no-margin-btm;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 32px;
    margin-bottom: 40px;

    @include media('below-small') {
      font-size: 15px;
      letter-spacing: 1.6px;
      line-height: 30px;
      margin-bottom: 32px;
    }
  }
  & .news-post-main__content-btn {
    width: 150px;
    display: none;

    @include media('below-small') {
      display: block;
    }
  }
}