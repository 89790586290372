.info-btn-bar {
  @extend %flex-between;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 72px;

  @include media('below-small') {
    margin-bottom: 40px;
  }

  &.-border {
    padding-bottom: 36px;
    border-bottom: 1px solid $c-main-dark;

    @include media('below-small') {
      padding-bottom: 24px;
    }
  }
  &.-dark {
    color: $c-main-dark;
  }
}
.info-btn-bar__left.-flex {
  @extend %flex;
}
.info-btn-bar__title {
  font-size: 22px;
  letter-spacing: 2.4px;
  line-height: 32px;

  @include media('below-small') {
    font-size: 18px;
    letter-spacing: 2px;
    line-height: 25px;
  }
}
.info-btn-bar__subtitle {
  font-size: 14px;
  letter-spacing: 2.4px;
  margin-bottom: 16px;

  @include media('below-small') {
    font-size: 12px;
    letter-spacing: 2px;
    margin-bottom: 14px;
  }
}
.info-btn-bar__date {
  font-size: 15px;
  letter-spacing: 1.38px;
  margin-right: 24px;

  @include media('below-small') {
    font-size: 11px;
    letter-spacing: 1.18px;
  }
}
.info-btn-bar__type {
  font-size: 15px;
  letter-spacing: 2.4px;

  @include media('below-small') {
    font-size: 11px;
    letter-spacing: 1.83px;
  }
}