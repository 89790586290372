.footer {
  position: relative;
  z-index: 2;
  padding: 100px 0;
  height: $footer;

  @include media('below-small') {
    height: $footer-m;
  }

  & .container {
    @include media('below-small') {
      width: 90%;
      margin: 0 auto;
    }
  }
}

.footer-top {
  margin-bottom: 64px;
}

.footer-logo {
  width: 100%;
  & img {
    width: 160px;
    max-width: 160px;
  }
}

.footer-middle {
  align-items: flex-end;
  border-bottom: 1px solid #979797;
  padding-bottom: 36px;
  margin-bottom: 36px;

  @include media('below-small') {
    border-bottom: none;
    padding-bottom: 0px;
    margin-bottom: 30px;
  }
}

.footer-middle__right {
  & .-rwd-show {
    display: none;
  }
  & .footer-socials__item {
    @extend %last-no-margin-btm;
    margin-bottom: 16px;

    & .footer-socials__link {
      display: block;
      height: 20px;
    }
  }
  @include media('below-small') {
    & .-rwd-hide {
      display: none;
    }
    & .-rwd-show {
      display: block;
    }
  }
}

.footer-links {
  @extend %flex-between;

  & .footer-links__item {
    margin-right: 40px;

    @include media('below-small') {
      margin-right: 0;
      margin-bottom: 26px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  & .footer-links__link {
    font-size: 14px;
    letter-spacing: 2.4px;
  }

  @include media('below-small') {
    flex-direction: column;
  }
}

.footer-contact {
  text-align: right;
  & .-bold {
    font-weight: $font-en-medium;
  }
  & .footer-contact__text {
    text-align: right;
    font-size: 14px;
    letter-spacing: 2.4px;
    line-height: 27px;
  }
}

.footer-btm__copyright {
  font-size: 11px;
  letter-spacing: 1.5px;
  line-height: 18px;
  color: $c-font-gray;
}

.footer-socials__item {
  @extend %last-no-margin;
  margin-right: 26px;
  & .icon {
    font-size: 20px;
  }

  @include media('below-small') {
    margin-right: 0;
  }
}

.footer-btm__right {
  @include media('below-small') {
    display: none;
  }
}

.footer-center {
  border-bottom: 1px solid #979797;
  margin-bottom: 30px;
  padding-bottom: 30px;

  & .footer-contact__text {
    text-align: left;
  }

  &.-rwd-show {
    display: none;

    @include media('below-small') {
      display: block;
    }
  }
}