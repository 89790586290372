$bezier-curve: ease-out;
$duration: 1s;

.carousel {
  position: relative;
}

.carousel__slides {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;

  &.-relative {
    display: flex;

    & .carousel__slide {
      position: relative;
      display: block;
      flex-shrink: 0;
      order: 1;
      opacity: 0;
      transform: translateX(-100%);
      transition: all $duration;

      &.-current {
        order: 1 !important;
        opacity: 1;
        transform: translateX(-100%);
      }
      &.-prev {
        order: 0 !important;
        transform: translateX(-100%);
      }
      &.-next {
        order: 2 !important;
        transform: translateX(-100%);
      }
      &.-single {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
}
.carousel__slide {
  position: absolute;
  width: 100%;
  display: none;
  z-index: 1;
  transition: transform $duration $bezier-curve;
  -webkit-backface-visibility: hidden;

  &.-current {
    display: block;
    z-index: 5;
    transform: translateX(0);

    @include media('below-small') {
      transform: translateX(-2px);
    }
  }
  &.-prev {
    display: block;
    transform: translateX(-100%);

    opacity: 0;
  }
  &.-next {
    display: block;
    transform: translateX(100%);

    opacity: 0;
  }

  &.-next-to-current {
    display: block;
    z-index: 1000;
    animation: nextToCurrent $duration;
    animation-timing-function: $bezier-curve;
    transform: translateX(0);

    opacity: 1;

    @include media('below-small') {
      animation: none;
      transform: none;
    }
  }
  &.-current-to-prev {
    display: block;
    animation: currentToPrev $duration;
    animation-timing-function: $bezier-curve;
    transform: translateX(-100%);
    z-index: 1;

    @include media('below-small') {
      animation: mobileHide $duration;
      transform: translateX(-20%);
    }
  }
  &.-prev-to-current {
    display: block;
    z-index: 1000;
    animation: prevToCurrent $duration;
    animation-timing-function: $bezier-curve;
    transform: translateX(0);

    opacity: 1;

    @include media('below-small') {
      animation: none;
      transform: none;
      transition: 1s ease-in;
    }
  }
  &.-current-to-next {
    display: block;
    animation: currentToNext $duration;
    animation-timing-function: $bezier-curve;
    transform: translateX(100%);
    z-index: 1;

    @include media('below-small') {
      animation: mobileHide $duration;
      transform: translateX(20%);
    }
  }

  &.-video {

    & iframe {
      width: 100%;
      min-height: 100%;
      pointer-events: none;
    }
  }
}

@keyframes nextToCurrent {
  0% {
    z-index: 5;
    transform: translateX(100%);
  }
  100% {
    z-index: 5;
    transform: translateX(0);
  }
}
@keyframes currentToPrev {
  0% {
    z-index: 1;
    transform: translateX(0);
  }
  100% {
    z-index: 1;
    transform: translateX(-20%);
  }
}
@keyframes prevToCurrent {
  0% {
    z-index: 5;
    transform: translateX(-100%);
  }
  100% {
    z-index: 5;
    transform: translateX(0);
  }
}
@keyframes currentToNext {
  0% {
    z-index: 1;
    transform: translateX(0);
  }
  100% {
    z-index: 1;
    transform: translateX(20%);
  }
}
@keyframes mobileHide {
  0% { opacity: 1; }
  25% { opacity: 1; }
  50% { opacity: 1; }
  75% { opacity: 1; }
  99% { opacity: 0; }
  100% { opacity: 0; }
}

.carousel__dots {
  @extend %flex;

  &.-single {
    & .carousel__dots-dot {
      display: none;
    }
  }

  & .carousel__dots-dot {
    @extend %last-no-margin;
    cursor: pointer;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    border: 1px solid $c-font-gray-light;
    margin-right: 10px;

    &.-current {
      background-color: $c-font-gray-light;
    }

    @include media('below-small') {
      width: 6px;
      height: 6px;
      margin-right: 8px;
    }
  }
}

.carousel.-default-style {
  position: relative;

  & .carousel__slides {
    margin-left: auto;
    height: 580px;
    width: calc(( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 10 + #{$gutter} * 10);

    @include media('below-medium') {
      height: 400px;
      width: 100%;
    }
    @include media('below-tablet') {
      height: 175px !important;
    }
  }
  & .carousel__slide {
    @extend %background-cover;
    // background-color: white;
    height: 100%;
    min-height: 100%;
    width: 100%;
    min-width: 100%;
  }
  & .carousel__dots  {
    justify-content: flex-end;
    padding: 20px 0;
    margin-bottom: 40px;
  }
  & .carousel__arrows {
    position: absolute;
    top: calc(50% - 48px);
    right: 0;
    // z-index: 5;
    height: 24px;
    width: calc(( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 10 + #{$gutter} * 10);

    &.-is-active {
      & .carousel__arrows-btn {
        opacity: 1;
        transition: all .5s;
      }
    }

    @include media('below-medium') {
      width: 100%;
    }
    @include media('below-small') {
      display: none;
    }

    & .carousel__arrows-btn {
      position: absolute;
      top: 0;
      z-index: 5;

      &.-single {
        display: none;
      }

      &.-prev {
        left: 40px;
        transform: rotate(180deg);
      }
      &.-next {
        right: 40px;
      }

      & .icon {
        font-size: 24px;
        font-weight: bold;
        color: $c-main-gray;
      }

      opacity: 0;
      transition: all .5s;

      @include media('below-tablet') {
        opacity: 1;
        pointer-events: visible;
      }
    }
  }
}

.carousel__rwd-arrow {
  display: none;
  position: absolute;
  z-index: 2;
  top: 0;
  height: 400px;
  width: 50%;

  &.-prev {
    left: 0;
  }
  &.-next {
    right: 0;
  }

  @include media('below-medium') {
    display: block;
  }
  @include media('below-tablet') {
    height: 150px;
  }
}