/* inject:scss */

@import "pages/_about.scss";

@import "pages/_block-colors.scss";

@import "pages/_blog.scss";

@import "pages/_contact.scss";

@import "pages/_index.scss";

@import "pages/_news-post.scss";

@import "pages/_news.scss";

@import "pages/_post.scss";

@import "pages/_village.scss";

/* endinject */