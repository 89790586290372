.column {
	@extend %flex;

	&.-cl-3-9 {
		& .column__item {
			&:first-child {
				width: calc(( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 3 + #{$gutter} * 3);

				@include media('below-medium') {
					width: calc(( ( #{$wrapper-rwd-m} - #{$gutter} * 11 ) / 12) * 3 + #{$gutter} * 3);
				}
			 }
			&:last-child { flex-grow: 2; }
		}
	}
	&.-cl-2-10 {
		& .column__item {
			&:first-child {
				width: calc(( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 2 + #{$gutter} * 2);

				@include media('below-medium') {
					width: calc(( ( #{$wrapper-rwd-m} - #{$gutter} * 11 ) / 12) * 2 + #{$gutter} * 2);
				}
			 }
			&:last-child { flex-grow: 2; }
		}
	}
	&.-cl-5-7 {
		& .column__item {
			&:first-child {
				width: calc(( ( #{$inner-wrap} - #{$gutter} * 11) / 12 ) * 5 + #{$gutter} * 5);

				@include media('below-medium') {
					width: calc(( ( #{$wrapper-rwd-m} - #{$gutter} * 11 ) / 12) * 5 + #{$gutter} * 5);
				}
			 }
			&:last-child { flex-grow: 2; }
		}
	}

	@include media('below-small') {
		flex-direction: column;

		&.-cl-3-9,
		&.-cl-2-10 {
			& .column__item {
				&:first-child {
					width: 100%;
				}
			}
		}
	}
}