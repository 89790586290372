.list-menu {
  padding-top: 96px;

  @include media('below-small') {
    padding-top: 0;
  }

  &.-bright {
    & .list-menu__parent a {
      color: $c-main-light;
    }
  }

  &.-rwd-hide {
    @include media('below-small') {
      display: none;
    }
  }
  &.-rwd-show {
    display: none;
    @include media('below-small') {
      display: block;
    }
  }

  & .list-menu__wrapper {
    margin-bottom: 28px;
  }
  & .list-menu__title {
    font-family: $font-en;
    font-weight: $font-en-medium;
    font-size: 14px;
    letter-spacing: 2.6px;
    margin-bottom: 28px;
  }
  & .list-menu__parent {
    position: relative;
    font-size: 13px;
    letter-spacing: 1.7px;
    line-height: 26px;
    transition: all .5s;
    will-change: transform;
    overflow: visible;

    @include media('above-tablet') {
      &:hover {
        transform: translateX(8px);
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: calc(50% - 1px);
      left: -40px;
      height: 1px;
      width: 0;
      background-color: $c-main-dark;
      transition: all .5s;
      will-change: width;
    }

    &.-is-active {
      transform: translate3d(16px, 0, 0);
      transition: all .5s;
      will-change: transform;
      &:before {
        width: 30px;
        transition: all .5s;
        will-change: width;
      }
    }

    &.-single {
      &:before {
        display: none;
      }
    }
  }
  & .list-menu__child {
    font-size: 12px;
    letter-spacing: 1.7px;
    line-height: 22px;
    color: $c-font-gray;
  }
  & .list-menu__children {
    pointer-events: none;
    opacity: 0;
    height: 0;
    transition: all .3s;
    will-change: height;

    &.-is-active {
      margin-bottom: 16px;
      pointer-events: visible;
      opacity: 1;
      height: auto;
      transition: all .5s;
      will-change: height;
    }
  }
}

.list-menu.-select {
  position: relative;
  width: 100%;

  & .list-menu__chevron {
    position: absolute;
    right: 4px;
    top: 0;
    color: $c-main-dark;
    font-weight: bold;
    font-size: 14px;
    transform: rotate(90deg);
  }
}

.list-menu__select {
  position: relative;
  width: 100%;
  font-family: $font-en;
  font-weight: $font-en-medium;
  font-size: 12px;
  letter-spacing: 2.23px;
  flex-grow: 2;
  z-index: 2;
  padding: 0 4px;
}

.list-menu__select-wrapper {
  @extend %last-no-margin-btm;
  position: relative;
  padding-bottom: 8px;
  border-bottom: 1px solid $c-main-dark;
  margin-bottom: 16px;

  &.-third {
    display: none;
    &.-is-active {
      display: block;
    }
  }

  &.-sml-margin {
    margin-bottom: 12px;
  }

  &.-bright {
    border-color: $c-main-light;

    & .list-menu__select {
      color: $c-main-light;
    }
    & .icon {
      color: $c-main-light;
    }
  }

  & .list-menu__select {
    &.-sub {
      display: none;
    }
    &.-is-active {
      display: block;
    }
  }
}