@charset "UTF-8";
/* inject:scss */
/*Clearfix*/
.clear:before, .clear:after {
  content: " ";
  display: table; }

.clear:after {
  clear: both; }

.container {
  lost-utility: clearfix; }

/*Background Size Cover*/
.background-cover,
.card-img__img,
.carousel.-default-style .carousel__slide,
.about-main .about-main__img,
.about-stripe .about-stripe__img,
.about-last .about-last__titles-img-floater .about-last__titles-img,
.about-last .about-last__img,
.index-hero__main .index-hero__img,
.index-block .index-block__img,
.village__hero .village__hero-img {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }

/*Position*/
.position-relative,
.container,
.wrapper-s,
.form-basic__bar {
  position: relative; }

.position-absolute,
.form-basic__bar .icon {
  position: absolute; }

.position-fixed {
  position: fixed; }

/*Display*/
.block,
.align--center {
  display: block; }

.inlineblock {
  display: inline-block; }

.align--center {
  margin-left: auto;
  margin-right: auto; }

/*Full-width elements*/
.table, .container, body, html,
.full-width {
  width: 100%; }

/*Full-height elements*/
.table,
.full-height {
  height: 100%; }


.full-view-height {
  height: 100vh; }

/*Text alignment*/

.text--left {
  text-align: left; }


.text--right {
  text-align: right; }


.text--center {
  text-align: center; }


.text--justify {
  text-align: justify; }


.inline--top {
  display: inline-block;
  vertical-align: top; }


.inline--middle {
  display: inline-block;
  vertical-align: middle; }


.inline--bottom {
  display: inline-block;
  vertical-align: bottom; }

/*Text style*/

.text--b {
  font-weight: 700; }


.text--green {
  color: #008787; }

/*Align center by table*/
.table {
  display: table; }

.table-cell {
  display: table-cell;
  vertical-align: middle; }

/*Flex*/
.column, .btn-arrow-text, .card-img, .card-img__info-side .card-img__info, .card-img.-large .card-img__info-bar, .carousel__dots, .floater, .info-btn-bar__left.-flex, .pagination .pagination__counts, .sandwich__bar, .title-set.-inline, .header-links, .header-links .header-links__item, .menu__container, .menu__socials, .about-last .about-last__main, .index-block.-third .index-block__text, .news-post-main, .post-main,
.flex {
  display: flex; }

.lightbox.-video,
.flex-center-all {
  display: flex;
  align-items: center;
  justify-content: center; }

.header-wrapper, .header-right,
.flex-center-horizontal {
  display: flex;
  align-items: center; }

.form-basic__bar-title, .info-btn-bar, .footer-links, .about-main, .index-block .index-block__wrapper, .village-content,
.flex-between {
  display: flex;
  justify-content: space-between; }

.btn-dash, .card-img__info-top, .form-basic__bar, .menu__top, .news-post-main .news-post-main__content-btm, .post-main .post-main__content-btm,
.flex-between-center {
  display: flex;
  align-items: center;
  justify-content: space-between; }


.flex-wrap {
  display: flex;
  flex-wrap: wrap; }

.hide {
  display: none !important; }

.carousel__dots .carousel__dots-dot:last-child, .form-basic__bar:last-child, .pagination .pagination__counts .pagination__num:last-child, .floats-socials__item:last-child, .footer-socials__item:last-child, .menu__links .menu__links-link:last-child, .menu__socials .menu__socials-link:last-child, .about-main .about-main__paragraph:last-child, .village-content .village-content__icons .village-content__icon:last-child {
  margin-right: 0; }

.card-img:last-child, .list-menu__select-wrapper:last-child, .sandwich__bar:last-child, .footer-middle__right .footer-socials__item:last-child, .about-last .about-last__titles:last-child, .contact-block:last-child, .news-post-main .news-post-main__content-paragraph:last-child, .post-main .post-main__content-paragraph:last-child, .village-content .village-content__paragraph:last-child {
  margin-bottom: 0; }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 10%, 0); }
  100% {
    opacity: 1;
    transform: none; } }

.p-transition {
  /** Basic styles for an animated element */
  /** An element that fades in */
  /** An element that fades in and slides up */ }
  .p-transition .p-transition__element {
    animation-duration: 0.4s;
    transition-timing-function: ease-in;
    animation-fill-mode: both; }
  .p-transition .p-transition__element-fadeout {
    animation-name: fadeOut; }
  .p-transition .p-transition__element-fadeinup {
    animation-name: fadeInUp; }
  .p-transition.-is-existing .p-transition__element {
    animation-direction: alternate-reverse; }

/* ------------------------------------
 *  Width and Height
 * ------------------------------------ */
/*wrap*/
/*components*/
/*z-index*/
/* ------------------------------------
 *  Color
 * ------------------------------------ */
/* ------------------------------------
 *  Styles
 * ------------------------------------ */
/* ------------------------------------
 *  Font
 * ------------------------------------ */
/* endinject */
/* inject:scss */
.column.-cl-3-9 .column__item:first-child {
  width: calc(( ( 1200px - 20px * 11) / 12 ) * 3 + 20px * 3); }
  @media (max-width: 1280px) {
    .column.-cl-3-9 .column__item:first-child {
      width: calc(( ( 920px - 20px * 11 ) / 12) * 3 + 20px * 3); } }

.column.-cl-3-9 .column__item:last-child {
  flex-grow: 2; }

.column.-cl-2-10 .column__item:first-child {
  width: calc(( ( 1200px - 20px * 11) / 12 ) * 2 + 20px * 2); }
  @media (max-width: 1280px) {
    .column.-cl-2-10 .column__item:first-child {
      width: calc(( ( 920px - 20px * 11 ) / 12) * 2 + 20px * 2); } }

.column.-cl-2-10 .column__item:last-child {
  flex-grow: 2; }

.column.-cl-5-7 .column__item:first-child {
  width: calc(( ( 1200px - 20px * 11) / 12 ) * 5 + 20px * 5); }
  @media (max-width: 1280px) {
    .column.-cl-5-7 .column__item:first-child {
      width: calc(( ( 920px - 20px * 11 ) / 12) * 5 + 20px * 5); } }

.column.-cl-5-7 .column__item:last-child {
  flex-grow: 2; }

@media (max-width: 850px) {
  .column {
    flex-direction: column; }
    .column.-cl-3-9 .column__item:first-child, .column.-cl-2-10 .column__item:first-child {
      width: 100%; } }

.container {
  max-width: 1200px;
  margin: 0 auto; }
  @media (max-width: 1280px) {
    .container {
      max-width: 920px; } }
  @media (max-width: 850px) {
    .container {
      padding: 0 32px; } }

.container.-l {
  max-width: 1325px; }
  @media (max-width: 1280px) {
    .container.-l {
      max-width: 920px; } }
  @media (max-width: 850px) {
    .container.-l {
      padding: 0 32px; } }

.wrapper-s {
  padding: 0 calc(( ( 1200px - 20px * 11) / 12 ) + 20px); }

@font-face {
  font-family: "icon";
  src: url("fonts/icon.eot");
  src: url("fonts/icon.eot?#iefix") format("eot"), url("fonts/icon.ttf") format("truetype"), url("fonts/icon.woff") format("woff"), url("fonts/icon.svg#icon") format("svg");
  font-weight: normal;
  font-style: normal; }

.icon-base,
[class^="icon-"], [class*=" icon-"] {
  font-family: "icon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-arrow-long:before {
  content: "\EA01"; }

.icon-arrow-short:before {
  content: "\EA02"; }

.icon-book:before {
  content: "\EA03"; }

.icon-calendar:before {
  content: "\EA04"; }

.icon-chevron:before {
  content: "\EA05"; }

.icon-class:before {
  content: "\EA06"; }

.icon-cross:before {
  content: "\EA07"; }

.icon-cup:before {
  content: "\EA08"; }

.icon-gift:before {
  content: "\EA09"; }

.icon-house:before {
  content: "\EA0A"; }

.icon-logo-text-en:before {
  content: "\EA0B"; }

.icon-logo-text-zh:before {
  content: "\EA0C"; }

.icon-logo:before {
  content: "\EA0D"; }

.icon-social-fb:before {
  content: "\EA0E"; }

.icon-social-ig:before {
  content: "\EA0F"; }

.icon-social-youtube:before {
  content: "\EA10"; }

.icon-utensil:before {
  content: "\EA11"; }

/* Eric Meyer's Reset CSS v2.0 - http://cssreset.com */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  margin: 0;
  padding: 0; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, main {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

*, *::before, *::after {
  box-sizing: border-box; }

a:focus {
  outline: none; }

/*form reset*/
input,
label,
select,
button,
textarea {
  margin: 0;
  border: 0;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  background: none;
  line-height: 1;
  text-align: left;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  box-sizing: border-box; }

button,
textarea,
input,
select {
  outline: 0; }

select {
  appearance: none; }

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #fff inset; }

button,
input[type="reset"],
input[type="button"],
input[type="submit"] {
  cursor: pointer; }

a {
  text-decoration: none; }

@lost gutter 24px;
body, html {
  position: relative;
  font-family: "Open Sans", Helvetica, "微軟正黑體", Arial, sans-serif;
  color: black;
  font-size: 16px;
  background-color: #EFEFEF;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.8);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden; }
  body.-is-fixed, html.-is-fixed {
    position: fixed;
    overflow-y: hidden; }

a {
  color: black;
  transition: all 0.3s; }
  @media (min-width: 768px) {
    a:hover {
      transform: translateX(4px); } }
  a.-no-hover:hover {
    transform: none; }

.body-container {
  padding-top: 150px; }
  @media (max-width: 850px) {
    .body-container {
      padding-top: 96px; } }

p {
  text-align: justify; }

.-f-base-m {
  font-family: "PingFangTC-Medium", "微軟正黑體", Helvetica, Arial, sans-serif; }

/* endinject */
/* inject:scss */
.breadcrumb {
  text-align: right;
  margin-bottom: 40px; }
  @media (max-width: 850px) {
    .breadcrumb {
      text-align: left;
      margin-bottom: 24px; } }
  .breadcrumb .breadcrumb__bar {
    font-size: 13px;
    letter-spacing: 1.6px;
    margin-left: auto;
    color: #878787; }
    @media (max-width: 850px) {
      .breadcrumb .breadcrumb__bar {
        line-height: 22px; } }
  .breadcrumb .breadcrumb__link {
    color: #878787; }
  .breadcrumb .breadcrumb__current {
    color: rgba(0, 0, 0, 0.7); }

.btn-arrow-text {
  align-items: center; }
  .btn-arrow-text.-prev .btn-arrow-text__icon {
    transform: rotate(180deg); }
  .btn-arrow-text.-prev .btn-arrow-text__text {
    margin-left: 44px; }
    @media (max-width: 850px) {
      .btn-arrow-text.-prev .btn-arrow-text__text {
        margin-left: 8px; } }
  .btn-arrow-text.-next .btn-arrow-text__text {
    margin-right: 44px; }
    @media (max-width: 850px) {
      .btn-arrow-text.-next .btn-arrow-text__text {
        margin-right: 8px; } }
  @media (min-width: 1025px) {
    .btn-arrow-text:hover {
      transform: translateX(0); }
      .btn-arrow-text:hover .btn-arrow-text__icon,
      .btn-arrow-text:hover .btn-arrow-text__text {
        transition: transform .3s; }
      .btn-arrow-text:hover.-prev .btn-arrow-text__text {
        transform: translateX(-8px); }
      .btn-arrow-text:hover.-prev .btn-arrow-text__icon {
        transform: rotate(180deg) translateX(4px); }
      .btn-arrow-text:hover.-next .btn-arrow-text__text {
        transform: translateX(8px); }
      .btn-arrow-text:hover.-next .btn-arrow-text__icon {
        transform: translateX(4px); } }

.btn-arrow-text__text {
  display: block;
  font-size: 13px;
  letter-spacing: 1.95px;
  transition: transform .3s; }

.btn-arrow-text__icon {
  display: block;
  font-size: 20px;
  transition: transform .3s; }

.btn-arrow.-top .icon {
  display: block;
  transform: rotate(-90deg); }

.btn-basic {
  display: block;
  padding: 10px 32px;
  font-size: 13px;
  letter-spacing: 3.2px;
  color: white;
  border: 1px solid transparent;
  background-color: black;
  transition: all .3s; }
  @media (min-width: 1025px) {
    .btn-basic:hover {
      cursor: pointer;
      color: black;
      border-color: black;
      background-color: transparent;
      transition: all .3s; } }

.btn-border {
  display: block;
  text-align: center;
  padding: 10px 32px;
  font-size: 13px;
  letter-spacing: 3.2px;
  border: 1px solid black;
  transition: all .3s; }
  @media (min-width: 1025px) {
    .btn-border:hover {
      transform: translateX(0);
      cursor: pointer;
      color: white;
      border-color: black;
      background-color: black;
      transition: all .3s; } }
  .btn-border.-bright {
    color: #F4F4F4;
    border-color: #F4F4F4; }
    @media (min-width: 1025px) {
      .btn-border.-bright:hover {
        color: white;
        border-color: black;
        background-color: black;
        transition: all .3s; } }

.btn-dash {
  position: relative; }
  .btn-dash.-reverse .btn-dash__text {
    padding: 0 0 0 16px; }
  @media (min-width: 1025px) {
    .btn-dash:hover .btn-dash__text {
      transform: translateX(30%);
      transition: all .5s; }
    .btn-dash:hover .btn-dash__dash {
      margin-left: 10%;
      transition: all .5s; }
    .btn-dash:hover.-reverse .btn-dash__text {
      transform: translateX(-30%);
      transition: all .5s; }
    .btn-dash:hover.-reverse .btn-dash__dash {
      margin: 0 10% 0 0; } }

.btn-dash__text {
  display: inline-block;
  font-size: 13px;
  letter-spacing: 2.6px;
  padding-right: 16px;
  transition: all .5s; }
  @media (max-width: 850px) {
    .btn-dash__text {
      font-size: 11px;
      letter-spacing: 2.2px; } }
  .-bright .btn-dash__text {
    color: white; }

.btn-dash__dash {
  position: relative;
  display: block;
  flex-grow: 2;
  height: 1px;
  background-color: black;
  transition: all .5s; }
  .btn-dash__dash:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    background-color: #C7CBB8;
    transition: width .3s; }
    .-bright .btn-dash__dash:before {
      background-color: white; }
  .-bright .btn-dash__dash {
    background-color: #4A4A4A; }

.card-img {
  margin-bottom: 64px; }
  @media (max-width: 850px) {
    .card-img {
      flex-direction: column;
      margin-bottom: 40px; } }
  .card-img.-skin .card-img__info-side {
    background-color: #F3D9B3; }
  .card-img.-green .card-img__info-side {
    background-color: #C7CBB8; }
  .card-img.-gray .card-img__info-side {
    background-color: #DADADA; }

.card-img__img-side {
  flex-grow: 2; }

.card-img__img {
  min-height: 360px;
  width: 100%; }
  @media (max-width: 850px) {
    .card-img__img {
      min-height: 240px; } }
  @media (max-width: 767px) {
    .card-img__img {
      min-height: 175px; } }

.card-img__info-side {
  width: 40%;
  padding: 30px 32px 24px 32px;
  background-color: white;
  min-height: 360px; }
  @media (max-width: 850px) {
    .card-img__info-side {
      width: 100%;
      min-height: 240px; } }
  @media (max-width: 767px) {
    .card-img__info-side {
      min-height: 150px; } }
  .card-img__info-side .card-img__info {
    flex-direction: column;
    justify-content: space-between;
    height: 100%; }
  @media (min-width: 1025px) {
    .card-img__info-side:hover {
      color: black; }
      .card-img__info-side:hover .btn-dash__text {
        transform: translateX(30%);
        transition: all 0.3s; }
      .card-img__info-side:hover .btn-dash__dash {
        margin-left: 10%;
        transition: all 0.3s; } }

.card-img__info-top {
  padding-bottom: 18px;
  margin-bottom: 22px;
  border-bottom: 1px solid black; }
  .card-img__info-top .card-img__info-top-title {
    font-size: 15px;
    letter-spacing: 2.5px; }
  .card-img__info-top .card-img__info-top-date {
    font-size: 13px;
    letter-spacing: 1.4px; }
  @media (max-width: 850px) {
    .card-img__info-top .card-img__info-top-title {
      font-size: 11px;
      letter-spacing: 1.8px; }
    .card-img__info-top .card-img__info-top-date {
      font-size: 11px;
      letter-spacing: 1.2px; } }

.card-img__info-mid {
  padding: 0 2px; }

.card-img__info-subtitle {
  font-size: 15px;
  letter-spacing: 0.8px;
  margin-bottom: 18px; }
  @media (max-width: 850px) {
    .card-img__info-subtitle {
      font-size: 11px;
      letter-spacing: 0.6px; } }

.card-img__info-title {
  font-size: 22px;
  letter-spacing: 1.6px;
  line-height: 32px; }
  @media (max-width: 850px) {
    .card-img__info-title {
      font-size: 16px;
      letter-spacing: 1.2px;
      line-height: 26px;
      margin-bottom: 72px; } }

.card-img__info-btm {
  padding-bottom: 4px;
  border-bottom: 1px solid black; }
  .card-img__info-btm .card-img__info-btn {
    padding-bottom: 16px;
    border-bottom: 1px solid black; }

.card-img.-large .card-img__info-side {
  width: calc(( ( 1200px - 20px * 11) / 12 ) * 4 + 20px * 4);
  padding: 45px 48px; }
  @media (max-width: 1280px) {
    .card-img.-large .card-img__info-side {
      width: calc(( ( 920px - 20px * 11 ) / 12) * 4 + 20px * 4); } }
  @media (max-width: 850px) {
    .card-img.-large .card-img__info-side {
      width: 100%;
      padding: 32px 34px; } }

.card-img.-large .card-img__img {
  min-height: 600px; }
  @media (max-width: 850px) {
    .card-img.-large .card-img__img {
      min-height: 315px; } }

.card-img.-large .card-img__info-top {
  display: block;
  padding-bottom: 85px;
  margin-bottom: 16px; }
  @media (max-width: 850px) {
    .card-img.-large .card-img__info-top {
      padding-bottom: 16px;
      margin-bottom: 16px; } }
  .card-img.-large .card-img__info-top .card-img__info-top-title {
    font-family: "Aurelia W01 Book", "Baskerville", "Times New Roman", serif;
    font-size: 20px;
    letter-spacing: 1.5px;
    margin-bottom: 10px; }
    @media (max-width: 850px) {
      .card-img.-large .card-img__info-top .card-img__info-top-title {
        margin-bottom: 8px; } }
  .card-img.-large .card-img__info-top .card-img__info-top-subtitle {
    font-family: "source-han-serif-tc", serif;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1.2px; }

.card-img.-large .card-img__info-bar {
  margin-bottom: 40px; }
  @media (max-width: 850px) {
    .card-img.-large .card-img__info-bar {
      justify-content: space-between;
      width: 100%;
      margin-bottom: 16px; } }

.card-img.-large .card-img__info-date {
  font-size: 15px;
  letter-spacing: 1.4px;
  margin-right: 24px; }
  @media (max-width: 850px) {
    .card-img.-large .card-img__info-date {
      font-size: 11px;
      letter-spacing: 1.18px; } }

.card-img.-large .card-img__info-type {
  font-size: 15px;
  letter-spacing: 2.5px; }
  @media (max-width: 850px) {
    .card-img.-large .card-img__info-type {
      font-size: 11px;
      letter-spacing: 1.83px; } }

.carousel {
  position: relative; }

.carousel__slides {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden; }
  .carousel__slides.-relative {
    display: flex; }
    .carousel__slides.-relative .carousel__slide {
      position: relative;
      display: block;
      flex-shrink: 0;
      order: 1;
      opacity: 0;
      transform: translateX(-100%);
      transition: all 1s; }
      .carousel__slides.-relative .carousel__slide.-current {
        order: 1 !important;
        opacity: 1;
        transform: translateX(-100%); }
      .carousel__slides.-relative .carousel__slide.-prev {
        order: 0 !important;
        transform: translateX(-100%); }
      .carousel__slides.-relative .carousel__slide.-next {
        order: 2 !important;
        transform: translateX(-100%); }
      .carousel__slides.-relative .carousel__slide.-single {
        opacity: 1;
        transform: translateX(0); }

.carousel__slide {
  position: absolute;
  width: 100%;
  display: none;
  z-index: 1;
  transition: transform 1s ease-out;
  -webkit-backface-visibility: hidden; }
  .carousel__slide.-current {
    display: block;
    z-index: 5;
    transform: translateX(0); }
    @media (max-width: 850px) {
      .carousel__slide.-current {
        transform: translateX(-2px); } }
  .carousel__slide.-prev {
    display: block;
    transform: translateX(-100%);
    opacity: 0; }
  .carousel__slide.-next {
    display: block;
    transform: translateX(100%);
    opacity: 0; }
  .carousel__slide.-next-to-current {
    display: block;
    z-index: 1000;
    animation: nextToCurrent 1s;
    animation-timing-function: ease-out;
    transform: translateX(0);
    opacity: 1; }
    @media (max-width: 850px) {
      .carousel__slide.-next-to-current {
        animation: none;
        transform: none; } }
  .carousel__slide.-current-to-prev {
    display: block;
    animation: currentToPrev 1s;
    animation-timing-function: ease-out;
    transform: translateX(-100%);
    z-index: 1; }
    @media (max-width: 850px) {
      .carousel__slide.-current-to-prev {
        animation: mobileHide 1s;
        transform: translateX(-20%); } }
  .carousel__slide.-prev-to-current {
    display: block;
    z-index: 1000;
    animation: prevToCurrent 1s;
    animation-timing-function: ease-out;
    transform: translateX(0);
    opacity: 1; }
    @media (max-width: 850px) {
      .carousel__slide.-prev-to-current {
        animation: none;
        transform: none;
        transition: 1s ease-in; } }
  .carousel__slide.-current-to-next {
    display: block;
    animation: currentToNext 1s;
    animation-timing-function: ease-out;
    transform: translateX(100%);
    z-index: 1; }
    @media (max-width: 850px) {
      .carousel__slide.-current-to-next {
        animation: mobileHide 1s;
        transform: translateX(20%); } }
  .carousel__slide.-video iframe {
    width: 100%;
    min-height: 100%;
    pointer-events: none; }

@keyframes nextToCurrent {
  0% {
    z-index: 5;
    transform: translateX(100%); }
  100% {
    z-index: 5;
    transform: translateX(0); } }

@keyframes currentToPrev {
  0% {
    z-index: 1;
    transform: translateX(0); }
  100% {
    z-index: 1;
    transform: translateX(-20%); } }

@keyframes prevToCurrent {
  0% {
    z-index: 5;
    transform: translateX(-100%); }
  100% {
    z-index: 5;
    transform: translateX(0); } }

@keyframes currentToNext {
  0% {
    z-index: 1;
    transform: translateX(0); }
  100% {
    z-index: 1;
    transform: translateX(20%); } }

@keyframes mobileHide {
  0% {
    opacity: 1; }
  25% {
    opacity: 1; }
  50% {
    opacity: 1; }
  75% {
    opacity: 1; }
  99% {
    opacity: 0; }
  100% {
    opacity: 0; } }

.carousel__dots.-single .carousel__dots-dot {
  display: none; }

.carousel__dots .carousel__dots-dot {
  cursor: pointer;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  border: 1px solid #878787;
  margin-right: 10px; }
  .carousel__dots .carousel__dots-dot.-current {
    background-color: #878787; }
  @media (max-width: 850px) {
    .carousel__dots .carousel__dots-dot {
      width: 6px;
      height: 6px;
      margin-right: 8px; } }

.carousel.-default-style {
  position: relative; }
  .carousel.-default-style .carousel__slides {
    margin-left: auto;
    height: 580px;
    width: calc(( ( 1200px - 20px * 11) / 12 ) * 10 + 20px * 10); }
    @media (max-width: 1280px) {
      .carousel.-default-style .carousel__slides {
        height: 400px;
        width: 100%; } }
    @media (max-width: 767px) {
      .carousel.-default-style .carousel__slides {
        height: 175px !important; } }
  .carousel.-default-style .carousel__slide {
    height: 100%;
    min-height: 100%;
    width: 100%;
    min-width: 100%; }
  .carousel.-default-style .carousel__dots {
    justify-content: flex-end;
    padding: 20px 0;
    margin-bottom: 40px; }
  .carousel.-default-style .carousel__arrows {
    position: absolute;
    top: calc(50% - 48px);
    right: 0;
    height: 24px;
    width: calc(( ( 1200px - 20px * 11) / 12 ) * 10 + 20px * 10); }
    .carousel.-default-style .carousel__arrows.-is-active .carousel__arrows-btn {
      opacity: 1;
      transition: all .5s; }
    @media (max-width: 1280px) {
      .carousel.-default-style .carousel__arrows {
        width: 100%; } }
    @media (max-width: 850px) {
      .carousel.-default-style .carousel__arrows {
        display: none; } }
    .carousel.-default-style .carousel__arrows .carousel__arrows-btn {
      position: absolute;
      top: 0;
      z-index: 5;
      opacity: 0;
      transition: all .5s; }
      .carousel.-default-style .carousel__arrows .carousel__arrows-btn.-single {
        display: none; }
      .carousel.-default-style .carousel__arrows .carousel__arrows-btn.-prev {
        left: 40px;
        transform: rotate(180deg); }
      .carousel.-default-style .carousel__arrows .carousel__arrows-btn.-next {
        right: 40px; }
      .carousel.-default-style .carousel__arrows .carousel__arrows-btn .icon {
        font-size: 24px;
        font-weight: bold;
        color: #EFEFEF; }
      @media (max-width: 767px) {
        .carousel.-default-style .carousel__arrows .carousel__arrows-btn {
          opacity: 1;
          pointer-events: visible; } }

.carousel__rwd-arrow {
  display: none;
  position: absolute;
  z-index: 2;
  top: 0;
  height: 400px;
  width: 50%; }
  .carousel__rwd-arrow.-prev {
    left: 0; }
  .carousel__rwd-arrow.-next {
    right: 0; }
  @media (max-width: 1280px) {
    .carousel__rwd-arrow {
      display: block; } }
  @media (max-width: 767px) {
    .carousel__rwd-arrow {
      height: 150px; } }

.fade-in:not(.-pseudo) {
  opacity: 0;
  transform: translateY(80px);
  transition: all 0.5s ease-out; }

.fade-in.-pseudo.-after:after {
  opacity: 0;
  transform: translateY(80px);
  transition: all 0.5s ease-out; }

.fade-in.-pseudo.-before:before {
  opacity: 0;
  transform: translateY(80px);
  transition: all 0.5s ease-out; }

.fade-in.-is-shown {
  opacity: 1;
  transform: translateY(0);
  transition: all 0.5s ease-out; }
  .fade-in.-is-shown.-pseudo.-after:after {
    opacity: 1;
    transform: translateY(0);
    transition: all 0.5s ease-out; }
  .fade-in.-is-shown.-pseudo.-before:before {
    opacity: 1;
    transform: translateY(0);
    transition: all 0.5s ease-out; }

.floater {
  align-items: center;
  overflow: hidden; }

.floater__img {
  transition: transform 5s cubic-bezier(0, 0, 0.04, 1.45);
  will-change: transform; }

.form-basic__wrapper {
  margin-bottom: 40px; }
  @media (max-width: 850px) {
    .form-basic__wrapper:last-child {
      margin-bottom: 0; } }

.form-basic__bar {
  margin-bottom: 26px;
  width: 100%; }
  @media (max-width: 850px) {
    .form-basic__bar {
      margin-bottom: 24px; } }
  .form-basic__bar .icon {
    bottom: 10px;
    right: 16px; }
    @media (max-width: 850px) {
      .form-basic__bar .icon {
        bottom: 8px; } }
    .form-basic__bar .icon.icon-chevron {
      transform: rotate(90deg); }
  .form-basic__bar.-is-error .form-basic__bar-title {
    color: #FF2929; }
  .form-basic__bar.-textarea {
    align-items: flex-start; }
    .form-basic__bar.-textarea .form-bbasic__bar-title {
      margin-top: 4px; }

.form-basic__bar-title {
  width: 15%;
  min-width: 15%; }
  @media (max-width: 850px) {
    .form-basic__bar-title {
      margin-bottom: 0; } }
  .form-basic__bar-title .form-basic__bar-label {
    font-size: 15px;
    letter-spacing: 3.75px;
    line-height: 30px; }
    @media (max-width: 850px) {
      .form-basic__bar-title .form-basic__bar-label {
        font-size: 13px;
        letter-spacing: 2.4px; } }
  .form-basic__bar-title .form-basic__bar-msg {
    font-size: 13px;
    letter-spacing: 1.3px;
    line-height: 30px; }
    @media (max-width: 850px) {
      .form-basic__bar-title .form-basic__bar-msg {
        font-size: 12px; } }

.form-basic__bar-input,
.form-basic__bar-select,
.form-basic__bar-textarea {
  font-family: "PingFangTC-Light", "微軟正黑體", Helvetica, Arial, sans-serif;
  font-size: 15px;
  letter-spacing: 1.69px;
  width: 100%;
  padding: 10px 16px;
  border: 1px solid black;
  border-radius: 0 !important;
  box-shadow: none !important;
  -webkit-appearance: none; }
  .form-basic__bar-input::placeholder,
  .form-basic__bar-select::placeholder,
  .form-basic__bar-textarea::placeholder {
    color: #4A4A4A; }
  @media (max-width: 850px) {
    .form-basic__bar-input,
    .form-basic__bar-select,
    .form-basic__bar-textarea {
      font-size: 13px;
      letter-spacing: 1.8px;
      padding: 9px 12px; } }

.form-basic__bar-select {
  border-radius: 0;
  border: 1px solid black;
  color: #4A4A4A; }

.form-basic__wrapper {
  width: 100%; }

.form-basic__btn {
  margin-left: 15%; }

.info-btn-bar {
  align-items: flex-end;
  width: 100%;
  margin-bottom: 72px; }
  @media (max-width: 850px) {
    .info-btn-bar {
      margin-bottom: 40px; } }
  .info-btn-bar.-border {
    padding-bottom: 36px;
    border-bottom: 1px solid black; }
    @media (max-width: 850px) {
      .info-btn-bar.-border {
        padding-bottom: 24px; } }
  .info-btn-bar.-dark {
    color: black; }

.info-btn-bar__title {
  font-size: 22px;
  letter-spacing: 2.4px;
  line-height: 32px; }
  @media (max-width: 850px) {
    .info-btn-bar__title {
      font-size: 18px;
      letter-spacing: 2px;
      line-height: 25px; } }

.info-btn-bar__subtitle {
  font-size: 14px;
  letter-spacing: 2.4px;
  margin-bottom: 16px; }
  @media (max-width: 850px) {
    .info-btn-bar__subtitle {
      font-size: 12px;
      letter-spacing: 2px;
      margin-bottom: 14px; } }

.info-btn-bar__date {
  font-size: 15px;
  letter-spacing: 1.38px;
  margin-right: 24px; }
  @media (max-width: 850px) {
    .info-btn-bar__date {
      font-size: 11px;
      letter-spacing: 1.18px; } }

.info-btn-bar__type {
  font-size: 15px;
  letter-spacing: 2.4px; }
  @media (max-width: 850px) {
    .info-btn-bar__type {
      font-size: 11px;
      letter-spacing: 1.83px; } }

.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  width: 100vw;
  height: 100vh;
  background-color: black;
  padding: 48px 0;
  pointer-events: none;
  opacity: 0; }
  .lightbox.-is-active {
    transition: all .3s;
    pointer-events: visible;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.8); }

.lightbox__video-wrapper {
  min-width: 80%;
  height: 90%; }
  @media (max-width: 1024px) {
    .lightbox__video-wrapper {
      height: 50%; } }
  @media (max-width: 767px) {
    .lightbox__video-wrapper {
      height: 40%; } }

.lightbox__iframe {
  margin: 0 auto;
  width: 100%;
  height: 100%; }

.list-menu {
  padding-top: 96px; }
  @media (max-width: 850px) {
    .list-menu {
      padding-top: 0; } }
  .list-menu.-bright .list-menu__parent a {
    color: white; }
  @media (max-width: 850px) {
    .list-menu.-rwd-hide {
      display: none; } }
  .list-menu.-rwd-show {
    display: none; }
    @media (max-width: 850px) {
      .list-menu.-rwd-show {
        display: block; } }
  .list-menu .list-menu__wrapper {
    margin-bottom: 28px; }
  .list-menu .list-menu__title {
    font-family: "Open Sans", Helvetica, "微軟正黑體", Arial, sans-serif;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 2.6px;
    margin-bottom: 28px; }
  .list-menu .list-menu__parent {
    position: relative;
    font-size: 13px;
    letter-spacing: 1.7px;
    line-height: 26px;
    transition: all .5s;
    will-change: transform;
    overflow: visible; }
    @media (min-width: 768px) {
      .list-menu .list-menu__parent:hover {
        transform: translateX(8px); } }
    .list-menu .list-menu__parent:before {
      content: '';
      position: absolute;
      top: calc(50% - 1px);
      left: -40px;
      height: 1px;
      width: 0;
      background-color: black;
      transition: all .5s;
      will-change: width; }
    .list-menu .list-menu__parent.-is-active {
      transform: translate3d(16px, 0, 0);
      transition: all .5s;
      will-change: transform; }
      .list-menu .list-menu__parent.-is-active:before {
        width: 30px;
        transition: all .5s;
        will-change: width; }
    .list-menu .list-menu__parent.-single:before {
      display: none; }
  .list-menu .list-menu__child {
    font-size: 12px;
    letter-spacing: 1.7px;
    line-height: 22px;
    color: #4A4A4A; }
  .list-menu .list-menu__children {
    pointer-events: none;
    opacity: 0;
    height: 0;
    transition: all .3s;
    will-change: height; }
    .list-menu .list-menu__children.-is-active {
      margin-bottom: 16px;
      pointer-events: visible;
      opacity: 1;
      height: auto;
      transition: all .5s;
      will-change: height; }

.list-menu.-select {
  position: relative;
  width: 100%; }
  .list-menu.-select .list-menu__chevron {
    position: absolute;
    right: 4px;
    top: 0;
    color: black;
    font-weight: bold;
    font-size: 14px;
    transform: rotate(90deg); }

.list-menu__select {
  position: relative;
  width: 100%;
  font-family: "Open Sans", Helvetica, "微軟正黑體", Arial, sans-serif;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 2.23px;
  flex-grow: 2;
  z-index: 2;
  padding: 0 4px; }

.list-menu__select-wrapper {
  position: relative;
  padding-bottom: 8px;
  border-bottom: 1px solid black;
  margin-bottom: 16px; }
  .list-menu__select-wrapper.-third {
    display: none; }
    .list-menu__select-wrapper.-third.-is-active {
      display: block; }
  .list-menu__select-wrapper.-sml-margin {
    margin-bottom: 12px; }
  .list-menu__select-wrapper.-bright {
    border-color: white; }
    .list-menu__select-wrapper.-bright .list-menu__select {
      color: white; }
    .list-menu__select-wrapper.-bright .icon {
      color: white; }
  .list-menu__select-wrapper .list-menu__select.-sub {
    display: none; }
  .list-menu__select-wrapper .list-menu__select.-is-active {
    display: block; }

.pagination {
  position: relative;
  z-index: 2;
  margin-left: calc(( ( 1200px - 20px * 11) / 12 ) + 20px);
  padding: 48px 0 112px 0; }
  @media (max-width: 850px) {
    .pagination {
      display: flex;
      align-items: center;
      margin-left: 0;
      padding-bottom: 64px; } }
  .pagination:after {
    content: '';
    position: absolute;
    z-index: -2;
    left: calc(( ( 1200px - 20px * 11) / 12 ) * 2 + 20px * 2);
    top: -64px;
    height: calc(100% + 64px);
    width: calc(( ( 100vw - 1200px ) / 2) + ( ( 1200px - 20px * 11) / 12 ) * 9 + 20px * 9);
    background-color: #BA8553; }
    @media (max-width: 850px) {
      .pagination:after {
        top: 0;
        left: auto;
        right: -32px;
        height: 100%;
        width: calc(32px + 80%); } }
  .pagination .pagination__counts {
    position: relative;
    z-index: 2; }
    .pagination .pagination__counts .pagination__num {
      font-family: "Open Sans", Helvetica, "微軟正黑體", Arial, sans-serif;
      font-size: 14px;
      padding: 8px;
      margin-right: 24px; }
      @media (max-width: 850px) {
        .pagination .pagination__counts .pagination__num {
          margin-right: 12px; }
          .pagination .pagination__counts .pagination__num:last-child {
            margin-right: 12px; } }
      .pagination .pagination__counts .pagination__num:last-child {
        position: relative; }
        .pagination .pagination__counts .pagination__num:last-child:after {
          content: '';
          position: absolute;
          z-index: 0;
          left: 52px;
          top: 50%;
          height: 1px;
          width: 1000%;
          background-color: black; }
  @media (max-width: 850px) {
    .pagination .pagination__decor {
      display: block;
      height: 1px;
      width: 100%;
      background-color: black; } }

.sandwich__bar {
  justify-content: space-between;
  width: 100%;
  min-width: 100%;
  padding: 0 2px 18px 2px;
  margin-bottom: 16px;
  border-bottom: 1px solid #878787; }
  @media (max-width: 1280px) {
    .sandwich__bar {
      justify-content: initial; } }
  .sandwich__bar .sandwich__bar-first,
  .sandwich__bar .sandwich__bar-mid,
  .sandwich__bar .sandwich__bar-last {
    font-size: 15px;
    letter-spacing: 1.2px;
    line-height: 16px; }
    @media (max-width: 1280px) {
      .sandwich__bar .sandwich__bar-first,
      .sandwich__bar .sandwich__bar-mid,
      .sandwich__bar .sandwich__bar-last {
        font-size: 11px; } }
  .sandwich__bar .sandwich__bar-first {
    flex-grow: 1;
    min-width: 10%; }
    @media (max-width: 1280px) {
      .sandwich__bar .sandwich__bar-first {
        flex-grow: 0;
        min-width: 30%; } }
  .sandwich__bar .sandwich__bar-mid {
    flex-grow: 2;
    min-width: 82%; }
    @media (max-width: 1280px) {
      .sandwich__bar .sandwich__bar-mid {
        min-width: 0;
        max-width: 75%; } }
    @media (max-width: 850px) {
      .sandwich__bar .sandwich__bar-mid {
        max-width: 70%; } }
    @media (max-width: 767px) {
      .sandwich__bar .sandwich__bar-mid {
        max-width: 50%; } }
  @media (max-width: 1280px) {
    .sandwich__bar .sandwich__bar-last {
      flex-grow: 1;
      text-align: right; } }

.title-set {
  color: white;
  padding-top: 16px;
  margin-bottom: 72px; }
  @media (max-width: 850px) {
    .title-set {
      margin-bottom: 40px; } }
  .title-set.-black {
    color: black; }
  @media (max-width: 850px) {
    .title-set.-rwd-dark {
      color: black; } }
  .title-set.-inline {
    align-items: center; }
    .title-set.-inline .title-set__en {
      margin-right: 20px; }
  .title-set .title-set__en {
    font-family: "Aurelia W01 Book", "Baskerville", "Times New Roman", serif;
    font-size: 36px;
    letter-spacing: 1.6px;
    line-height: 46px; }
    @media (max-width: 850px) {
      .title-set .title-set__en {
        font-size: 24px;
        letter-spacing: 1px;
        line-height: 30px; } }
  .title-set .title-set__zh {
    font-family: "source-han-serif-tc", serif;
    font-weight: 500;
    font-size: 26px;
    letter-spacing: 3.6px;
    line-height: 46px; }
    @media (max-width: 850px) {
      .title-set .title-set__zh {
        font-size: 16px;
        letter-spacing: 2.4px;
        line-height: 23px; } }

/* endinject */
/* inject:scss */
.floats {
  position: absolute;
  top: calc(140px + 685px - 22px * 3 - 48px);
  left: 5%;
  z-index: 50; }
  @media (max-width: 1460px) {
    .floats {
      left: 2%; } }
  @media (max-width: 1280px) {
    .floats {
      left: 3.5%; } }
  @media (max-width: 1100px) {
    .floats {
      display: none; } }

.floats-socials__item {
  margin-bottom: 32px; }
  .floats-socials__item .floats-socials__link {
    height: 22px;
    width: 22px; }
  .floats-socials__item .icon {
    display: block;
    font-size: 20px;
    transform: translateX(1px); }

.footer {
  position: relative;
  z-index: 2;
  padding: 100px 0;
  height: 470px; }
  @media (max-width: 850px) {
    .footer {
      height: 640px; } }
  @media (max-width: 850px) {
    .footer .container {
      width: 90%;
      margin: 0 auto; } }

.footer-top {
  margin-bottom: 64px; }

.footer-logo {
  width: 100%; }
  .footer-logo img {
    width: 160px;
    max-width: 160px; }

.footer-middle {
  align-items: flex-end;
  border-bottom: 1px solid #979797;
  padding-bottom: 36px;
  margin-bottom: 36px; }
  @media (max-width: 850px) {
    .footer-middle {
      border-bottom: none;
      padding-bottom: 0px;
      margin-bottom: 30px; } }

.footer-middle__right .-rwd-show {
  display: none; }

.footer-middle__right .footer-socials__item {
  margin-bottom: 16px; }
  .footer-middle__right .footer-socials__item .footer-socials__link {
    display: block;
    height: 20px; }

@media (max-width: 850px) {
  .footer-middle__right .-rwd-hide {
    display: none; }
  .footer-middle__right .-rwd-show {
    display: block; } }

.footer-links .footer-links__item {
  margin-right: 40px; }
  @media (max-width: 850px) {
    .footer-links .footer-links__item {
      margin-right: 0;
      margin-bottom: 26px; }
      .footer-links .footer-links__item:last-child {
        margin-bottom: 0; } }

.footer-links .footer-links__link {
  font-size: 14px;
  letter-spacing: 2.4px; }

@media (max-width: 850px) {
  .footer-links {
    flex-direction: column; } }

.footer-contact {
  text-align: right; }
  .footer-contact .-bold {
    font-weight: 600; }
  .footer-contact .footer-contact__text {
    text-align: right;
    font-size: 14px;
    letter-spacing: 2.4px;
    line-height: 27px; }

.footer-btm__copyright {
  font-size: 11px;
  letter-spacing: 1.5px;
  line-height: 18px;
  color: #4A4A4A; }

.footer-socials__item {
  margin-right: 26px; }
  .footer-socials__item .icon {
    font-size: 20px; }
  @media (max-width: 850px) {
    .footer-socials__item {
      margin-right: 0; } }

@media (max-width: 850px) {
  .footer-btm__right {
    display: none; } }

.footer-center {
  border-bottom: 1px solid #979797;
  margin-bottom: 30px;
  padding-bottom: 30px; }
  .footer-center .footer-contact__text {
    text-align: left; }
  .footer-center.-rwd-show {
    display: none; }
    @media (max-width: 850px) {
      .footer-center.-rwd-show {
        display: block; } }

header {
  position: fixed;
  width: 100vw;
  z-index: 60;
  transition: all .3s;
  will-change: transform; }
  @media (min-width: 851px) {
    header.-is-inflate {
      height: 140px; } }
  header.-is-inflate .header-wrapper {
    transform: translateY(30px);
    transition: transform .5s; }
    @media (max-width: 850px) {
      header.-is-inflate .header-wrapper {
        transform: translateY(0); } }
  header.-with-bg {
    transition: all .5s;
    background-color: #EFEFEF; }
    header.-with-bg .header-logo.-bright {
      color: black; }
  header.-is-hidden {
    transform: translateY(-150px);
    transition: transform .3s; }
  @media (max-width: 850px) {
    header {
      position: fixed; }
      header.-with-bg {
        transition: all .3s;
        background-color: #F4F4F4; } }

.header-wrapper {
  width: 100%;
  height: 80px;
  padding: 0 5vw; }
  @media (max-width: 850px) {
    .header-wrapper {
      height: 96px; } }

.header-logo .icon {
  font-size: 40px;
  transition: all .3s;
  will-change: font-size; }
  .-is-inflate .header-logo .icon {
    font-size: 60px;
    transition: all .3s;
    will-change: font-size; }
    @media (max-width: 850px) {
      .-is-inflate .header-logo .icon {
        font-size: 36px; } }

.-is-inflate .header-logo.-index .icon {
  font-size: 70px;
  transition: all .3s;
  will-change: font-size; }
  @media (max-width: 850px) {
    .-is-inflate .header-logo.-index .icon {
      font-size: 36px; } }

.header-logo.-bright {
  color: white; }

@media (max-width: 850px) {
  .header-logo .icon {
    font-size: 36px; } }

.header-links .header-links__item {
  align-items: center;
  margin-right: 40px; }
  .header-links .header-links__item:last-child {
    margin-right: 80px; }
  @media (max-width: 850px) {
    .header-links .header-links__item {
      margin-right: 48px; }
      .header-links .header-links__item:first-child {
        margin-right: 20px; }
      .header-links .header-links__item.-rwd-hide {
        display: none; } }

.header-links .header-links__link {
  font-size: 14px;
  letter-spacing: 2.4px; }
  @media (max-width: 850px) {
    .header-links .header-links__link {
      font-size: 12px;
      letter-spacing: 2px; } }

.header-ham {
  display: block;
  position: fixed;
  top: 24px;
  right: 5vw;
  z-index: 90;
  height: 16px;
  width: 26px;
  padding: 16px;
  transition: all 0.3s;
  transition: transform .5s;
  will-change: transform; }
  .header-ham.-is-hidden {
    transform: translateY(-150px);
    transition: all .5s; }
  .header-ham.-is-inflate {
    transform: translateY(30px);
    transition: transform .5s; }
    @media (max-width: 850px) {
      .header-ham.-is-inflate {
        transform: translateY(0); } }
  .header-ham.-menu-opened {
    margin-right: 8px; }
    .header-ham.-menu-opened .header__ham-line {
      background-color: #DADADA; }
      .header-ham.-menu-opened .header__ham-line:first-child {
        top: 15px;
        transform: rotate(45deg); }
      .header-ham.-menu-opened .header__ham-line:last-child {
        bottom: 15px;
        transform: rotate(-45deg); }
    .header-ham.-menu-opened:hover .header__ham-line {
      background-color: white; }
  .header-ham:hover .header__ham-line {
    transition: all 0.3s;
    background-color: #4A4A4A; }
  @media (max-width: 850px) {
    .header-ham {
      top: 31px;
      height: 14px;
      width: 24px; } }
  .header-ham .header__ham-line {
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: black;
    transition: all 0.3s; }
  .header-ham .header__ham-line:first-child {
    top: 8px; }
    @media (max-width: 850px) {
      .header-ham .header__ham-line:first-child {
        top: 10px; } }
  .header-ham .header__ham-line:last-child {
    bottom: 8px; }
    @media (max-width: 850px) {
      .header-ham .header__ham-line:last-child {
        bottom: 10px; } }

.menu {
  position: absolute;
  right: 0;
  overflow-x: hidden;
  z-index: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none; }
  .menu:after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    opacity: 0;
    will-change: transform; }
    @media (max-width: 1024px) {
      .menu:after {
        display: none; } }
  .menu.-is-active {
    z-index: 80;
    pointer-events: visible; }
    .menu.-is-active:after {
      opacity: 1;
      transition: all .1s;
      will-change: transform; }
    .menu.-is-active .menu__container {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: all .4s ease-out;
      will-change: transform; }

.menu__container {
  flex-direction: column;
  justify-content: space-between;
  margin-left: auto;
  padding: 60px 5vw 32px 5vw;
  height: 100%;
  width: 30%;
  background-color: black;
  opacity: 0;
  transform: translate3d(30%, 0, 0);
  transition: all .4s;
  will-change: transform; }
  @media (max-width: 1024px) {
    .menu__container {
      padding: 40px 10vw;
      width: 100%;
      transform: translate3d(50%, 0, 0);
      transition: all .4s ease-out; } }

.menu__wrapper {
  max-height: 100%; }

.menu__top {
  margin-bottom: 200px; }
  @media (max-width: 1024px) {
    .menu__top {
      margin-bottom: 15vh; } }
  .menu__top .menu__top-btn {
    font-size: 20px;
    color: #DADADA; }
    @media (min-width: 1025px) {
      .menu__top .menu__top-btn:hover {
        color: white; } }
  .menu__top .menu__top-link {
    font-size: 14px;
    letter-spacing: 0.7px;
    color: #DADADA;
    margin-right: calc( ( 1200px - 20px * 11) / 12); }
    @media (min-width: 1025px) {
      .menu__top .menu__top-link:hover {
        color: white; } }
    @media (max-width: 1024px) {
      .menu__top .menu__top-link {
        font-size: 13px;
        letter-spacing: 0.65px; } }
  .menu__top .menu__top-language {
    font-family: "PingFangTC-Medium", "微軟正黑體", Helvetica, Arial, sans-serif;
    font-size: 14px;
    letter-spacing: 0.7px;
    color: #878787; }
    @media (min-width: 1025px) {
      .menu__top .menu__top-language:hover {
        color: white; } }
    @media (max-width: 1024px) {
      .menu__top .menu__top-language {
        font-size: 13px;
        letter-spacing: 0.65px; } }

.menu__links .menu__links-link {
  margin-bottom: 24px; }
  @media (max-width: 1024px) {
    .menu__links .menu__links-link {
      margin-bottom: 40px; } }
  .menu__links .menu__links-link a {
    font-size: 24px;
    letter-spacing: 4px;
    color: #DADADA; }
    @media (min-width: 1025px) {
      .menu__links .menu__links-link a:hover {
        color: white; } }
    @media (max-width: 1024px) {
      .menu__links .menu__links-link a {
        font-size: 20px;
        letter-spacing: 3.7px; } }

.menu__socials .menu__socials-link {
  margin-right: 32px; }

.menu__socials a .icon {
  font-size: 20px;
  color: #DADADA; }
  @media (min-width: 1025px) {
    .menu__socials a .icon:hover {
      color: white; } }

/* endinject */
/* inject:scss */
.about {
  position: relative;
  background-color: #C7CBB8; }
  .about:before {
    content: '';
    position: absolute;
    left: 0;
    top: calc(150px * -1);
    height: 150px;
    width: 100vw;
    background-color: #C7CBB8; }
  .about:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: calc(470px * -1);
    height: 470px;
    width: 100vw;
    background-color: #C7CBB8; }
    @media (max-width: 850px) {
      .about:after {
        bottom: calc(640px * -1);
        height: 640px; } }

.about-top {
  position: relative;
  z-index: 1; }
  @media (max-width: 850px) {
    .about-top {
      margin-bottom: 48px; } }
  .about-top:before {
    content: '';
    position: absolute;
    z-index: 0;
    right: calc(( ( 100vw - 1200px ) / 2) * -1);
    top: 0;
    height: 100%;
    width: calc(100vw - ( ( 100vw - 1200px ) / 2) - ( ( 1200px - 20px * 11) / 12 ) * 1.5 - 20px);
    background-color: #E4DDCF; }
    @media (max-width: 1280px) {
      .about-top:before {
        display: none; } }
  .about-top .title-set {
    position: relative;
    z-index: 1;
    margin-bottom: 0;
    padding-top: 64px;
    padding-bottom: 4px; }

.about-main {
  position: relative;
  padding: 0 calc(( ( 1200px - 20px * 11) / 12 ) + 20px);
  width: 100%; }
  @media (max-width: 1280px) {
    .about-main {
      flex-direction: column;
      padding: 0; } }
  .about-main:before {
    content: '';
    position: absolute;
    z-index: 1;
    right: calc(( ( 100vw - 1200px ) / 2) * -1);
    top: 0;
    height: 100%;
    width: calc(100vw - ( ( 100vw - 1200px ) / 2) - ( ( 1200px - 20px * 11) / 12 ) * 1.5 - 20px);
    background-color: #E4DDCF; }
    @media (max-width: 1280px) {
      .about-main:before {
        top: 25%;
        right: calc(( ( 100vw - 920px ) / 2) * -1);
        height: 75%;
        width: calc(100% + ( ( 100vw - 920px ) / 2)); } }
    @media (max-width: 850px) {
      .about-main:before {
        top: 10%;
        right: -32px;
        height: 90%;
        width: calc(100% + 32px); } }
  .about-main:after {
    content: '';
    position: absolute;
    z-index: 0;
    left: calc(( ( 100vw - 1200px ) / 2) * -1);
    bottom: 0;
    height: 72px;
    width: calc(( ( 100vw - 1200px ) / 2) + 1200px);
    background-color: white; }
    @media (max-width: 850px) {
      .about-main:after {
        left: -32px;
        width: calc(100% + 32px);
        height: 35%; } }
  .about-main .about-main__right {
    z-index: 2;
    padding-left: calc(( ( 1200px - 20px * 11) / 12 ) + 20px * 2); }
    @media (max-width: 1280px) {
      .about-main .about-main__right {
        padding-left: 10%; } }
  @media (max-width: 1280px) {
    .about-main .about-main__left {
      margin-bottom: 145px; } }
  @media (max-width: 850px) {
    .about-main .about-main__left {
      margin-bottom: 48px; } }
  .about-main .about-main__title {
    font-family: "source-han-serif-tc", serif;
    font-weight: 500;
    font-size: 21px;
    letter-spacing: 2.8px;
    line-height: 35px;
    margin-bottom: 48px; }
    @media (max-width: 850px) {
      .about-main .about-main__title {
        font-size: 14px;
        letter-spacing: 1.4px;
        line-height: 24px; } }
  .about-main .about-main__paragraph {
    font-size: 15px;
    letter-spacing: 1px;
    line-height: 30px;
    margin-bottom: 32px; }
    @media (max-width: 850px) {
      .about-main .about-main__paragraph {
        font-size: 13px;
        letter-spacing: 1.2px;
        line-height: 27px; } }
  .about-main .about-main__img-floater {
    position: relative;
    z-index: 2;
    transform: translateY(104px);
    width: calc(( ( 1200px - 20px * 11) / 12 ) * 4 + 20px * 3); }
    @media (max-width: 1280px) {
      .about-main .about-main__img-floater {
        width: 60%; } }
    @media (max-width: 850px) {
      .about-main .about-main__img-floater {
        width: 80%;
        transform: none; } }
  .about-main .about-main__img {
    height: 580px;
    min-height: 580px;
    width: 100%; }
    @media (max-width: 1280px) {
      .about-main .about-main__img {
        height: 300px;
        min-height: 300px; } }

.about-stripe {
  position: relative; }
  .about-stripe:before {
    content: '';
    position: absolute;
    z-index: 1;
    right: calc(( ( 100vw - 1200px ) / 2) * -1);
    top: 0;
    height: 50%;
    width: calc(100vw - ( ( 100vw - 1200px ) / 2) - ( ( 1200px - 20px * 11) / 12 ) * 1.5 - 20px);
    background-color: #E4DDCF; }
    @media (max-width: 1280px) {
      .about-stripe:before {
        right: calc(( ( 100vw - 920px ) / 2) * -1);
        width: calc(100% + ( ( 100vw - 920px ) / 2)); } }
    @media (max-width: 850px) {
      .about-stripe:before {
        right: -32px;
        width: calc(100% + 32px); } }
  .about-stripe:after {
    content: '';
    position: absolute;
    z-index: 0;
    left: calc(( ( 100vw - 1200px ) / 2) * -1);
    bottom: 0;
    height: 100%;
    width: calc(( ( 100vw - 1200px ) / 2) + 1200px);
    background-color: white; }
    @media (max-width: 1280px) {
      .about-stripe:after {
        left: calc(( ( 100vw - 920px ) / 2) * -1);
        width: calc(( ( 100vw - 920px ) / 2) + 920px); } }
    @media (max-width: 850px) {
      .about-stripe:after {
        left: -32px;
        width: 100%; } }
    @media (max-width: 767px) {
      .about-stripe:after {
        left: -32px;
        width: calc(100% + 32px); } }
  .about-stripe .about-stripe__img-floater {
    position: relative;
    z-index: 2;
    margin-left: auto;
    transform: translateX(calc(( ( 1200px - 20px * 11) / 12 ) * 0.3));
    width: calc(( ( 1200px - 20px * 11) / 12 ) * 4.3 + 20px * 3); }
    @media (max-width: 850px) {
      .about-stripe .about-stripe__img-floater {
        width: 90%;
        transform: none; } }
  .about-stripe .about-stripe__img {
    height: 270px;
    min-height: 270px;
    width: 100%; }
    @media (max-width: 850px) {
      .about-stripe .about-stripe__img {
        height: 380px;
        min-height: 380px; } }
    @media (max-width: 767px) {
      .about-stripe .about-stripe__img {
        height: 140px;
        min-height: 140px; } }

.about-bars {
  position: relative; }
  @media (max-width: 850px) {
    .about-bars {
      padding-top: 88px; } }
  .about-bars:before {
    content: '';
    position: absolute;
    z-index: 0;
    left: calc(( ( 100vw - 1200px ) / 2) * -1);
    top: 0;
    height: 100%;
    width: calc(( ( 100vw - 1200px ) / 2) + 1200px);
    background-color: white; }
    @media (max-width: 1280px) {
      .about-bars:before {
        left: calc(( ( 100vw - 920px ) / 2) * -1);
        width: calc( 920px); } }
    @media (max-width: 850px) {
      .about-bars:before {
        left: -32px;
        width: 100%; } }
    @media (max-width: 767px) {
      .about-bars:before {
        left: -32px;
        width: calc(100% + 32px); } }
  .about-bars .about-bars__top {
    position: relative;
    z-index: 1; }
  .about-bars .about-bars__main {
    position: relative;
    z-index: 1;
    padding: 0 calc(( ( 1200px - 20px * 11) / 12 ) + 20px);
    padding-bottom: 104px; }
    @media (max-width: 850px) {
      .about-bars .about-bars__main {
        padding: 0 10% 104px 0; } }
  .about-bars .about-bars__main-hidden {
    pointer-events: none;
    opacity: 0;
    height: 0;
    transition: all .3s;
    will-change: height; }
    .about-bars .about-bars__main-hidden.-is-active {
      margin-bottom: 16px;
      pointer-events: visible;
      opacity: 1;
      height: auto;
      transition: all .5s;
      will-change: height; }
  .about-bars .about-bars__more {
    display: block;
    margin: 0 auto;
    transform: rotate(90deg);
    transition: all .3s; }
    .about-bars .about-bars__more.-is-hidden {
      transform: rotate(-90deg); }
    .about-bars .about-bars__more .icon {
      font-size: 20px; }
    @media (min-width: 1025px) {
      .about-bars .about-bars__more:hover {
        transform: rotate(90deg) translateX(6px);
        transition: all .3s; }
      .about-bars .about-bars__more.-is-hidden:hover {
        transform: rotate(-90deg) translateX(6px); } }

.about-last {
  position: relative; }
  @media (max-width: 850px) {
    .about-last {
      padding-bottom: 72px; } }
  .about-last:before {
    content: '';
    position: absolute;
    z-index: 0;
    left: calc(( ( 100vw - 1200px ) / 2) * -1);
    top: 0;
    height: 100%;
    width: calc(( ( 100vw - 1200px ) / 2) + 1200px);
    background-color: white; }
    @media (max-width: 1280px) {
      .about-last:before {
        left: calc(( ( 100vw - 920px ) / 2) * -1);
        width: calc( 920px); } }
    @media (max-width: 850px) {
      .about-last:before {
        left: -32px;
        width: 100%; } }
    @media (max-width: 767px) {
      .about-last:before {
        left: -32px;
        width: calc(100% + 32px); } }
  .about-last .about-last__top {
    position: relative;
    z-index: 1; }
  .about-last .about-last__main {
    position: relative;
    z-index: 1;
    padding: 0 calc(( ( 1200px - 20px * 11) / 12 ) + 20px);
    padding-bottom: 104px; }
    @media (max-width: 850px) {
      .about-last .about-last__main {
        padding: 0; } }
  .about-last .about-last__left {
    min-width: calc(( ( 1200px - 20px * 11) / 12 ) * 5 + 20px * 5); }
    @media (max-width: 1280px) {
      .about-last .about-last__left {
        min-width: calc(( ( 920px - 20px * 11 ) / 12) * 5 + 20px * 5); } }
    @media (max-width: 850px) {
      .about-last .about-last__left {
        min-width: 0;
        max-width: 90%; } }
  @media (max-width: 1280px) {
    .about-last .about-last__right {
      padding-left: 32px; } }
  @media (max-width: 850px) {
    .about-last .about-last__right {
      display: none; } }
  .about-last .about-last__titles {
    color: #878787;
    margin-bottom: 32px;
    cursor: pointer;
    transition: all .3s; }
    .about-last .about-last__titles.-is-active {
      color: black;
      transition: all .3s; }
    @media (min-width: 1025px) {
      .about-last .about-last__titles:hover {
        color: black;
        transition: all .3s; } }
    @media (max-width: 850px) {
      .about-last .about-last__titles {
        color: black;
        pointer-events: none; } }
  .about-last .about-last__titles-title {
    font-size: 19px;
    letter-spacing: 2px;
    margin-bottom: 22px; }
    @media (max-width: 850px) {
      .about-last .about-last__titles-title {
        font-size: 16px;
        letter-spacing: 1.6px; } }
  .about-last .about-last__titles-desc {
    font-family: "PingFangTC-Light", "微軟正黑體", Helvetica, Arial, sans-serif;
    font-size: 15px;
    letter-spacing: 1px;
    line-height: 26px; }
    @media (max-width: 850px) {
      .about-last .about-last__titles-desc {
        font-size: 13px;
        letter-spacing: 1.2px;
        line-height: 24px;
        margin-bottom: 32px; } }
  .about-last .about-last__titles-img-floater {
    display: none;
    transform: translateX(32px);
    height: 240px;
    width: calc(100% + 32px); }
    @media (max-width: 850px) {
      .about-last .about-last__titles-img-floater {
        display: flex;
        align-items: center; } }
    .about-last .about-last__titles-img-floater .about-last__titles-img {
      height: 240px;
      min-height: 240px;
      width: 100%;
      transition: background .3s; }
      @media (max-width: 850px) {
        .about-last .about-last__titles-img-floater .about-last__titles-img {
          height: 400px;
          min-height: 400px; } }
      @media (max-width: 767px) {
        .about-last .about-last__titles-img-floater .about-last__titles-img {
          height: 195px;
          min-height: 195px; } }
  .about-last .about-last__img-floater {
    transform: translateX(100px);
    height: 410px;
    width: calc(120px + ( ( 1200px - 20px * 11) / 12 ) * 5 + 20px * 4); }
    @media (max-width: 1280px) {
      .about-last .about-last__img-floater {
        transform: translateX(0); } }
  .about-last .about-last__img {
    height: 410px;
    min-height: 410px;
    width: 100%;
    transition: background .3s; }

.post-main.-skin:before,
.news-post-main.-skin:before {
  background-color: #F3D9B3; }

.post-main.-green:before,
.news-post-main.-green:before {
  background-color: #C7CBB8; }

.post-main.-gray:before,
.news-post-main.-gray:before {
  background-color: #DADADA; }

.post-main.-military:before,
.news-post-main.-military:before {
  background-color: #4B5346; }

.village-right.-skin:after {
  background-color: #F3D9B3; }

.village-right.-green:after {
  background-color: #C7CBB8; }

.village-right.-gray:after {
  background-color: #DADADA; }

.village-right.-military:after {
  background-color: #4B5346; }

.blog:before {
  content: '';
  position: absolute;
  z-index: 0;
  left: -32px;
  top: 0;
  height: calc(96px + 180px);
  width: calc(70% + 18px);
  background-color: white;
  display: none; }
  @media (max-width: 850px) {
    .blog:before {
      display: block; } }

.blog-header,
.blog-posts__card {
  position: relative;
  z-index: 1; }
  .blog-header:before,
  .blog-posts__card:before {
    content: '';
    position: absolute;
    z-index: -2;
    left: calc(( ( 100vw - 1200px ) / 2) * -1);
    top: calc(150px * -1);
    height: calc(100% + 150px);
    width: calc(( ( 100vw - 1200px ) / 2) + ( ( 1200px - 20px * 11) / 12 ) * 7 + 20px * 7);
    background-color: white; }
    @media (max-width: 1280px) {
      .blog-header:before,
      .blog-posts__card:before {
        left: calc(( ( 100vw - 920px ) / 2) * -1);
        width: calc(( ( 100vw - 920px ) / 2) + ( ( 920px - 20px * 11 ) / 12) * 7 + 20px * 7); } }
    @media (max-width: 850px) {
      .blog-header:before,
      .blog-posts__card:before {
        left: -32px;
        top: calc(96px * -1);
        height: calc(100% + 96px);
        width: calc(70% + 32px); } }

.blog-select {
  position: relative;
  z-index: 1;
  margin-bottom: 40px; }
  .blog-select:before {
    content: '';
    position: absolute;
    z-index: -2;
    left: -32px;
    top: 0;
    height: calc(100% + 40px);
    width: calc(70% + 32px);
    background-color: white; }

.blog-header {
  padding-bottom: 40px; }
  .blog-header .title-set {
    margin-bottom: 0; }

.blog-menu {
  position: relative;
  z-index: 2; }

.blog-posts .blog-posts__card:before {
  height: 100%;
  top: 0;
  left: calc(( ( 100vw - 1200px ) / 2) * -1 - ( ( 1200px - 20px * 11) / 12 ) * 3 - 20px * 3); }
  @media (max-width: 1280px) {
    .blog-posts .blog-posts__card:before {
      left: calc(( ( 100vw - 920px ) / 2) * -1 - ( ( 920px - 20px * 11 ) / 12) * 3 - 20px * 3); } }
  @media (max-width: 850px) {
    .blog-posts .blog-posts__card:before {
      left: -32px; } }

.blog-posts .blog-posts__card:after {
  content: '';
  position: absolute;
  z-index: -2;
  left: calc(( ( 1200px - 20px * 11) / 12 ) * 4 + 20px * 4);
  top: 0;
  height: 100%;
  width: calc(( ( 100vw - 1200px ) / 2) + ( ( 1200px - 20px * 11) / 12 ) * 5 + 20px * 4);
  background-color: #BA8553; }
  @media (max-width: 1280px) {
    .blog-posts .blog-posts__card:after {
      left: calc(( ( 920px - 20px * 11 ) / 12) * 4 + 20px * 4);
      width: calc(( ( 100vw - 920px ) / 2) + ( ( 920px - 20px * 11 ) / 12) * 5 + 20px * 4); } }
  @media (max-width: 850px) {
    .blog-posts .blog-posts__card:after {
      left: auto;
      right: -32px;
      height: 100%;
      width: calc(30% + 32px); } }

.blog-posts .blog-posts__card:first-child:after {
  height: calc(50% + 32px);
  top: auto;
  bottom: 0; }
  @media (max-width: 850px) {
    .blog-posts .blog-posts__card:first-child:after {
      height: 65%; } }

.blog-posts .blog-posts__card:last-child:before {
  height: 50%; }

.blog-posts .blog-posts__card.card-img {
  margin-bottom: 0;
  padding-bottom: 64px; }
  @media (max-width: 850px) {
    .blog-posts .blog-posts__card.card-img {
      padding-bottom: 40px; }
      .blog-posts .blog-posts__card.card-img:last-child {
        padding-bottom: 0; } }

.contact {
  position: relative;
  z-index: 1;
  min-height: calc(100vh - 470px - 150px); }
  @media (max-width: 850px) {
    .contact {
      min-height: calc(100vh - 640px - 96px); } }
  .contact:before {
    content: '';
    position: absolute;
    z-index: 0;
    left: 0;
    top: calc(150px * -1);
    height: calc(100% + 150px + 470px);
    width: calc(( ( 100vw - 1200px ) / 2) + ( ( 1200px - 20px * 11) / 12 ) * 5 + 20px * 4);
    background-color: white; }
    @media (max-width: 1280px) {
      .contact:before {
        width: calc(( ( 100vw - 920px ) / 2) + ( ( 920px - 20px * 11 ) / 12) * 5 + 20px * 4); } }
    @media (max-width: 850px) {
      .contact:before {
        display: none; } }
  .contact:after {
    content: '';
    position: absolute;
    z-index: -1;
    right: 0;
    top: calc(150px * -1);
    height: calc(100% + 150px + 470px);
    width: 100vw;
    background-color: #C7CBB8; }
    @media (max-width: 850px) {
      .contact:after {
        display: none; } }

.contact__column {
  padding-top: 80px; }
  @media (max-width: 850px) {
    .contact__column {
      padding-top: 64px;
      flex-direction: column; } }
  .contact__column .column__item {
    position: relative; }
  @media (max-width: 1024px) {
    .contact__column.-cl-5-7 .column__item:first-child {
      width: 100%; } }
  @media (max-width: 850px) {
    .contact__column .column__item:first-child {
      padding-bottom: 80px; } }
  .contact__column .column__item:first-child:before {
    content: '';
    position: absolute;
    z-index: -1;
    left: -32px;
    top: -64px;
    height: calc(100% + 64px);
    width: 100vw;
    background-color: white; }
    @media (max-width: 850px) {
      .contact__column .column__item:first-child:before {
        display: block; } }

.contact-header {
  position: relative;
  padding-top: 100px; }
  @media (max-width: 850px) {
    .contact-header {
      padding-top: 48px; }
      .contact-header:before {
        content: '';
        position: absolute;
        z-index: -1;
        left: -32px;
        top: calc(96px * -1);
        height: calc(100% + 96px);
        width: 100vw;
        background-color: white; } }
  .contact-header .contact-header__title {
    font-size: 26px;
    letter-spacing: 4px; }
    @media (max-width: 850px) {
      .contact-header .contact-header__title {
        font-size: 16px;
        letter-spacing: 2.4px; } }

.contact-block {
  margin-bottom: 40px; }
  .contact-block .contact-block__title {
    font-family: "PingFangTC-Medium", "微軟正黑體", Helvetica, Arial, sans-serif; }
  .contact-block .contact-block__title,
  .contact-block .contact-block__item {
    text-align: left;
    font-size: 15px;
    letter-spacing: 1.8px;
    line-height: 30px; }
    @media (max-width: 850px) {
      .contact-block .contact-block__title,
      .contact-block .contact-block__item {
        font-size: 13px;
        letter-spacing: 1.6px;
        line-height: 27px; } }

.contact-form {
  position: relative;
  padding-left: calc(( ( 1200px - 20px * 11) / 12 ) + 20px * 2); }
  @media (max-width: 1024px) {
    .contact-form {
      padding-left: 0; } }
  @media (max-width: 850px) {
    .contact-form {
      padding: 58px 0 72px 0; }
      .contact-form:before {
        content: '';
        position: absolute;
        z-index: -1;
        left: -32px;
        top: 0;
        height: 100%;
        width: 100vw;
        background-color: #C7CBB8; } }

.contact-btm .contact-btm__text {
  font-size: 11px;
  letter-spacing: 1.57px;
  line-height: 26px; }

.index {
  position: relative; }

.index-hero {
  position: relative;
  z-index: 1;
  padding-bottom: 32px; }
  .index-hero:before {
    content: '';
    position: absolute;
    z-index: -2;
    left: 0;
    top: calc(150px * -1);
    height: calc(100% + 150px);
    width: calc(( ( 100% - 1200px ) / 2 ) + ( ( 1200px - 20px * 11) / 12 ) * 8 + 20px * 7);
    background-color: #E4DDCF; }
    @media (max-width: 850px) {
      .index-hero:before {
        width: 65%; } }
  .index-hero:after {
    content: '';
    position: absolute;
    z-index: -1;
    right: 0;
    bottom: 0;
    height: calc(50%);
    width: calc(( ( 100% - 1200px ) / 2 ) + ( ( 1200px - 20px * 11) / 12 ) * 9 + 20px * 9);
    background-color: #20271E; }
    @media (max-width: 850px) {
      .index-hero:after {
        height: 80%;
        width: 80%; } }

.index-hero__main {
  position: relative;
  margin-left: auto;
  max-width: calc(100vw - ( ( 1200px - 20px * 11) / 12 ) - 20px); }
  @media (max-width: 1024px) {
    .index-hero__main {
      margin: 0 auto;
      max-width: calc(100vw - ( (100% - 20px * 11) / 12) - 20px); } }
  @media (max-width: 850px) {
    .index-hero__main {
      max-width: 100vw; } }
  .index-hero__main .index-hero__carousel {
    margin-bottom: 32px; }
    @media (max-width: 850px) {
      .index-hero__main .index-hero__carousel {
        margin-bottom: 16px; } }
  .index-hero__main .index-hero__img {
    min-height: 685px;
    max-height: 685px;
    width: 100%; }
    @media (max-width: 850px) {
      .index-hero__main .index-hero__img {
        min-height: 400px;
        max-height: 400px; } }
    @media (max-width: 767px) {
      .index-hero__main .index-hero__img {
        min-height: 175px;
        max-height: 175px; } }
  .index-hero__main .index-hero__captions {
    text-align: right;
    color: white; }
  .index-hero__main .index-hero__caption {
    font-size: 15px;
    letter-spacing: 2.4px;
    line-height: 24px;
    text-align: right; }
    @media (max-width: 850px) {
      .index-hero__main .index-hero__caption {
        font-size: 10px;
        letter-spacing: 1.6px;
        line-height: 14px; } }

.index-hero__slides {
  min-height: 685px; }
  @media (max-width: 850px) {
    .index-hero__slides {
      min-height: 400px; } }
  @media (max-width: 767px) {
    .index-hero__slides {
      min-height: 175px; } }

.index-hero__counter {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: calc(50% - 100px);
  right: -72px; }
  @media (max-width: 1100px) {
    .index-hero__counter {
      display: none; } }
  .index-hero__counter .index-hero__counter-num {
    display: block;
    font-size: 14px;
    letter-spacing: 2.6px;
    margin-bottom: 20px; }
    .index-hero__counter .index-hero__counter-num:last-child {
      color: white;
      margin-bottom: 0; }
  .index-hero__counter .index-hero__counter-bar {
    position: relative;
    width: 1px;
    height: 200px;
    background-color: #DADADA;
    margin-bottom: 20px; }
  .index-hero__counter .index-hero__counter-steppers {
    height: 100%; }
  .index-hero__counter .index-hero__counter-stepper {
    display: block;
    width: 1px;
    padding: 0 4px; }
  .index-hero__counter .index-hero__counter-indicator {
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    background-color: #20271E;
    transition: all .5s; }

.index-block {
  position: relative;
  z-index: 1; }
  .index-block .index-block__wrapper {
    width: 100%; }
    @media (max-width: 850px) {
      .index-block .index-block__wrapper {
        flex-direction: column; } }
  .index-block .index-block__text {
    padding-top: 56px; }
  .index-block .index-block__text-side {
    color: white;
    max-width: calc( ( ( 1200px - 20px * 11) / 12 ) * 6 + 20px * 5); }
    @media (max-width: 1024px) {
      .index-block .index-block__text-side {
        max-width: calc( ( (100% - 20px * 11) / 12) * 9 + 20px * 8); } }
  .index-block .index-block__text-indent {
    margin-left: calc(( ( 1200px - 20px * 11) / 12 ) + 20px); }
    @media (max-width: 1024px) {
      .index-block .index-block__text-indent {
        margin-left: calc(( (100% - 20px * 11) / 12) + 20px); } }
    @media (max-width: 850px) {
      .index-block .index-block__text-indent {
        margin-left: 0; } }
  .index-block .index-block__title {
    font-size: 36px;
    letter-spacing: 4.2px;
    line-height: 46px;
    font-family: "source-han-serif-tc", serif;
    font-weight: 500;
    margin-bottom: 56px; }
    @media (max-width: 850px) {
      .index-block .index-block__title {
        font-size: 22px;
        letter-spacing: 2.86px;
        margin-bottom: 30px; } }
  .index-block .index-block__subtitle {
    font-size: 23px;
    letter-spacing: 3.5px;
    line-height: 38px;
    font-family: "source-han-serif-tc", serif;
    font-weight: 500;
    margin-bottom: 24px; }
    @media (max-width: 850px) {
      .index-block .index-block__subtitle {
        font-size: 14px;
        letter-spacing: 1.4px;
        line-height: 24px; } }
  .index-block .index-block__paragraph {
    font-size: 15px;
    letter-spacing: 2px;
    line-height: 30px;
    font-family: "PingFangTC-Light", "微軟正黑體", Helvetica, Arial, sans-serif; }
    @media (max-width: 850px) {
      .index-block .index-block__paragraph {
        font-size: 11px;
        letter-spacing: 1px;
        line-height: 23px; } }

.index-block.-first {
  background-color: #E4DDCF;
  padding-top: 80px; }
  @media (max-width: 850px) {
    .index-block.-first {
      padding-top: 16px; } }
  @media (max-width: 1024px) {
    .index-block.-first .index-block__img-side {
      width: calc(( (100% - 20px * 11) / 12) * 5 + 20px * 4);
      margin-right: 40px; } }
  @media (max-width: 850px) {
    .index-block.-first .index-block__img-side {
      width: 100%;
      margin-right: 0; } }
  .index-block.-first .index-block__img {
    min-height: 460px;
    width: calc(( ( 1200px - 20px * 11) / 12 ) * 5 + 20px * 4); }
    @media (max-width: 1024px) {
      .index-block.-first .index-block__img {
        width: 100%; } }
    @media (max-width: 850px) {
      .index-block.-first .index-block__img {
        min-height: 280px;
        width: 80%; } }
    @media (max-width: 767px) {
      .index-block.-first .index-block__img {
        min-height: 193px; } }
  .index-block.-first:before {
    content: '';
    position: absolute;
    z-index: -2;
    right: 0;
    bottom: 0;
    height: 50%;
    width: 100%;
    background-color: #4B5346; }
    @media (max-width: 850px) {
      .index-block.-first:before {
        height: 40%; } }
  .index-block.-first:after {
    content: '';
    position: absolute;
    z-index: -1;
    right: 0;
    bottom: 0;
    height: 100%;
    width: calc(( ( 100% - 1200px ) / 2 ) + ( ( 1200px - 20px * 11) / 12 ) * 9 + 20px * 9);
    background-color: #20271E; }
    @media (max-width: 850px) {
      .index-block.-first:after {
        width: 80%; } }
  @media (max-width: 850px) {
    .index-block.-first .index-block__text-side {
      max-width: 100%;
      margin-left: 25%; } }

.index-block.-second {
  background-color: #E4DDCF;
  padding-top: 40px; }
  @media (max-width: 1024px) {
    .index-block.-second .index-block__img-side {
      width: calc(( (100% - 20px * 11) / 12) * 4 + 20px * 3);
      margin-right: 40px; } }
  @media (max-width: 850px) {
    .index-block.-second .index-block__img-side {
      width: 100%; } }
  .index-block.-second .index-block__img {
    min-height: 580px;
    width: calc(( ( 1200px - 20px * 11) / 12 ) * 4 + 20px * 4); }
    @media (max-width: 1024px) {
      .index-block.-second .index-block__img {
        width: 100%; } }
    @media (max-width: 850px) {
      .index-block.-second .index-block__img {
        min-height: 300px;
        width: 60vw;
        margin: 0 auto; } }
    @media (max-width: 767px) {
      .index-block.-second .index-block__img {
        background-position-x: left; } }
  .index-block.-second .index-block__text {
    padding-top: 246px; }
    @media (max-width: 850px) {
      .index-block.-second .index-block__text {
        padding-top: 64px;
        max-width: 100%; } }
  .index-block.-second:before {
    content: '';
    position: absolute;
    z-index: -2;
    left: 0;
    bottom: 0;
    height: 100%;
    width: calc(( ( 100% - 1200px ) / 2 ) + ( ( 1200px - 20px * 11) / 12 ) * 8 + 20px * 7);
    background-color: #4B5346; }
    @media (max-width: 850px) {
      .index-block.-second:before {
        width: 80%; } }
  .index-block.-second:after {
    content: '';
    position: absolute;
    z-index: -1;
    right: 0;
    top: 0;
    height: 130px;
    width: calc(( ( 100% - 1200px ) / 2 ) + ( ( 1200px - 20px * 11) / 12 ) * 9 + 20px * 9);
    background-color: #20271E; }
    @media (max-width: 850px) {
      .index-block.-second:after {
        height: 25%;
        width: 80%; } }
  @media (max-width: 850px) {
    .index-block.-second .index-block__wrapper {
      flex-direction: column-reverse;
      padding-top: 24px; } }

.index-block.-third {
  background-color: #E4DDCF;
  padding-top: 72px; }
  @media (max-width: 850px) {
    .index-block.-third .index-block__text-wrapper {
      color: white; } }
  .index-block.-third .index-block__text-indent {
    margin-left: 0; }
  .index-block.-third .index-block__link-wrapper {
    max-width: 60%;
    margin-left: auto; }
    @media (max-width: 850px) {
      .index-block.-third .index-block__link-wrapper {
        max-width: 100%;
        margin-right: auto;
        margin-left: initial; }
        .index-block.-third .index-block__link-wrapper .index-block__link {
          color: white; }
          .index-block.-third .index-block__link-wrapper .index-block__link .btn-dash__dash {
            background-color: white; } }
  .index-block.-third .index-block__text-side {
    width: 100%;
    max-width: calc(( ( 1200px - 20px * 11) / 12 ) * 5 + 20px * 5); }
    @media (max-width: 1024px) {
      .index-block.-third .index-block__text-side {
        max-width: calc(( (100% - 20px * 11) / 12) * 5 + 20px * 5); } }
    @media (max-width: 1024px) {
      .index-block.-third .index-block__text-side {
        max-width: 75%; } }
  .index-block.-third .index-block__text {
    align-items: center;
    padding-top: 0;
    height: 100%;
    color: black; }
  .index-block.-third .index-block__text-wrapper {
    width: 100%; }
  .index-block.-third .index-block__img-side {
    width: calc(( ( 1200px - 20px * 11) / 12 ) * 7 + 20px * 7); }
    @media (max-width: 1024px) {
      .index-block.-third .index-block__img-side {
        margin-right: 40px;
        width: calc(( (100% - 20px * 11) / 12) * 5 + 20px * 5 + ( ( 1200px - 20px * 11) / 12 )/2); } }
    @media (max-width: 850px) {
      .index-block.-third .index-block__img-side {
        min-height: 150px;
        width: 90%;
        margin: 0 0 64px auto; } }
    @media (max-width: 767px) {
      .index-block.-third .index-block__img-side {
        min-height: 183px; } }
  .index-block.-third .index-block__img {
    min-height: 360px;
    width: calc(( ( 1200px - 20px * 11) / 12 ) * 5 + 20px * 5 + ( ( 1200px - 20px * 11) / 12 )/2); }
    @media (max-width: 1024px) {
      .index-block.-third .index-block__img {
        width: 100%; } }
    @media (max-width: 850px) {
      .index-block.-third .index-block__img {
        min-height: 300px;
        width: 100%; } }
    @media (max-width: 767px) {
      .index-block.-third .index-block__img {
        min-height: 150px; } }
  .index-block.-third .index-block__paragraph {
    margin-bottom: 40px; }
  .index-block.-third:before {
    content: '';
    position: absolute;
    z-index: -2;
    left: 0;
    bottom: 0;
    height: 100%;
    width: calc(( ( 100% - 1200px ) / 2 ) + ( ( 1200px - 20px * 11) / 12 ) * 8 + 20px * 7);
    background-color: #4B5346; }
    @media (max-width: 850px) {
      .index-block.-third:before {
        width: 80%; } }

.index-block.-fourth {
  background-color: #E4DDCF;
  padding-top: 56px; }
  @media (max-width: 850px) {
    .index-block.-fourth {
      padding-bottom: 116px; } }
  .index-block.-fourth .index-block__img-side {
    margin-left: calc(( ( 1200px - 20px * 11) / 12 ) * 7 + 20px * 6);
    max-width: calc(( ( 1200px - 20px * 11) / 12 ) * 5 + 20px * 5); }
    @media (max-width: 1024px) {
      .index-block.-fourth .index-block__img-side {
        margin-left: calc(( (100% - 20px * 11) / 12) * 7 + 20px * 6);
        max-width: calc(( (100% - 20px * 11) / 12) * 5 + 20px * 5);
        width: calc(( (100% - 20px * 11) / 12) * 4 + 20px * 4); } }
    @media (max-width: 850px) {
      .index-block.-fourth .index-block__img-side {
        margin-left: 10%;
        max-width: 90%;
        width: 100%; } }
  .index-block.-fourth .index-block__img {
    min-height: 300px;
    width: calc(( ( 1200px - 20px * 11) / 12 ) * 4 + 20px * 4); }
    @media (max-width: 1024px) {
      .index-block.-fourth .index-block__img {
        width: 100%; } }
    @media (max-width: 850px) {
      .index-block.-fourth .index-block__img {
        min-height: 240px;
        width: 100%; } }
    @media (max-width: 767px) {
      .index-block.-fourth .index-block__img {
        min-height: 207px; } }
  .index-block.-fourth:before {
    content: '';
    position: absolute;
    z-index: -2;
    left: 0;
    bottom: 0;
    height: 100%;
    width: calc(( ( 100% - 1200px ) / 2 ) + ( ( 1200px - 20px * 11) / 12 ) * 8 + 20px * 7);
    background-color: #4B5346; }
    @media (max-width: 850px) {
      .index-block.-fourth:before {
        width: 80%;
        height: 45%;
        bottom: auto;
        top: 0; } }

.index-block.-last {
  padding-bottom: 132px; }
  @media (max-width: 850px) {
    .index-block.-last {
      padding-bottom: 72px; } }
  .index-block.-last .index-block__img-side {
    width: calc(( ( 1200px - 20px * 11) / 12 ) * 5 + 20px * 4); }
    @media (max-width: 1024px) {
      .index-block.-last .index-block__img-side {
        width: calc(( (100% - 20px * 11) / 12) * 5 + 20px * 4); } }
    @media (max-width: 850px) {
      .index-block.-last .index-block__img-side {
        width: 90%;
        transform: translateX(32px); } }
  .index-block.-last .index-block__img {
    height: 730px;
    width: 100%; }
    @media (max-width: 850px) {
      .index-block.-last .index-block__img {
        height: 390px;
        margin-left: auto; } }
  .index-block.-last .index-block__text-side {
    padding-top: 168px; }
    @media (max-width: 850px) {
      .index-block.-last .index-block__text-side {
        padding-top: 150px;
        margin-bottom: 64px;
        max-width: 75%; } }
  .index-block.-last .index-block__paragraph {
    font-family: "PingFangTC-Light", "微軟正黑體", Helvetica, Arial, sans-serif;
    font-size: 20px;
    letter-spacing: 3px;
    line-height: 40px;
    margin-bottom: 88px; }
    @media (max-width: 850px) {
      .index-block.-last .index-block__paragraph {
        font-size: 13px;
        letter-spacing: 1.5px;
        line-height: 26px; } }
  @media (max-width: 850px) {
    .index-block.-last .index-block__link.btn-dash .btn-dash__dash {
      background-color: white; } }
  .index-block.-last:before {
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    bottom: 0;
    height: calc(100% - 110px);
    width: calc(( ( 100% - 1200px ) / 2 ) + ( ( 1200px - 20px * 11) / 12 ) * 12 + 20px * 11);
    background-color: #20271E; }
    @media (max-width: 850px) {
      .index-block.-last:before {
        bottom: auto;
        top: 100px;
        height: calc(85% - 100px);
        width: 80%; } }
  .index-block.-last:after {
    content: '';
    position: absolute;
    z-index: -2;
    right: 0;
    top: 0;
    height: 110px;
    width: calc(( ( 100% - 1200px ) / 2 ) + ( ( 1200px - 20px * 11) / 12 ) * 10 + 20px * 10);
    background-color: #E4DDCF; }
    @media (max-width: 850px) {
      .index-block.-last:after {
        height: 85%;
        width: 100%; } }

.index-cards {
  position: relative; }
  .index-cards:before {
    content: '';
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    height: calc(100% - 360px * 0.7);
    width: calc(( ( 100% - 1200px ) / 2 ) + ( ( 1200px - 20px * 11) / 12 ) * 8 + 20px * 7);
    background-color: #4B5346; }
    @media (max-width: 850px) {
      .index-cards:before {
        display: none; } }
  .index-cards:after {
    content: '';
    position: absolute;
    z-index: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: calc(( ( 100% - 1200px ) / 2 ) + ( ( 1200px - 20px * 11) / 12 ) * 10 + 20px * 10);
    background-color: #E4DDCF; }
  .index-cards .container {
    z-index: 2; }
  .index-cards .index-cards__wrapper {
    margin-left: auto;
    max-width: calc(( ( 1200px - 20px * 11) / 12 ) * 10 + 20px * 10);
    margin-bottom: 48px; }
    @media (max-width: 1024px) {
      .index-cards .index-cards__wrapper {
        max-width: 100%; } }

.index-cards__btm {
  max-width: calc(( ( 1200px - 20px * 11) / 12 ) * 5 + 20px * 5);
  padding: 0 calc(( ( 1200px - 20px * 11) / 12 ) + 20px) 124px calc(( ( 1200px - 20px * 11) / 12 ) + 20px); }
  @media (max-width: 1024px) {
    .index-cards__btm {
      max-width: calc(( (100% - 20px * 11) / 12) * 5 + 20px * 5);
      padding: 0 calc(( (100% - 20px * 11) / 12) + 20px) 124px calc(( (100% - 20px * 11) / 12) + 20px); } }
  @media (max-width: 850px) {
    .index-cards__btm {
      max-width: 100%;
      padding: 0; } }

.index__go-top {
  position: absolute;
  z-index: 2;
  bottom: 2px;
  right: calc((100vw - 1325px)/2);
  transition: all .3s; }
  .index__go-top .icon {
    font-size: 40px; }
  @media (min-width: 1025px) {
    .index__go-top:hover {
      transform: translateY(-4px);
      transition: all .3s; }
      .index__go-top:hover .icon {
        color: #4A4A4A;
        transition: all .3s; } }

.news-post {
  position: relative;
  z-index: 1; }
  .news-post:before {
    content: '';
    position: absolute;
    z-index: -2;
    left: 0;
    top: calc(150px * -1);
    height: calc(100% - 150px);
    width: 50vw;
    background-color: white; }
    @media (max-width: 850px) {
      .news-post:before {
        top: calc(96px * -1);
        width: 70%;
        height: calc(75% + 96px); } }

.news-post-carousel {
  position: relative;
  z-index: 20; }
  .news-post-carousel .news-post-carousel__dots.carousel__dots {
    margin-bottom: 0;
    padding-bottom: 40px; }

.news-post-main {
  position: relative;
  z-index: 1;
  padding-bottom: 100px; }
  .news-post-main:before {
    content: '';
    position: absolute;
    z-index: -2;
    right: calc(( ( 100vw - 1200px ) / 2) * -1);
    bottom: 0;
    height: calc(100% + 580px / 2 + 70px);
    width: calc(( ( 100vw - 1200px ) / 2) + ( ( 1200px - 20px * 11) / 12 ) * 8 + 20px * 7);
    background-color: #E4DDCF; }
    @media (max-width: 1280px) {
      .news-post-main:before {
        height: calc(100% + 400px / 2 + 70px);
        right: calc(( ( 100vw - 920px ) / 2) * -1);
        width: calc(( ( 100vw - 920px ) / 2) + ( ( 920px - 20px * 11 ) / 12) * 8 + 20px * 7); } }
    @media (max-width: 850px) {
      .news-post-main:before {
        height: calc(100% + 150px / 2 + 66px);
        right: -32px;
        width: calc(100% + 32px); } }
  .news-post-main .title-set {
    padding-top: 0; }
  .news-post-main .news-post-main__left {
    width: calc(( ( 1200px - 20px * 11) / 12 ) * 4 + 20px * 4);
    min-width: calc(( ( 1200px - 20px * 11) / 12 ) * 4 + 20px * 4); }
    @media (max-width: 1280px) {
      .news-post-main .news-post-main__left {
        width: calc(( ( 1200px - 20px * 11) / 12 ) * 3 + 20px * 3);
        min-width: calc(( ( 1200px - 20px * 11) / 12 ) * 3 + 20px * 3); } }
    @media (max-width: 850px) {
      .news-post-main .news-post-main__left {
        display: none; } }
    .news-post-main .news-post-main__left .news-post-main__left-top {
      margin-bottom: 600px; }
    .news-post-main .news-post-main__left .news-post-main__left-btm {
      padding-right: calc(( ( 1200px - 20px * 11) / 12 ) + 20px);
      text-align: right; }
  .news-post-main .news-post-main__right {
    flex-grow: 2; }
  .news-post-main .news-post-main__content {
    padding-left: calc(( ( 1200px - 20px * 11) / 12 ) + 20px); }
    @media (max-width: 850px) {
      .news-post-main .news-post-main__content {
        padding-left: 10%; } }
  .news-post-main .news-post-main__content-top {
    margin-bottom: 36px; }
    @media (max-width: 850px) {
      .news-post-main .news-post-main__content-top .info-btn-bar__right {
        display: none; } }
  .news-post-main .news-post-main__content-mid {
    position: relative;
    padding-bottom: 68px;
    margin-bottom: 32px;
    border-bottom: 1px solid black; }
    .news-post-main .news-post-main__content-mid:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -8px;
      height: 1px;
      width: 100%;
      background-color: black; }
  .news-post-main .news-post-main__content-btm {
    width: 100%;
    padding: 0 2px; }
  .news-post-main .news-post-main__content-title {
    font-size: 26px;
    letter-spacing: 2.4px;
    margin-bottom: 56px; }
    @media (max-width: 850px) {
      .news-post-main .news-post-main__content-title {
        font-size: 16px;
        letter-spacing: 1.8px;
        line-height: 27px;
        margin-bottom: 32px; } }
  .news-post-main .news-post-main__content-subtitle {
    font-size: 15px;
    letter-spacing: 2.4px;
    margin-bottom: 14px; }
    @media (max-width: 850px) {
      .news-post-main .news-post-main__content-subtitle {
        font-size: 11px;
        letter-spacing: 1.8px; } }
  .news-post-main .news-post-main__content-paragraph {
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 32px;
    margin-bottom: 40px; }
    @media (max-width: 850px) {
      .news-post-main .news-post-main__content-paragraph {
        font-size: 15px;
        letter-spacing: 1.6px;
        line-height: 30px;
        margin-bottom: 32px; } }
  .news-post-main .news-post-main__content-btn {
    width: 150px;
    display: none; }
    @media (max-width: 850px) {
      .news-post-main .news-post-main__content-btn {
        display: block; } }

.news:before {
  content: '';
  position: absolute;
  z-index: 0;
  left: -32px;
  top: 0;
  height: calc(96px + 180px);
  width: calc(70% + 18px);
  background-color: #E4DDCF;
  display: none; }
  @media (max-width: 850px) {
    .news:before {
      display: block; } }

.news-header,
.news-posts__card {
  position: relative;
  z-index: 1; }
  .news-header:before,
  .news-posts__card:before {
    content: '';
    position: absolute;
    z-index: -2;
    left: calc(( ( 100vw - 1200px ) / 2) * -1);
    top: calc(150px * -1);
    height: calc(100% + 150px);
    width: calc(( ( 100vw - 1200px ) / 2) + ( ( 1200px - 20px * 11) / 12 ) * 8 + 20px * 8);
    background-color: #E4DDCF; }
    @media (max-width: 1280px) {
      .news-header:before,
      .news-posts__card:before {
        left: calc(( ( 100vw - 920px ) / 2) * -1);
        width: calc(( ( 100vw - 920px ) / 2) + ( ( 920px - 20px * 11 ) / 12) * 8 + 20px * 8); } }
    @media (max-width: 850px) {
      .news-header:before,
      .news-posts__card:before {
        left: -32px;
        top: calc(96px * -1);
        height: calc(100% + 96px);
        width: calc(70% + 32px); } }

.news-select.list-menu.-select {
  position: relative;
  z-index: 1;
  margin-bottom: 40px;
  width: calc(70% + 32px); }
  .news-select.list-menu.-select:before {
    content: '';
    position: absolute;
    z-index: -2;
    left: -32px;
    top: 0;
    height: calc(100% + 40px);
    width: 100%;
    background-color: #E4DDCF; }
  .news-select.list-menu.-select .news-select__wrapper {
    width: 75%; }

.news-header {
  padding-bottom: 40px; }
  .news-header .title-set {
    margin-bottom: 0; }

.news-menu {
  position: relative;
  z-index: 2;
  padding-top: 56px; }

.news-carousel {
  position: relative;
  z-index: 1; }
  .news-carousel:before {
    content: '';
    position: absolute;
    z-index: -1;
    left: calc(( ( 100vw - 1200px ) / 2) * -1 - ( ( 1200px - 20px * 11) / 12 ) * 2 - 20px);
    top: 0;
    height: 100%;
    width: calc(( ( 100vw - 1200px ) / 2) + ( ( 1200px - 20px * 11) / 12 ) * 8 + 20px * 8);
    background-color: #E4DDCF; }
    @media (max-width: 1280px) {
      .news-carousel:before {
        left: calc(( ( 100vw - 920px ) / 2) * -1 - ( ( 920px - 20px * 11 ) / 12) * 2 - 20px * 2);
        width: calc(( ( 100vw - 920px ) / 2) + ( ( 920px - 20px * 11 ) / 12) * 8 + 20px * 8); } }
    @media (max-width: 850px) {
      .news-carousel:before {
        left: -32px;
        width: calc(70% + 32px); } }
  .news-carousel:after {
    content: '';
    position: absolute;
    z-index: -2;
    left: calc(( ( 1200px - 20px * 11) / 12 ) * 4 + 20px * 4);
    bottom: 90px;
    height: calc((100% - 90px) / 2);
    width: calc(( ( 100vw - 1200px ) / 2) + ( ( 1200px - 20px * 11) / 12 ) * 10 + 20px * 10);
    background-color: #EFEFEF; }
    @media (max-width: 850px) {
      .news-carousel:after {
        display: none; } }
  .news-carousel.carousel.-default-style .news-carousel__slides {
    height: 480px; }
  .news-carousel .news-carousel__dots.carousel__dots {
    margin-bottom: 0;
    padding-bottom: 60px; }
    @media (max-width: 850px) {
      .news-carousel .news-carousel__dots.carousel__dots {
        padding-bottom: 40px; } }

.news-posts .news-posts__card:before {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  left: calc(( ( 100vw - 1200px ) / 2) * -1 - ( ( 1200px - 20px * 11) / 12 ) * 3 - 20px * 3);
  height: 100%;
  width: calc(( ( 100vw - 1200px ) / 2) + ( ( 1200px - 20px * 11) / 12 ) * 9 + 20px * 10);
  background-color: #E4DDCF; }
  @media (max-width: 1280px) {
    .news-posts .news-posts__card:before {
      width: calc(( ( 100vw - 920px ) / 2) + ( ( 920px - 20px * 11 ) / 12) * 9 + 20px * 9);
      left: calc(( ( 100vw - 920px ) / 2) * -1 - ( ( 920px - 20px * 11 ) / 12) * 3 - 20px * 3); } }
  @media (max-width: 850px) {
    .news-posts .news-posts__card:before {
      width: calc(70% + 32px);
      left: -32px; } }

.news-posts .news-posts__card:after {
  content: '';
  position: absolute;
  z-index: -2;
  left: calc(( ( 1200px - 20px * 11) / 12 ) * 4 + 20px * 4);
  top: 0;
  height: 100%;
  width: calc(( ( 100vw - 1200px ) / 2) + ( ( 1200px - 20px * 11) / 12 ) * 10 + 20px * 10);
  background-color: #EFEFEF; }
  @media (max-width: 1280px) {
    .news-posts .news-posts__card:after {
      left: calc(( ( 920px - 20px * 11 ) / 12) * 6 + 20px * 6);
      width: calc(( ( 100vw - 920px ) / 2) + ( ( 920px - 20px * 11 ) / 12) * 10 + 20px * 10); } }
  @media (max-width: 850px) {
    .news-posts .news-posts__card:after {
      left: auto;
      right: -32px;
      height: 100%;
      width: calc(30% + 32px); } }

@media (max-width: 850px) {
  .news-posts .news-posts__card:first-child:after {
    top: auto;
    bottom: 0;
    height: calc(100% - 150px); } }

.news-posts .news-posts__card:last-child:before {
  height: 50%; }
  @media (max-width: 850px) {
    .news-posts .news-posts__card:last-child:before {
      height: 65%; } }

.news-posts .news-posts__card.card-img {
  margin-bottom: 0;
  padding-bottom: 64px; }
  @media (max-width: 850px) {
    .news-posts .news-posts__card.card-img {
      padding-bottom: 40px; }
      .news-posts .news-posts__card.card-img:last-child {
        padding-bottom: 0; } }

.news-posts .news-posts__card .card-img__info-side {
  width: calc(( ( 1200px - 20px * 11) / 12 ) * 4 + 20px * 3); }
  @media (max-width: 1280px) {
    .news-posts .news-posts__card .card-img__info-side {
      width: calc(( ( 920px - 20px * 11 ) / 12) * 4 + 20px * 3); } }
  @media (max-width: 850px) {
    .news-posts .news-posts__card .card-img__info-side {
      width: 100%; } }

.news .pagination:after {
  background-color: #EFEFEF; }

.post {
  position: relative;
  z-index: 1; }
  .post:before {
    content: '';
    position: absolute;
    z-index: -2;
    left: 0;
    top: calc(150px * -1);
    height: calc(100% + 150px + 470px);
    width: 50vw;
    background-color: white; }
    @media (max-width: 850px) {
      .post:before {
        top: calc(96px * -1);
        width: 70%;
        height: calc(100% + 96px + 640px); } }

.post-carousel {
  position: relative;
  z-index: 20; }
  .post-carousel .post-carousel__dots.carousel__dots {
    margin-bottom: 0;
    padding-bottom: 40px; }

.post-main {
  position: relative;
  z-index: 1;
  padding-bottom: 100px; }
  @media (max-width: 850px) {
    .post-main {
      flex-direction: column; } }
  .post-main:before {
    content: '';
    position: absolute;
    z-index: -2;
    right: calc(( ( 100vw - 1200px ) / 2) * -1);
    bottom: 0;
    height: calc(100% + 580px / 2 + 70px);
    width: calc(( ( 100vw - 1200px ) / 2) + ( ( 1200px - 20px * 11) / 12 ) * 8 + 20px * 7);
    background-color: #F3D9B3; }
    @media (max-width: 1280px) {
      .post-main:before {
        height: calc(100% + 400px / 2 + 70px);
        right: calc(( ( 100vw - 920px ) / 2) * -1);
        width: calc(( ( 100vw - 920px ) / 2) + ( ( 920px - 20px * 11 ) / 12) * 8 + 20px * 7); } }
    @media (max-width: 850px) {
      .post-main:before {
        height: calc(100% + 150px / 2 + 66px);
        right: -32px;
        width: calc(100% + 32px); } }
  .post-main .title-set {
    padding-top: 0; }
  .post-main .post-main__left {
    width: calc(( ( 1200px - 20px * 11) / 12 ) * 4 + 20px * 4);
    min-width: calc(( ( 1200px - 20px * 11) / 12 ) * 4 + 20px * 4); }
    @media (max-width: 1280px) {
      .post-main .post-main__left {
        width: calc(( ( 1200px - 20px * 11) / 12 ) * 3 + 20px * 3);
        min-width: calc(( ( 1200px - 20px * 11) / 12 ) * 3 + 20px * 3); } }
    @media (max-width: 850px) {
      .post-main .post-main__left {
        width: 100%;
        min-width: 100%;
        padding-left: 10%; } }
    .post-main .post-main__left .post-main__left-top {
      margin-bottom: 600px; }
      @media (max-width: 850px) {
        .post-main .post-main__left .post-main__left-top {
          margin-bottom: 32px !important; } }
    .post-main .post-main__left .post-main__left-btm {
      padding-right: calc(( ( 1200px - 20px * 11) / 12 ) + 20px);
      text-align: right; }
      @media (max-width: 850px) {
        .post-main .post-main__left .post-main__left-btm {
          display: none; } }
  .post-main .post-main__right {
    flex-grow: 2; }
  .post-main .post-main__content {
    padding-left: calc(( ( 1200px - 20px * 11) / 12 ) + 20px); }
    @media (max-width: 850px) {
      .post-main .post-main__content {
        padding-left: 10%; } }
  .post-main .post-main__content-top {
    margin-bottom: 36px; }
    @media (max-width: 850px) {
      .post-main .post-main__content-top {
        margin-bottom: 24px; }
        .post-main .post-main__content-top .info-btn-bar__right {
          display: none; } }
  .post-main .post-main__content-mid {
    position: relative;
    padding-bottom: 68px;
    margin-bottom: 32px;
    border-bottom: 1px solid black; }
    .post-main .post-main__content-mid:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -8px;
      height: 1px;
      width: 100%;
      background-color: black; }
  .post-main .post-main__content-btm {
    width: 100%;
    padding: 0 2px; }
  .post-main .post-main__content-title {
    font-size: 26px;
    letter-spacing: 2.4px;
    margin-bottom: 56px; }
    @media (max-width: 850px) {
      .post-main .post-main__content-title {
        font-size: 16px;
        letter-spacing: 1.8px;
        line-height: 27px;
        margin-bottom: 32px; } }
  .post-main .post-main__content-paragraph {
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 32px;
    margin-bottom: 40px; }
    @media (max-width: 850px) {
      .post-main .post-main__content-paragraph {
        font-size: 13px;
        letter-spacing: 1.2px;
        line-height: 27px;
        margin-bottom: 32px; } }
  .post-main .post-main__content-btn {
    width: 150px;
    display: none; }
    @media (max-width: 850px) {
      .post-main .post-main__content-btn {
        display: block; } }

.village {
  position: relative;
  z-index: 1; }
  .village:before {
    content: '';
    position: absolute;
    z-index: -2;
    left: 0;
    top: calc(150px * -1);
    height: calc(100% + 150px + 470px);
    width: calc(( ( 100vw - 1200px ) / 2) + ( ( 1200px - 20px * 11) / 12 ) * 6 + 20px * 5);
    background-color: #E4DDCF; }
    @media (max-width: 850px) {
      .village:before {
        top: calc(96px * -1);
        height: calc(100% + 96px + 640px); } }

.village-right {
  position: relative;
  width: calc(( ( 1200px - 20px * 11) / 12 ) * 9 + 20px * 9);
  margin-left: auto; }
  @media (max-width: 1280px) {
    .village-right {
      width: calc(( ( 920px - 20px * 11 ) / 12) * 9 + 20px * 9); } }
  @media (max-width: 850px) {
    .village-right {
      width: 100%;
      margin-bottom: 40px; }
      .village-right:last-child {
        margin-bottom: 0; } }
  .village-right:after {
    content: '';
    position: absolute;
    z-index: -1;
    right: calc(( ( 100vw - 1200px ) / 2) * -1);
    bottom: 0;
    height: calc(100% - 480px/2);
    width: calc(100% - ( ( 1200px - 20px * 11) / 12 ) + ( ( 100vw - 1200px ) / 2));
    background-color: #4B5346; }
    @media (max-width: 1280px) {
      .village-right:after {
        right: calc(( ( 100vw - 920px ) / 2) * -1);
        width: calc(100% - ( ( 920px - 20px * 11 ) / 12) + ( ( 100vw - 920px ) / 2)); } }
    @media (max-width: 850px) {
      .village-right:after {
        right: -32px;
        width: calc(100% + 32px); } }
    @media (max-width: 767px) {
      .village-right:after {
        top: calc(175px/2);
        bottom: auto;
        height: calc(100% - 175px/2); } }
  .village-right.-bg-brick:after {
    background-color: #BA8553; }

@media (max-width: 850px) {
  .village-column {
    flex-direction: column; }
    .village-column.column.-cl-3-9 .column__item:first-child {
      width: 100%; } }

.village-select {
  width: 100%;
  margin-bottom: 32px; }

.village-carousel.carousel.-default-style .carousel__slides {
  height: 480px;
  width: 100%; }

.village-carousel.carousel.-default-style .carousel__arrows {
  top: calc(50% - 48px + 12px);
  width: 100%; }

.village-carousel.carousel.-default-style .carousel__dots {
  margin-bottom: 16px; }

.village__hero {
  position: relative;
  margin-bottom: 72px; }
  @media (max-width: 850px) {
    .village__hero {
      margin-bottom: 24px; } }
  .village__hero .village__hero-img {
    height: 480px;
    min-height: 480px;
    width: 100%;
    margin-left: auto; }
    @media (max-width: 767px) {
      .village__hero .village__hero-img {
        height: 175px;
        min-height: 175px; } }

.village-content {
  width: calc(100% - ( ( 1200px - 20px * 11) / 12 ));
  margin-left: auto;
  color: white; }
  @media (max-width: 850px) {
    .village-content {
      flex-direction: column-reverse;
      width: 90%; } }
  .village-content .village-content__main {
    padding-left: calc(( ( 1200px - 20px * 11) / 12 ) + 20px * 2);
    flex-grow: 2; }
    @media (max-width: 850px) {
      .village-content .village-content__main {
        padding-left: 0;
        padding-bottom: 40px; } }
  .village-content .village-content__side {
    margin-left: calc(( ( 1200px - 20px * 11) / 12 ) / 2 + 20px); }
    @media (max-width: 850px) {
      .village-content .village-content__side {
        margin-left: auto; } }
  .village-content .village-content__main-mid {
    padding-bottom: 54px;
    margin-bottom: 48px;
    border-bottom: 1px solid white; }
  .village-content .village-content__main-btm {
    padding-bottom: 126px; }
    @media (max-width: 850px) {
      .village-content .village-content__main-btm {
        padding-bottom: 0; }
        .village-content .village-content__main-btm .village-content__list {
          margin-bottom: 40px; } }
  .village-content .village-content__main-btm-share {
    width: 150px;
    display: none; }
    @media (max-width: 850px) {
      .village-content .village-content__main-btm-share {
        display: block; } }
  .village-content .village-content__paragraph {
    font-size: 15px;
    letter-spacing: 1px;
    line-height: 30px;
    margin-bottom: 26px; }
    @media (max-width: 850px) {
      .village-content .village-content__paragraph {
        font-size: 13px;
        letter-spacing: 1.2px;
        line-height: 27px; } }
  @media (max-width: 850px) {
    .village-content .village-content__icons {
      display: flex;
      margin-bottom: 32px; } }
  .village-content .village-content__icons .village-content__icon {
    margin-bottom: 24px; }
    .village-content .village-content__icons .village-content__icon .icon {
      font-size: 26px;
      color: #EFEFEF; }
    @media (min-width: 1025px) {
      .village-content .village-content__icons .village-content__icon:hover .icon {
        color: white; } }
    @media (max-width: 850px) {
      .village-content .village-content__icons .village-content__icon {
        margin-bottom: 0;
        margin-right: 16px; }
        .village-content .village-content__icons .village-content__icon:last-child {
          margin-right: 0; }
        .village-content .village-content__icons .village-content__icon .icon {
          font-size: 18px; } }
  .village-content .village-content__list {
    font-size: 15px;
    letter-spacing: 1.2px;
    line-height: 30px;
    color: #F4F4F4; }
    @media (max-width: 850px) {
      .village-content .village-content__list {
        font-size: 13px;
        line-height: 27px; } }
    .village-content .village-content__list > * > a {
      color: #F4F4F4;
      border-bottom: 1px solid #F4F4F4; }
      @media (min-width: 1025px) {
        .village-content .village-content__list > * > a:hover {
          color: white;
          border-bottom-color: white; } }
  @media (max-width: 850px) {
    .village-content .village-content__main-top .info-btn-bar__right {
      display: none; } }

/* endinject */
/* inject:scss */
body[data-aos-duration='50'] [data-aos], [data-aos][data-aos][data-aos-duration='50'] {
  transition-duration: 50ms; }

body[data-aos-delay='50'] [data-aos], [data-aos][data-aos][data-aos-delay='50'] {
  transition-delay: 0; }
  body[data-aos-delay='50'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='50'].aos-animate {
    transition-delay: 50ms; }

body[data-aos-duration='100'] [data-aos], [data-aos][data-aos][data-aos-duration='100'] {
  transition-duration: 100ms; }

body[data-aos-delay='100'] [data-aos], [data-aos][data-aos][data-aos-delay='100'] {
  transition-delay: 0; }
  body[data-aos-delay='100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='100'].aos-animate {
    transition-delay: 100ms; }

body[data-aos-duration='150'] [data-aos], [data-aos][data-aos][data-aos-duration='150'] {
  transition-duration: 150ms; }

body[data-aos-delay='150'] [data-aos], [data-aos][data-aos][data-aos-delay='150'] {
  transition-delay: 0; }
  body[data-aos-delay='150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='150'].aos-animate {
    transition-delay: 150ms; }

body[data-aos-duration='200'] [data-aos], [data-aos][data-aos][data-aos-duration='200'] {
  transition-duration: 200ms; }

body[data-aos-delay='200'] [data-aos], [data-aos][data-aos][data-aos-delay='200'] {
  transition-delay: 0; }
  body[data-aos-delay='200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='200'].aos-animate {
    transition-delay: 200ms; }

body[data-aos-duration='250'] [data-aos], [data-aos][data-aos][data-aos-duration='250'] {
  transition-duration: 250ms; }

body[data-aos-delay='250'] [data-aos], [data-aos][data-aos][data-aos-delay='250'] {
  transition-delay: 0; }
  body[data-aos-delay='250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='250'].aos-animate {
    transition-delay: 250ms; }

body[data-aos-duration='300'] [data-aos], [data-aos][data-aos][data-aos-duration='300'] {
  transition-duration: 300ms; }

body[data-aos-delay='300'] [data-aos], [data-aos][data-aos][data-aos-delay='300'] {
  transition-delay: 0; }
  body[data-aos-delay='300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='300'].aos-animate {
    transition-delay: 300ms; }

body[data-aos-duration='350'] [data-aos], [data-aos][data-aos][data-aos-duration='350'] {
  transition-duration: 350ms; }

body[data-aos-delay='350'] [data-aos], [data-aos][data-aos][data-aos-delay='350'] {
  transition-delay: 0; }
  body[data-aos-delay='350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='350'].aos-animate {
    transition-delay: 350ms; }

body[data-aos-duration='400'] [data-aos], [data-aos][data-aos][data-aos-duration='400'] {
  transition-duration: 400ms; }

body[data-aos-delay='400'] [data-aos], [data-aos][data-aos][data-aos-delay='400'] {
  transition-delay: 0; }
  body[data-aos-delay='400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='400'].aos-animate {
    transition-delay: 400ms; }

body[data-aos-duration='450'] [data-aos], [data-aos][data-aos][data-aos-duration='450'] {
  transition-duration: 450ms; }

body[data-aos-delay='450'] [data-aos], [data-aos][data-aos][data-aos-delay='450'] {
  transition-delay: 0; }
  body[data-aos-delay='450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='450'].aos-animate {
    transition-delay: 450ms; }

body[data-aos-duration='500'] [data-aos], [data-aos][data-aos][data-aos-duration='500'] {
  transition-duration: 500ms; }

body[data-aos-delay='500'] [data-aos], [data-aos][data-aos][data-aos-delay='500'] {
  transition-delay: 0; }
  body[data-aos-delay='500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='500'].aos-animate {
    transition-delay: 500ms; }

body[data-aos-duration='550'] [data-aos], [data-aos][data-aos][data-aos-duration='550'] {
  transition-duration: 550ms; }

body[data-aos-delay='550'] [data-aos], [data-aos][data-aos][data-aos-delay='550'] {
  transition-delay: 0; }
  body[data-aos-delay='550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='550'].aos-animate {
    transition-delay: 550ms; }

body[data-aos-duration='600'] [data-aos], [data-aos][data-aos][data-aos-duration='600'] {
  transition-duration: 600ms; }

body[data-aos-delay='600'] [data-aos], [data-aos][data-aos][data-aos-delay='600'] {
  transition-delay: 0; }
  body[data-aos-delay='600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='600'].aos-animate {
    transition-delay: 600ms; }

body[data-aos-duration='650'] [data-aos], [data-aos][data-aos][data-aos-duration='650'] {
  transition-duration: 650ms; }

body[data-aos-delay='650'] [data-aos], [data-aos][data-aos][data-aos-delay='650'] {
  transition-delay: 0; }
  body[data-aos-delay='650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='650'].aos-animate {
    transition-delay: 650ms; }

body[data-aos-duration='700'] [data-aos], [data-aos][data-aos][data-aos-duration='700'] {
  transition-duration: 700ms; }

body[data-aos-delay='700'] [data-aos], [data-aos][data-aos][data-aos-delay='700'] {
  transition-delay: 0; }
  body[data-aos-delay='700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='700'].aos-animate {
    transition-delay: 700ms; }

body[data-aos-duration='750'] [data-aos], [data-aos][data-aos][data-aos-duration='750'] {
  transition-duration: 750ms; }

body[data-aos-delay='750'] [data-aos], [data-aos][data-aos][data-aos-delay='750'] {
  transition-delay: 0; }
  body[data-aos-delay='750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='750'].aos-animate {
    transition-delay: 750ms; }

body[data-aos-duration='800'] [data-aos], [data-aos][data-aos][data-aos-duration='800'] {
  transition-duration: 800ms; }

body[data-aos-delay='800'] [data-aos], [data-aos][data-aos][data-aos-delay='800'] {
  transition-delay: 0; }
  body[data-aos-delay='800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='800'].aos-animate {
    transition-delay: 800ms; }

body[data-aos-duration='850'] [data-aos], [data-aos][data-aos][data-aos-duration='850'] {
  transition-duration: 850ms; }

body[data-aos-delay='850'] [data-aos], [data-aos][data-aos][data-aos-delay='850'] {
  transition-delay: 0; }
  body[data-aos-delay='850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='850'].aos-animate {
    transition-delay: 850ms; }

body[data-aos-duration='900'] [data-aos], [data-aos][data-aos][data-aos-duration='900'] {
  transition-duration: 900ms; }

body[data-aos-delay='900'] [data-aos], [data-aos][data-aos][data-aos-delay='900'] {
  transition-delay: 0; }
  body[data-aos-delay='900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='900'].aos-animate {
    transition-delay: 900ms; }

body[data-aos-duration='950'] [data-aos], [data-aos][data-aos][data-aos-duration='950'] {
  transition-duration: 950ms; }

body[data-aos-delay='950'] [data-aos], [data-aos][data-aos][data-aos-delay='950'] {
  transition-delay: 0; }
  body[data-aos-delay='950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='950'].aos-animate {
    transition-delay: 950ms; }

body[data-aos-duration='1000'] [data-aos], [data-aos][data-aos][data-aos-duration='1000'] {
  transition-duration: 1000ms; }

body[data-aos-delay='1000'] [data-aos], [data-aos][data-aos][data-aos-delay='1000'] {
  transition-delay: 0; }
  body[data-aos-delay='1000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1000'].aos-animate {
    transition-delay: 1000ms; }

body[data-aos-duration='1050'] [data-aos], [data-aos][data-aos][data-aos-duration='1050'] {
  transition-duration: 1050ms; }

body[data-aos-delay='1050'] [data-aos], [data-aos][data-aos][data-aos-delay='1050'] {
  transition-delay: 0; }
  body[data-aos-delay='1050'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1050'].aos-animate {
    transition-delay: 1050ms; }

body[data-aos-duration='1100'] [data-aos], [data-aos][data-aos][data-aos-duration='1100'] {
  transition-duration: 1100ms; }

body[data-aos-delay='1100'] [data-aos], [data-aos][data-aos][data-aos-delay='1100'] {
  transition-delay: 0; }
  body[data-aos-delay='1100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1100'].aos-animate {
    transition-delay: 1100ms; }

body[data-aos-duration='1150'] [data-aos], [data-aos][data-aos][data-aos-duration='1150'] {
  transition-duration: 1150ms; }

body[data-aos-delay='1150'] [data-aos], [data-aos][data-aos][data-aos-delay='1150'] {
  transition-delay: 0; }
  body[data-aos-delay='1150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1150'].aos-animate {
    transition-delay: 1150ms; }

body[data-aos-duration='1200'] [data-aos], [data-aos][data-aos][data-aos-duration='1200'] {
  transition-duration: 1200ms; }

body[data-aos-delay='1200'] [data-aos], [data-aos][data-aos][data-aos-delay='1200'] {
  transition-delay: 0; }
  body[data-aos-delay='1200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1200'].aos-animate {
    transition-delay: 1200ms; }

body[data-aos-duration='1250'] [data-aos], [data-aos][data-aos][data-aos-duration='1250'] {
  transition-duration: 1250ms; }

body[data-aos-delay='1250'] [data-aos], [data-aos][data-aos][data-aos-delay='1250'] {
  transition-delay: 0; }
  body[data-aos-delay='1250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1250'].aos-animate {
    transition-delay: 1250ms; }

body[data-aos-duration='1300'] [data-aos], [data-aos][data-aos][data-aos-duration='1300'] {
  transition-duration: 1300ms; }

body[data-aos-delay='1300'] [data-aos], [data-aos][data-aos][data-aos-delay='1300'] {
  transition-delay: 0; }
  body[data-aos-delay='1300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1300'].aos-animate {
    transition-delay: 1300ms; }

body[data-aos-duration='1350'] [data-aos], [data-aos][data-aos][data-aos-duration='1350'] {
  transition-duration: 1350ms; }

body[data-aos-delay='1350'] [data-aos], [data-aos][data-aos][data-aos-delay='1350'] {
  transition-delay: 0; }
  body[data-aos-delay='1350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1350'].aos-animate {
    transition-delay: 1350ms; }

body[data-aos-duration='1400'] [data-aos], [data-aos][data-aos][data-aos-duration='1400'] {
  transition-duration: 1400ms; }

body[data-aos-delay='1400'] [data-aos], [data-aos][data-aos][data-aos-delay='1400'] {
  transition-delay: 0; }
  body[data-aos-delay='1400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1400'].aos-animate {
    transition-delay: 1400ms; }

body[data-aos-duration='1450'] [data-aos], [data-aos][data-aos][data-aos-duration='1450'] {
  transition-duration: 1450ms; }

body[data-aos-delay='1450'] [data-aos], [data-aos][data-aos][data-aos-delay='1450'] {
  transition-delay: 0; }
  body[data-aos-delay='1450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1450'].aos-animate {
    transition-delay: 1450ms; }

body[data-aos-duration='1500'] [data-aos], [data-aos][data-aos][data-aos-duration='1500'] {
  transition-duration: 1500ms; }

body[data-aos-delay='1500'] [data-aos], [data-aos][data-aos][data-aos-delay='1500'] {
  transition-delay: 0; }
  body[data-aos-delay='1500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1500'].aos-animate {
    transition-delay: 1500ms; }

body[data-aos-duration='1550'] [data-aos], [data-aos][data-aos][data-aos-duration='1550'] {
  transition-duration: 1550ms; }

body[data-aos-delay='1550'] [data-aos], [data-aos][data-aos][data-aos-delay='1550'] {
  transition-delay: 0; }
  body[data-aos-delay='1550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1550'].aos-animate {
    transition-delay: 1550ms; }

body[data-aos-duration='1600'] [data-aos], [data-aos][data-aos][data-aos-duration='1600'] {
  transition-duration: 1600ms; }

body[data-aos-delay='1600'] [data-aos], [data-aos][data-aos][data-aos-delay='1600'] {
  transition-delay: 0; }
  body[data-aos-delay='1600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1600'].aos-animate {
    transition-delay: 1600ms; }

body[data-aos-duration='1650'] [data-aos], [data-aos][data-aos][data-aos-duration='1650'] {
  transition-duration: 1650ms; }

body[data-aos-delay='1650'] [data-aos], [data-aos][data-aos][data-aos-delay='1650'] {
  transition-delay: 0; }
  body[data-aos-delay='1650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1650'].aos-animate {
    transition-delay: 1650ms; }

body[data-aos-duration='1700'] [data-aos], [data-aos][data-aos][data-aos-duration='1700'] {
  transition-duration: 1700ms; }

body[data-aos-delay='1700'] [data-aos], [data-aos][data-aos][data-aos-delay='1700'] {
  transition-delay: 0; }
  body[data-aos-delay='1700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1700'].aos-animate {
    transition-delay: 1700ms; }

body[data-aos-duration='1750'] [data-aos], [data-aos][data-aos][data-aos-duration='1750'] {
  transition-duration: 1750ms; }

body[data-aos-delay='1750'] [data-aos], [data-aos][data-aos][data-aos-delay='1750'] {
  transition-delay: 0; }
  body[data-aos-delay='1750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1750'].aos-animate {
    transition-delay: 1750ms; }

body[data-aos-duration='1800'] [data-aos], [data-aos][data-aos][data-aos-duration='1800'] {
  transition-duration: 1800ms; }

body[data-aos-delay='1800'] [data-aos], [data-aos][data-aos][data-aos-delay='1800'] {
  transition-delay: 0; }
  body[data-aos-delay='1800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1800'].aos-animate {
    transition-delay: 1800ms; }

body[data-aos-duration='1850'] [data-aos], [data-aos][data-aos][data-aos-duration='1850'] {
  transition-duration: 1850ms; }

body[data-aos-delay='1850'] [data-aos], [data-aos][data-aos][data-aos-delay='1850'] {
  transition-delay: 0; }
  body[data-aos-delay='1850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1850'].aos-animate {
    transition-delay: 1850ms; }

body[data-aos-duration='1900'] [data-aos], [data-aos][data-aos][data-aos-duration='1900'] {
  transition-duration: 1900ms; }

body[data-aos-delay='1900'] [data-aos], [data-aos][data-aos][data-aos-delay='1900'] {
  transition-delay: 0; }
  body[data-aos-delay='1900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1900'].aos-animate {
    transition-delay: 1900ms; }

body[data-aos-duration='1950'] [data-aos], [data-aos][data-aos][data-aos-duration='1950'] {
  transition-duration: 1950ms; }

body[data-aos-delay='1950'] [data-aos], [data-aos][data-aos][data-aos-delay='1950'] {
  transition-delay: 0; }
  body[data-aos-delay='1950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1950'].aos-animate {
    transition-delay: 1950ms; }

body[data-aos-duration='2000'] [data-aos], [data-aos][data-aos][data-aos-duration='2000'] {
  transition-duration: 2000ms; }

body[data-aos-delay='2000'] [data-aos], [data-aos][data-aos][data-aos-delay='2000'] {
  transition-delay: 0; }
  body[data-aos-delay='2000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2000'].aos-animate {
    transition-delay: 2000ms; }

body[data-aos-duration='2050'] [data-aos], [data-aos][data-aos][data-aos-duration='2050'] {
  transition-duration: 2050ms; }

body[data-aos-delay='2050'] [data-aos], [data-aos][data-aos][data-aos-delay='2050'] {
  transition-delay: 0; }
  body[data-aos-delay='2050'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2050'].aos-animate {
    transition-delay: 2050ms; }

body[data-aos-duration='2100'] [data-aos], [data-aos][data-aos][data-aos-duration='2100'] {
  transition-duration: 2100ms; }

body[data-aos-delay='2100'] [data-aos], [data-aos][data-aos][data-aos-delay='2100'] {
  transition-delay: 0; }
  body[data-aos-delay='2100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2100'].aos-animate {
    transition-delay: 2100ms; }

body[data-aos-duration='2150'] [data-aos], [data-aos][data-aos][data-aos-duration='2150'] {
  transition-duration: 2150ms; }

body[data-aos-delay='2150'] [data-aos], [data-aos][data-aos][data-aos-delay='2150'] {
  transition-delay: 0; }
  body[data-aos-delay='2150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2150'].aos-animate {
    transition-delay: 2150ms; }

body[data-aos-duration='2200'] [data-aos], [data-aos][data-aos][data-aos-duration='2200'] {
  transition-duration: 2200ms; }

body[data-aos-delay='2200'] [data-aos], [data-aos][data-aos][data-aos-delay='2200'] {
  transition-delay: 0; }
  body[data-aos-delay='2200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2200'].aos-animate {
    transition-delay: 2200ms; }

body[data-aos-duration='2250'] [data-aos], [data-aos][data-aos][data-aos-duration='2250'] {
  transition-duration: 2250ms; }

body[data-aos-delay='2250'] [data-aos], [data-aos][data-aos][data-aos-delay='2250'] {
  transition-delay: 0; }
  body[data-aos-delay='2250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2250'].aos-animate {
    transition-delay: 2250ms; }

body[data-aos-duration='2300'] [data-aos], [data-aos][data-aos][data-aos-duration='2300'] {
  transition-duration: 2300ms; }

body[data-aos-delay='2300'] [data-aos], [data-aos][data-aos][data-aos-delay='2300'] {
  transition-delay: 0; }
  body[data-aos-delay='2300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2300'].aos-animate {
    transition-delay: 2300ms; }

body[data-aos-duration='2350'] [data-aos], [data-aos][data-aos][data-aos-duration='2350'] {
  transition-duration: 2350ms; }

body[data-aos-delay='2350'] [data-aos], [data-aos][data-aos][data-aos-delay='2350'] {
  transition-delay: 0; }
  body[data-aos-delay='2350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2350'].aos-animate {
    transition-delay: 2350ms; }

body[data-aos-duration='2400'] [data-aos], [data-aos][data-aos][data-aos-duration='2400'] {
  transition-duration: 2400ms; }

body[data-aos-delay='2400'] [data-aos], [data-aos][data-aos][data-aos-delay='2400'] {
  transition-delay: 0; }
  body[data-aos-delay='2400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2400'].aos-animate {
    transition-delay: 2400ms; }

body[data-aos-duration='2450'] [data-aos], [data-aos][data-aos][data-aos-duration='2450'] {
  transition-duration: 2450ms; }

body[data-aos-delay='2450'] [data-aos], [data-aos][data-aos][data-aos-delay='2450'] {
  transition-delay: 0; }
  body[data-aos-delay='2450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2450'].aos-animate {
    transition-delay: 2450ms; }

body[data-aos-duration='2500'] [data-aos], [data-aos][data-aos][data-aos-duration='2500'] {
  transition-duration: 2500ms; }

body[data-aos-delay='2500'] [data-aos], [data-aos][data-aos][data-aos-delay='2500'] {
  transition-delay: 0; }
  body[data-aos-delay='2500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2500'].aos-animate {
    transition-delay: 2500ms; }

body[data-aos-duration='2550'] [data-aos], [data-aos][data-aos][data-aos-duration='2550'] {
  transition-duration: 2550ms; }

body[data-aos-delay='2550'] [data-aos], [data-aos][data-aos][data-aos-delay='2550'] {
  transition-delay: 0; }
  body[data-aos-delay='2550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2550'].aos-animate {
    transition-delay: 2550ms; }

body[data-aos-duration='2600'] [data-aos], [data-aos][data-aos][data-aos-duration='2600'] {
  transition-duration: 2600ms; }

body[data-aos-delay='2600'] [data-aos], [data-aos][data-aos][data-aos-delay='2600'] {
  transition-delay: 0; }
  body[data-aos-delay='2600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2600'].aos-animate {
    transition-delay: 2600ms; }

body[data-aos-duration='2650'] [data-aos], [data-aos][data-aos][data-aos-duration='2650'] {
  transition-duration: 2650ms; }

body[data-aos-delay='2650'] [data-aos], [data-aos][data-aos][data-aos-delay='2650'] {
  transition-delay: 0; }
  body[data-aos-delay='2650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2650'].aos-animate {
    transition-delay: 2650ms; }

body[data-aos-duration='2700'] [data-aos], [data-aos][data-aos][data-aos-duration='2700'] {
  transition-duration: 2700ms; }

body[data-aos-delay='2700'] [data-aos], [data-aos][data-aos][data-aos-delay='2700'] {
  transition-delay: 0; }
  body[data-aos-delay='2700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2700'].aos-animate {
    transition-delay: 2700ms; }

body[data-aos-duration='2750'] [data-aos], [data-aos][data-aos][data-aos-duration='2750'] {
  transition-duration: 2750ms; }

body[data-aos-delay='2750'] [data-aos], [data-aos][data-aos][data-aos-delay='2750'] {
  transition-delay: 0; }
  body[data-aos-delay='2750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2750'].aos-animate {
    transition-delay: 2750ms; }

body[data-aos-duration='2800'] [data-aos], [data-aos][data-aos][data-aos-duration='2800'] {
  transition-duration: 2800ms; }

body[data-aos-delay='2800'] [data-aos], [data-aos][data-aos][data-aos-delay='2800'] {
  transition-delay: 0; }
  body[data-aos-delay='2800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2800'].aos-animate {
    transition-delay: 2800ms; }

body[data-aos-duration='2850'] [data-aos], [data-aos][data-aos][data-aos-duration='2850'] {
  transition-duration: 2850ms; }

body[data-aos-delay='2850'] [data-aos], [data-aos][data-aos][data-aos-delay='2850'] {
  transition-delay: 0; }
  body[data-aos-delay='2850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2850'].aos-animate {
    transition-delay: 2850ms; }

body[data-aos-duration='2900'] [data-aos], [data-aos][data-aos][data-aos-duration='2900'] {
  transition-duration: 2900ms; }

body[data-aos-delay='2900'] [data-aos], [data-aos][data-aos][data-aos-delay='2900'] {
  transition-delay: 0; }
  body[data-aos-delay='2900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2900'].aos-animate {
    transition-delay: 2900ms; }

body[data-aos-duration='2950'] [data-aos], [data-aos][data-aos][data-aos-duration='2950'] {
  transition-duration: 2950ms; }

body[data-aos-delay='2950'] [data-aos], [data-aos][data-aos][data-aos-delay='2950'] {
  transition-delay: 0; }
  body[data-aos-delay='2950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2950'].aos-animate {
    transition-delay: 2950ms; }

body[data-aos-duration='3000'] [data-aos], [data-aos][data-aos][data-aos-duration='3000'] {
  transition-duration: 3000ms; }

body[data-aos-delay='3000'] [data-aos], [data-aos][data-aos][data-aos-delay='3000'] {
  transition-delay: 0; }
  body[data-aos-delay='3000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='3000'].aos-animate {
    transition-delay: 3000ms; }

body[data-aos-easing="linear"] [data-aos], [data-aos][data-aos][data-aos-easing="linear"] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75); }

body[data-aos-easing="ease"] [data-aos], [data-aos][data-aos][data-aos-easing="ease"] {
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1); }

body[data-aos-easing="ease-in"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in"] {
  transition-timing-function: cubic-bezier(0.42, 0, 1, 1); }

body[data-aos-easing="ease-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out"] {
  transition-timing-function: cubic-bezier(0, 0, 0.58, 1); }

body[data-aos-easing="ease-in-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out"] {
  transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1); }

body[data-aos-easing="ease-in-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-back"] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045); }

body[data-aos-easing="ease-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-back"] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275); }

body[data-aos-easing="ease-in-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-back"] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }

body[data-aos-easing="ease-in-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-sine"] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715); }

body[data-aos-easing="ease-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-sine"] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1); }

body[data-aos-easing="ease-in-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-sine"] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95); }

body[data-aos-easing="ease-in-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quad"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quad"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quad"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

body[data-aos-easing="ease-in-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-cubic"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-cubic"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-cubic"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

body[data-aos-easing="ease-in-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quart"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quart"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quart"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

/**
 * Fade animations:
 * fade
 * fade-up, fade-down, fade-left, fade-right
 * fade-up-right, fade-up-left, fade-down-right, fade-down-left
 */
[data-aos^='fade'][data-aos^='fade'] {
  opacity: 0;
  transition-property: opacity, transform; }
  [data-aos^='fade'][data-aos^='fade'].aos-animate {
    opacity: 1;
    transform: translate(0, 0); }

[data-aos='fade-up'] {
  transform: translate(0, 50px); }

[data-aos='fade-down'] {
  transform: translate(0, -50px); }

[data-aos='fade-right'] {
  transform: translate(-50px, 0); }

[data-aos='fade-left'] {
  transform: translate(50px, 0); }

[data-aos='fade-up-right'] {
  transform: translate(-50px, 50px); }

[data-aos='fade-up-left'] {
  transform: translate(50px, 50px); }

[data-aos='fade-down-right'] {
  transform: translate(-50px, -50px); }

[data-aos='fade-down-left'] {
  transform: translate(50px, -50px); }

/**
 * Zoom animations:
 * zoom-in, zoom-in-up, zoom-in-down, zoom-in-left, zoom-in-right
 * zoom-out, zoom-out-up, zoom-out-down, zoom-out-left, zoom-out-right
 */
[data-aos^='zoom'][data-aos^='zoom'] {
  opacity: 0;
  transition-property: opacity, transform; }
  [data-aos^='zoom'][data-aos^='zoom'].aos-animate {
    opacity: 1;
    transform: translate(0, 0) scale(1); }

[data-aos='zoom-in'] {
  transform: scale(0.6); }

[data-aos='zoom-in-up'] {
  transform: translate(0, 50px) scale(0.6); }

[data-aos='zoom-in-down'] {
  transform: translate(0, -50px) scale(0.6); }

[data-aos='zoom-in-right'] {
  transform: translate(-50px, 0) scale(0.6); }

[data-aos='zoom-in-left'] {
  transform: translate(50px, 0) scale(0.6); }

[data-aos='zoom-out'] {
  transform: scale(1.2); }

[data-aos='zoom-out-up'] {
  transform: translate(0, 50px) scale(1.2); }

[data-aos='zoom-out-down'] {
  transform: translate(0, -50px) scale(1.2); }

[data-aos='zoom-out-right'] {
  transform: translate(-50px, 0) scale(1.2); }

[data-aos='zoom-out-left'] {
  transform: translate(50px, 0) scale(1.2); }

/**
 * Slide animations
 */
[data-aos^='slide'][data-aos^='slide'] {
  transition-property: transform; }
  [data-aos^='slide'][data-aos^='slide'].aos-animate {
    transform: translate(0, 0); }

[data-aos='slide-up'] {
  transform: translateY(100%); }

[data-aos='slide-down'] {
  transform: translateY(-100%); }

[data-aos='slide-right'] {
  transform: translateX(-100%); }

[data-aos='slide-left'] {
  transform: translateX(100%); }

/**
 * Flip animations:
 * flip-left, flip-right, flip-up, flip-down
 */
[data-aos^='flip'][data-aos^='flip'] {
  backface-visibility: hidden;
  transition-property: transform; }

[data-aos='flip-left'] {
  transform: perspective(2500px) rotateY(-100deg); }
  [data-aos='flip-left'].aos-animate {
    transform: perspective(2500px) rotateY(0); }

[data-aos='flip-right'] {
  transform: perspective(2500px) rotateY(100deg); }
  [data-aos='flip-right'].aos-animate {
    transform: perspective(2500px) rotateY(0); }

[data-aos='flip-up'] {
  transform: perspective(2500px) rotateX(-100deg); }
  [data-aos='flip-up'].aos-animate {
    transform: perspective(2500px) rotateX(0); }

[data-aos='flip-down'] {
  transform: perspective(2500px) rotateX(100deg); }
  [data-aos='flip-down'].aos-animate {
    transform: perspective(2500px) rotateX(0); }

.CMSDeskContent .aos-init {
  opacity: 1 !important;
  transform: translate(0, 0); }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
  height: 100%; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  height: 100%; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }
  .slick-slide:focus {
    outline: none; }

.slick-arrow.slick-hidden {
  display: none; }

.slick-dots {
  margin-top: -50px;
  position: relative;
  z-index: 1;
  text-align: center; }
  .slick-dots > li {
    display: inline-block;
    margin: 0 5px; }
    .slick-dots > li button {
      font-size: 0;
      line-height: 0;
      display: block;
      width: 12px;
      height: 12px;
      border-radius: 100%;
      padding: 5px;
      cursor: pointer;
      color: transparent;
      border: 0;
      outline: none;
      background: #fff; }
    .slick-dots > li.slick-active button {
      background: #000;
      opacity: 1; }

/* endinject */
