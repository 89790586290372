@lost gutter 24px;

body, html {
	@extend %full-width;
	position: relative;
	font-family: $font-en;
	color: $default-color;
	font-size: 16px;
	background-color: $c-bg-main;
	-webkit-tap-highlight-color: rgba($default-color, 0.8);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow-x: hidden;
	&.-is-fixed {
		position: fixed;
		overflow-y: hidden;
	}
}

a {
	color: $default-color;
	transition: $transition;

	@include media('above-tablet') {
		&:hover {
			transform: translateX(4px);
		}
	}
	&.-no-hover {
		&:hover {
			transform: none;
		}
	}
}

.body-container {
	padding-top: $header;
	@include media('below-small') {
		padding-top: $header-m;
	}
}

p {
	text-align: justify;
}